import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, IconButton, alpha, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertPopup({
  open,
  setOpen,
  handleSubmit,
  title,
  content,
  confirm,
  close,
  isSubmitting,
  alertWidth,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    if (!(isSubmitting ?? false)) {
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundColor: "white",
            padding: 2,
            width: isMobile ? "100%" : alertWidth ?? "350px",
            height: "100%",
            paddingY: 4,
            position: "relative",
          }}
        >
          {!isSubmitting && (
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "gray",
                margin: 1,
                "&:hover": {
                  color: "#11B1F4",
                  backgroundColor: "transparent",
                },
              }}
              size="medium"
              onClick={handleClose}
            >
              <CloseIcon fontSize="medium" sx={{ color: "gray" }} />
            </IconButton>
          )}

          <img
            style={{
              width: 65,
              height: 65,
              marginTop: 4,
            }}
            src="/Images/popups/cancel-symbol.png"
            alt=""
          />
          <DialogTitle
            sx={{
              color: "black",
              fontWeight: 600,
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                color: "gray",
              }}
              id="alert-dialog-slide-description"
            >
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: "#F41111",
                color: "white",
                textTransform: "none",
                paddingX: 2,
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: alpha("#F41111", 0.5),
                },
              }}
              onClick={handleClose}
            >
              {close}
            </Button>

            <LoadingButton
              loading={isSubmitting}
              // <Button
              sx={{
                backgroundColor: "#58B112",
                color: "white",
                textTransform: "none",
                paddingX: 2,
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: alpha("#58B112", 0.5),
                },
              }}
              onClick={handleSubmit}
            >
              {confirm}
              {/* </Button> */}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
