import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const LiveScoreCounter = ({ endTime, status }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const easternTime = new Date().toLocaleString("en-US", {
        timeZone: process.env.REACT_APP_DEFAULT_TIME_ZONE,
      });

      const easternTimeInMS = Date.parse(easternTime);
      const difference = endTime.getTime() - easternTimeInMS;

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDays(d);

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(h);

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(m);

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(s);
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  const formatTime = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <Box
      sx={{
        height: 55,
        // width: 100,
        // paddingY: 2,
        paddingX: 2,
        borderRadius: "10px",
        background: "#00FF66",
        textTransform: "none",
        py: 1,
        display: status === "started" ? "flex" : "none",
        border: 2,
        borderColor: "black",
        fontWeight: 800,
        alignItems: "center",
        fontSize: 24,
        justifyContent: "center",
        alignContent: "center",
        color: "#042836",
      }}
      whiteSpace={"nowrap"}
    >
      {seconds < 0
        ? `00:00:00`
        : days === 0 && hours === 0
        ? `00:${formatTime(minutes)}:${formatTime(seconds)}`
        : days === 0
        ? `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}s`
        : `${formatTime(days)}:${formatTime(hours)}:${formatTime(
            minutes
          )}:${formatTime(seconds)}`}
    </Box>
  );
};

export default LiveScoreCounter;
