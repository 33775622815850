import { Box } from "@mui/material";
import React from "react";
import Timer from "../Timer/Timer";

const GameResultStatus = ({ tab }) => {
  return (
    <>
      {tab.status === "waiting" ? (
        <Timer target={new Date(tab?.startTime)} />
      ) : tab.status === "started" ? (
        <Box
          sx={{
            height: 35,
            // width: 110,
            borderRadius: "20px",
            background: "#00FF66",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            paddingX: 1,
          }}
          whiteSpace={"nowrap"}
        >
          In progress
        </Box>
      ) : tab.status === "closed" ? (
        <Box
          sx={{
            height: 35,
            // width: 110,
            borderRadius: "20px",
            background: "#FF0000",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            paddingX: 1,
          }}
          whiteSpace={"nowrap"}
        >
          Finished
        </Box>
      ) : (
        <Box
          sx={{
            height: 35,
            // width: 110,
            borderRadius: "20px",
            background: "#FF0000",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            paddingX: 1,
          }}
          whiteSpace={"nowrap"}
        >
          {tab.status.charAt(0).toUpperCase() + tab.status.slice(1)}
        </Box>
      )}
    </>
  );
};

export default GameResultStatus;
