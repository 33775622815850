import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { getUsersWithProfile } from "../../Services/Service";

const LandingAvatarGroups = ({ size }) => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchUsers = async () => {
    try {
      await delay(400);
      const response = await getUsersWithProfile();
      console.log(response.data);
      return response.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["usersWithProfile"],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
  });

  console.log(data, "USERAWP");

  if (isLoading) return <>Loading...</>;

  return (
    <>
      <AvatarGroup max={100}>
        {data?.slice(0, size ?? 5).map((user) => (
          <Avatar
            key={user?._id}
            sx={{
              width: { xs: 15, sm: 30, md: 50 },
              height: { xs: 15, sm: 30, md: 50 },
              // border: "1px solid white",
              // padding: 0.3,
            }}
            alt="Remy Sharp"
            src={user?.profilePic}
          />
        ))}
      </AvatarGroup>
    </>
  );
};

export const BannerFooterSection = ({ text, link, onClick, size }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <Box
        // sx={{ position: "absolute", left: 0 }}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"row"}
        gap={{
          xs: 1,
          sm: 2,
        }}
        marginBottom={4}
        justifyContent={isSmall && "start"}
      >
        <LandingAvatarGroups size={size ?? 5} />
        <Box
          display={"flex"}
          flexDirection={"column"}
          marginLeft={{ xs: 0, sm: 1 }}
        >
          {" "}
          <Typography
            variant="body2"
            sx={{
              color: "white",
              // fontWeight: 300,
              fontFamily: "Montserrat",
              marginBottom: {
                sm: 1,
              },
            }}
            fontSize={{ xs: 6, sm: 12, md: 14 }}
          >
            {text}
            <Link
              component="button"
              variant="body2"
              to={onClick}
              style={{
                color: "white",
              }}
            >
              {link}
            </Link>
          </Typography>
          <Typography
            variant="caption"
            // color={"white"}
            fontSize={{ xs: 4, sm: 10, md: 14 }}
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            100+ Users joined last Month
          </Typography>
        </Box>
      </Box>
    </>
  );
};
