import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Slide,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createParticipant,
  deleteInvitation,
  getBlockChianConfig,
  magicGetRequestState,
  transactionGasFee,
} from "../../Services/Service";
import { useJoinedGameCount } from "../../constants/useJoinedGameCount";
import { BiSearch } from "react-icons/bi";
import toast from "react-hot-toast";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Contract, ethers } from "ethers";
import { makeMagic } from "../../lib/magic";
import NewAbi from "../../Contract/NewAbi.json";
import contestAbi from "../../Contract/contestAbi.json";
import USDCToken from "../../Contract/USDC.json";
import feedAbi from "../../Contract/feed_contract.json";
import { AuthContext } from "../../context/AuthContext";
import { Magic } from "magic-sdk";
import { OpenIdExtension } from "@magic-ext/oidc";
import LinearProgress from "@mui/material/LinearProgress";
import { walletClient } from "../../lib/zyfi";
import { formatEther, parseUnits } from "viem";
import {
  walletClientApprove,
  walletClientJoinContest,
  wcApproveWithFeed,
} from "./transaction/transaction";
import { useStockContractInitialization } from "./transaction/stockContestTx";
import { commonData } from "../../constants/common";
import { feedSmartContract } from "../../lib/feedContract";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// A helper component to display the progress value inside the LinearProgress bar
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            background: "rgba(4, 54, 76, 0.40)",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#04364c", // Change this to your desired color
              color: "#04364c",
            },
          }}
        />
      </Box>
      <Box>
        <Typography variant="body2" color="black">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const SelectStocksPage = () => {
  const { state } = useLocation();
  const { rowData, receiver, invitationId } = state;
  console.log(rowData, invitationId, "SCP");
  const navigate = useNavigate();

  const userId = sessionStorage.getItem("userId");
  const userToken = sessionStorage.getItem("userToken");
  const [nobalance, setNoBalance] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedStocksData, setSelectedStocksData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [query, setQuery] = useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { user, userBalance } = useContext(AuthContext);
  const [joinContestProgress, setJoinContestProgress] = useState(0);

  const { refetch: refetchJoinedGameCount } = useJoinedGameCount();

  const {
    usdcContract,
    contestContract,
    blockChainConfig,
    provider,
    signer,
    address,
    isAlreadyApproved,
    nonceTx,
    allowFeed,
    processing,
    initializeContracts,
  } = useStockContractInitialization();

  const progressBar = (step) => {
    const totalSteps = 12;
    setJoinContestProgress((step / totalSteps) * 100);

    if (step === 12) {
      setJoinContestProgress(0);
    }
  };

  useEffect(() => {
    if (rowData?.game?.gamePaymentType === "Paid") {
      initializeContracts(`${rowData?.game?.entryFees ?? 0}`);
    }
  }, []);

  const handleIncrement = (data) => {
    const isSelected = selectedCoins.some((item) => item.stocks === data?._id);

    if (isSelected) {
      // If it's already selected, remove it from the array
      setSelectedCoins(
        selectedCoins.filter((item) => item.stocks !== data?._id)
      );
      setSelectedStocksData(
        selectedStocksData.filter((item) => item.data._id !== data?._id)
      );
      setCount(count - 1);
    } else {
      if (count === Number(rowData.game.gameType)) {
        // toast.("max numbers selected");
        toast.error("Max numbers selected.", {
          style: {
            border: "1px solid #FF0000",
            padding: "16px",
            color: "#FF0000",
          },
          iconTheme: {
            primary: "#FF0000",
            secondary: "#FFFAEE",
          },
          position: "top-right",
        });
      } else {
        // If it's not selected, add it to the array
        setSelectedCoins([...selectedCoins, { stocks: data?._id }]);
        setSelectedStocksData([...selectedStocksData, { data }]);
        setCount(count + 1);
      }
    }
  };

  console.log(selectedCoins);
  console.log(selectedStocksData);

  const sendTransactionNew = async (usdcAmount, contestId) => {
    const startTime = performance.now();

    let receipt, convert, approveTransaction;
    try {
      const blockChainConfig = await getBlockChianConfig();

      progressBar(1);
      // console.log(blockChainConfig, "blockChainConfig");
      const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);

      // ⭐️ After user is successfully authenticated
      const signer = provider.getSigner();

      // ⭐️ After user is successfully authenticated
      const address = await signer.getAddress();
      console.log(address, "wallet magic address");

      const destination = blockChainConfig?.data?.adminWallet; //this will be admin wallet address

      const contractAddress = blockChainConfig?.data?.contractAddress;

      console.log(contractAddress, "smart contract address");
      if (!contractAddress) {
        progressBar(12);
        throw new Error("contract");
      }

      progressBar(2);

      const contestContractNew = new ethers.Contract(
        contractAddress,
        contestAbi,
        signer
      );

      progressBar(3);

      const paymentToken = await contestContractNew.paymentToken();
      console.log(paymentToken, "paymentToken");
      progressBar(4);
      const finalAmount = ethers.utils.parseUnits(
        // usdcAmount.toString(),
        `${commonData?.allowanceAmount}`,
        commonData?.usdcDecimals
      );

      const usdcContractNew = new ethers.Contract(
        paymentToken, //, //payment Token - USDT -
        USDCToken,
        signer
      );
      progressBar(5);
      const allowance = await usdcContractNew.functions.allowance(
        address,
        contractAddress
      );

      const isAmountExist = ethers.utils.formatUnits(
        allowance.toString(),
        commonData?.usdcDecimals
      );

      // console.log(
      //   "ALLOWANCE => ",
      //   ethers.utils.formatUnits(allowance.toString(), 18),
      //   "isAmountExist <= usdcAmount",
      //   isAmountExist <= usdcAmount,
      //   "isAmountExist",
      //   isAmountExist,
      //   "usdcAmount",
      //   usdcAmount
      // );

      const endTime = performance.now();
      const executionTime = endTime - startTime;
      console.log(
        `After allowance execution time: ${executionTime.toFixed(
          2
        )} milliseconds`
      );
      progressBar(6);
      if (parseFloat(isAmountExist) < parseFloat(usdcAmount)) {
        const gasLimit = await usdcContractNew.estimateGas.approve(
          contractAddress,
          finalAmount
        );

        const gasPrice = await provider.getGasPrice();
        const gasPriceWithBuffer = gasPrice.mul(120).div(100);
        console.log(
          ethers.utils.formatEther(gasPriceWithBuffer.toString()),
          "gas price with buffer"
        );

        // Get signer's balance
        const signerBalance = await provider.getBalance(address);
        console.log(
          ethers.utils.formatEther(signerBalance.toString()),
          "Matic signerBalance"
        );

        const gasLimitWithBuffer = gasLimit.mul(120).div(100); // 120% of the estimate
        console.log(
          ethers.utils.formatEther(gasLimitWithBuffer.toString()),
          "gasLimitWithBuffer"
        );

        const tx_cost_with_buffer = gasPriceWithBuffer.mul(gasLimitWithBuffer);
        console.log(
          ethers.utils.formatEther(tx_cost_with_buffer.toString()),
          "tx_cost_with_buffer"
        );

        console.log(
          signerBalance.lt(tx_cost_with_buffer),
          "signerBalance.lt(tx_cost_with_buffer)"
        );

        progressBar(7);

        //Feed Wallet fund transfer
        // /*
        if (signerBalance.lt(tx_cost_with_buffer) || allowFeed === true) {
          throw new Error(
            `There isn't enough MATIC in the sender's account to cover the gas fees`
          );
        }
        // */

        /*
          const claimFundTnx = await claimFund(
            "0x17d752b7AA79b4Da4154D954eE31AcD2A56Cb8B1",
            address,
            provider,
            signer
          );
  
          if (!claimFundTnx?.transactionHash && claimFundTnx?.status !== 1) {
            progressBar(12);
            // return ;
            throw new Error("Claim Fund transaction is failed");
          }
            */

        let transaction;
        console.log("Nonce in Transaction", nonceTx);
        if (nonceTx > 0) {
          const nonce = await provider.getTransactionCount(address);
          console.log("Nonce ", nonce);
          const currentNonce = nonce === nonceTx ? nonceTx + 1 : nonce;
          console.log("Nonce current", nonce);

          progressBar(8);
          transaction = await usdcContractNew.approve(
            contractAddress,
            finalAmount,
            {
              gasLimit: gasLimit,
              gasPrice: gasPriceWithBuffer,
              nonce: currentNonce,
            }
          );
        } else {
          progressBar(8);
          transaction = await usdcContractNew.approve(
            contractAddress,
            finalAmount,
            {
              gasLimit: gasLimit,
              gasPrice: gasPriceWithBuffer,
            }
          );
        }

        progressBar(9);
        approveTransaction = await transaction.wait();

        const endTimeApprove = performance.now();
        const executionTimeA = endTimeApprove - startTime;
        console.log(
          `After approve execution time: ${executionTimeA.toFixed(
            2
          )} milliseconds`
        );

        if (
          !approveTransaction?.transactionHash &&
          approveTransaction?.status !== 1
        ) {
          progressBar(12);
          // return ;
          throw new Error("Approve transaction is failed");
        }
      }

      progressBar(10);
      console.log("Transaction contestId=> ", contestId);

      //===============[Start of With Gasless Transaction]===================
      const joinContestTransaction =
        await contestContractNew.populateTransaction.joinContest(
          `${contestId}`
        );

      console.log(joinContestTransaction, "joinContestTransaction");

      // setIsSubmitting(false);
      // Send gasless transaction to Magic Relayer (after user login)
      const gasless_joinContest_request =
        await makeMagic.wallet.sendGaslessTransaction(
          address, // User's Wallet address
          joinContestTransaction
        );

      // console.log(gasless_joinContest_request, "gasless_joinContest_request");
      progressBar(11);
      const joinContestResponse = await pollTransactionStatus(
        gasless_joinContest_request?.request_id
      );

      console.log("Transaction successful:", joinContestResponse);

      receipt = joinContestResponse;

      // console.log(waitForTJoin, "waitForTJoin");
      console.log(receipt, "Transaction from magic join contest");

      const endTimeT = performance.now();
      const executionTimeT = endTimeT - startTime;
      console.log(
        `Transaction execution time: ${executionTimeT.toFixed(2)} milliseconds`
      );
      progressBar(12);
      return { receipt, convert };
    } catch (error) {
      // if (error.code === "INSUFFICIENT_FUNDS") {
      //   toast.error(
      //     "There isn't enough MATIC in the sender's account to cover the gas fees."
      //   );
      // } else {
      //   toast.error(error?.message);
      // }
      // setIsSubmitting(false);
      // console.log("Error =>", error?.message);
      // console.log("Error =>", error);

      const endTime = performance.now();
      const executionTime = endTime - startTime;
      console.log(
        `Transaction execution time: ${executionTime.toFixed(2)} milliseconds`
      );
      progressBar(12);
      throw error;
    }
  };

  const sendTransaction = async (usdcAmount, contestId) => {
    const startTime = performance.now();
    let receipt, convert, approveTransaction;
    try {
      if (isAlreadyApproved === false) {
        console.log("ALready Approved is False");
        return sendTransactionNew(usdcAmount, contestId);
      }

      console.log("Transaction contestId=> ", contestId);

      progressBar(6);
      //===============[Start of With Gasless Transaction]===================
      const joinContestTransaction =
        await contestContract.populateTransaction.joinContest(`${contestId}`);

      console.log(joinContestTransaction, "joinContestTransaction");
      progressBar(8);
      // setIsSubmitting(false);
      // Send gasless transaction to Magic Relayer (after user login)
      const gasless_joinContest_request =
        await makeMagic.wallet.sendGaslessTransaction(
          address, // User's Wallet address
          joinContestTransaction
        );

      // console.log(gasless_joinContest_request, "gasless_joinContest_request");
      progressBar(11);
      const joinContestResponse = await pollTransactionStatus(
        gasless_joinContest_request?.request_id
      );

      console.log("Transaction successful:", joinContestResponse);

      receipt = joinContestResponse;

      // console.log(waitForTJoin, "waitForTJoin");
      console.log(receipt, "Transaction from magic join contest");

      const endTimeT = performance.now();
      const executionTimeT = endTimeT - startTime;
      console.log(
        `Transaction execution time: ${executionTimeT.toFixed(2)} milliseconds`
      );
      progressBar(12);
      return { receipt, convert };
    } catch (error) {
      const endTime = performance.now();
      const executionTime = endTime - startTime;
      console.log(
        `Transaction execution time: ${executionTime.toFixed(2)} milliseconds`
      );
      progressBar(12);
      throw error;
    }
  };

  // Function to poll the transaction status
  const pollTransactionStatus = async (
    requestId,
    maxAttempts = 100,
    interval = 1000
  ) => {
    if (!requestId) throw new Error("Transaction failed");

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const response = await magicGetRequestState(requestId);

      console.log(
        `Attempt ${attempt + 1}: Transaction status -`,
        response?.data?.state
      );
      console.log(`Attempt ${attempt + 1}: Transaction status -`, response);
      if (response?.data?.state === "FAILED") {
        throw new Error("Transaction failed");
      } else if (response?.data?.state === "COMPLETED") {
        return response?.data?.tx_receipt;
      }

      // // Wait for the specified interval before the next attempt
      await new Promise((resolve) => setTimeout(resolve, interval));
    }

    throw new Error("Transaction timed out");
  };

  const handleAddPariticpant = async () => {
    setIsSubmitting(true);
    try {
      const getInfo = await makeMagic.user.getInfo(); // address call directly from Profile

      if (invitationId) {
        setIsSubmitting(true);
        const response = await deleteInvitation(invitationId);
        if (response.data.success === true) {
          // try {
          let transaction;
          if (rowData?.game?.gamePaymentType === "Paid") {
            transaction = await sendTransaction(
              `${rowData?.game?.entryFees}`,
              `${rowData?.contestId}`
            );
            if (!transaction?.receipt?.transactionHash) {
              setIsSubmitting(false);
              return toast.error(
                "Your transaction to join the game has failed"
              );
            }
            if (
              transaction?.receipt?.transactionHash &&
              transaction?.receipt?.status !== 1
            ) {
              setIsSubmitting(false);
              return toast.error(
                "Your transaction to join the game has failed"
              );
            }
          }

          const body = {
            userId: userId,
            gameInstance: rowData?._id,
            game: rowData?.game?._id,
            selectedStocks: selectedCoins,
            gameAssetType: "STOCKS",
            receiver: receiver || "",
            startTime: rowData?.startTime,
            gameType: receiver ? "Head to Head" : "Multiplayer",
            transactionHash: transaction?.receipt?.transactionHash,
            walletAddress: getInfo?.publicAddress,
            etherValue: rowData?.game?.entryFees,
          };
          const addParticipant = await createParticipant(body);
          if (addParticipant) {
            setConfirmModalOpen(false);
            toast.success("Participant added successfully!", {
              position: "top-right",
            });
            setIsSubmitting(false);
            navigate(-1);
            await refetchJoinedGameCount();
          }
        }
      } else {
        // try {
        setIsSubmitting(true);
        let transaction;
        if (rowData?.game?.gamePaymentType === "Paid") {
          transaction = await sendTransaction(
            `${rowData?.game?.entryFees}`,
            `${rowData?.contestId}`
          );
          if (!transaction?.receipt?.transactionHash) {
            setIsSubmitting(false);
            return toast.error("Your transaction to join the game has failed");
          }
          if (
            transaction?.receipt?.transactionHash &&
            transaction?.receipt?.status !== 1
          ) {
            setIsSubmitting(false);
            return toast.error("Your transaction to join the game has failed");
          }
        }

        const body = {
          userId: userId,
          gameInstance: rowData?._id,
          game: rowData?.game?._id,
          selectedStocks: selectedCoins,
          gameAssetType: "STOCKS",
          receiver: receiver || "",
          startTime: rowData?.startTime,
          gameType: receiver ? "Head to Head" : "Multiplayer",
          transactionHash: transaction?.receipt?.transactionHash,
          walletAddress: getInfo?.publicAddress,
          etherValue: rowData?.game?.entryFees,
        };
        const addParticipant = await createParticipant(body);
        if (addParticipant) {
          setConfirmModalOpen(false);
          toast.success("Participant added successfully!", {
            position: "top-right",
          });
          setIsSubmitting(false);
          navigate(-1);
          await refetchJoinedGameCount();
        }
      }
    } catch (error) {
      console.error(error?.message);
      setIsSubmitting(false);
      setConfirmModalOpen(false);
      console.log(error.response);
      if (error.response?.data?.error === "Time_Reached") {
        // toast.error("Time out..!");
        toast.error("You can not join a contest 2 min prior to start time");
      } else if (error.code === "INSUFFICIENT_FUNDS") {
        toast.error(
          "There isn't enough MATIC in the sender's account to cover the gas fees"
        );
      } else if (error?.message === "contract") {
        toast.error("Failed to add Participant [c]");
      } else {
        toast.error(`Failed to add Participant`);
      }

      return navigate(-1);
    }
    setIsSubmitting(false);
  };

  const handleResetClick = () => {
    setSelectedCoins([]);
    setSelectedStocksData([]);
    setCount(0);
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setConfirmModalOpen(false);
    }
  };

  return (
    <>
      {/*  */}
      <Box sx={{ background: "#04364c", borderRadius: "27px", padding: 1 }}>
        <Grid container spacing={2} p={2} marginBottom={4}>
          <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
            <div>
              <Typography variant="h5">{`Pick Any ${rowData?.game?.gameType} Stocks`}</Typography>
              <Typography color={"#11B1F4"} marginTop={1}>
                {`${rowData?.game?.gameType - count} coins left`}
              </Typography>
            </div>
            <Box sx={{ display: "flex", gap: 1, height: 45 }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                  color: "white",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: " #10ADEF95",
                  },
                }}
                onClick={handleResetClick}
              >
                Reset
              </Button>
              <LoadingButton
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                  color: "white",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: " #10ADEF95",
                  },
                }}
                loading={
                  rowData?.game?.gamePaymentType === "Paid" ? processing : false
                }
                disabled={
                  count === Number(rowData?.game.gameType)
                    ? rowData?.game?.gamePaymentType === "Paid"
                      ? processing === true
                        ? true
                        : false
                      : false
                    : true
                }
                onClick={() => {
                  // console.log(userToken);

                  // user?.token < rowData?.game?.entryFees
                  //   ? setNoBalance(true)
                  //   : setNoBalance(false);
                  parseFloat(userBalance) < rowData?.game?.entryFees
                    ? setNoBalance(true)
                    : setNoBalance(false);
                  setConfirmModalOpen(true);
                }}
              >
                Confirm
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          marginY={5}
          paddingX={2}
        >
          {selectedStocksData.map((data, index) => (
            <Grid key={index} position={"relative"} item xs={2} sm={4} md={4}>
              <Box
                sx={{
                  background: "#022737",
                  padding: 2,
                  borderRadius: "16px",
                  border: 1,
                  borderColor: "#11B1F4",
                  position: "relative",
                  //   minWidth: "300px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  cursor: "pointer",
                }}
                onClick={() => handleIncrement(data.data)}
              >
                <Button
                  sx={{ position: "absolute", top: -18, right: -28 }}
                  onClick={() => handleIncrement(data.data)}
                >
                  <img
                    width="26px"
                    height="26px"
                    src="/Images/deselect.png"
                    alt="power-off-button"
                  />
                </Button>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box display={"flex"}>
                    <img
                      src={data.data.image}
                      style={{ width: "40px", height: "40px" }}
                      alt=""
                    />
                    <Box marginLeft={1}>
                      <Typography sx={{ fontWeight: 600 }}>
                        {data.data?.coinId}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontSize={14}
                        color={"gray"}
                      >
                        {/* {data.data?.name} */}
                        {data.data?.name?.length > 20
                          ? `${data.data?.name?.slice(0, 20)}...`
                          : data.data?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography>${data?.data?.price?.toFixed(2)}</Typography>
                    <Typography
                      sx={{
                        color:
                          data.data?.price_change_percentage_24h < 0
                            ? "#FF4C67"
                            : "#00FF66",
                      }}
                    >
                      {data?.data?.price_change_percentage_24h?.toFixed(2) ?? 0}
                      %
                    </Typography>
                  </Box>
                </Stack>
                {/* <Stack
            direction={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography sx={{ fontSize: 14 }}>Enter Percentage</Typography>
            <Typography
              sx={{
                background: "#011F2D",
                padding: 0.5,
                borderRadius: "8px",
                fontWeight: 600,
              }}
            >
              100 %
            </Typography>
          </Stack> */}
              </Box>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ margin: 2, borderTopWidth: 2 }} />

        <Paper
          component="form"
          sx={{
            p: "0 2px",
            display: "flex",
            alignItems: "center",
            background: "#064865",
            border: "none",
            boxShadow: "none",
            borderRadius: "36px",
            width: "90%",
            margin: 2,
            marginBottom: 4,
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <BiSearch color="#D0D4DC" size={20} />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search Token....."
            inputProps={{ "aria-label": "Search Token....." }}
          />
        </Paper>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          marginY={5}
          paddingX={2}
        >
          {rowData.game?.stocks
            ?.sort((a, b) => b.open_24h - a.open_24h)
            .filter(
              (stocks) =>
                stocks.name?.toLowerCase().includes(query?.toLowerCase()) ||
                stocks.coinId?.toLowerCase().includes(query?.toLowerCase())
            )
            .map((data, index) => {
              const isCoinSelected = selectedCoins.some(
                (item) => item.stocks === data._id
              );
              return (
                <Grid
                  key={index}
                  position={"relative"}
                  item
                  xs={2}
                  sm={4}
                  md={4}
                >
                  <Box
                    sx={{
                      backgroundColor: isCoinSelected
                        ? "transparent"
                        : "#022737",
                      padding: 2,
                      borderRadius: "16px",
                      border: 1,
                      opacity: isCoinSelected ? 0.6 : 1,
                      borderColor: "#11B1F4",
                      position: "relative",
                      //   minWidth: "300px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                    onClick={() => handleIncrement(data)}
                  >
                    <Button
                      sx={{
                        position: "absolute",
                        top: -18,
                        right: -28,
                      }}
                      onClick={() => handleIncrement(data)}
                    >
                      <img
                        width="26px"
                        height="26px"
                        src={
                          isCoinSelected
                            ? "/Images/deselect.png"
                            : "/Images/plus-symbol.png"
                        }
                        alt="power-off-button"
                      />
                    </Button>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"}>
                        <img
                          src={data.image}
                          style={{ width: "40px", height: "40px" }}
                          alt=""
                        />
                        <Box marginLeft={1}>
                          <Typography sx={{ fontWeight: 600 }}>
                            {data.coinId}
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize={14}
                            color={"gray"}
                          >
                            {data.name?.length > 20
                              ? `${data.name?.slice(0, 20)}...`
                              : data?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography>${data?.price?.toFixed(2) ?? 0}</Typography>
                        <Typography
                          sx={{
                            color:
                              data?.price_change_percentage_24h < 0
                                ? "#FF4C67"
                                : "#00FF66",
                          }}
                        >
                          {data?.price_change_percentage_24h?.toFixed(2) ?? 0}%
                        </Typography>
                      </Box>
                    </Stack>
                    {/* <Stack
            direction={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography sx={{ fontSize: 14 }}>Enter Percentage</Typography>
            <Typography
              sx={{
                background: "#011F2D",
                padding: 0.5,
                borderRadius: "8px",
                fontWeight: 600,
              }}
            >
              100 %
            </Typography>
          </Stack> */}
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Box>

      <>
        <Dialog
          open={confirmModalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            overflow: "hidden",
            boxShadow: "none",
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              backgroundColor: "white",
              padding: 2,
              width: isMobile ? "100%" : "400px",
              height: "100%",
              paddingY: 4,
              position: "relative",
            }}
          >
            {!isSubmitting && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "gray",
                  margin: 1,
                  "&:hover": {
                    color: "#11B1F4",
                    backgroundColor: "transparent",
                  },
                }}
                size="medium"
                onClick={handleClose}
              >
                <CloseIcon fontSize="medium" sx={{ color: "gray" }} />
              </IconButton>
            )}
            <img
              style={{
                width: 80,
                height: 80,
                marginTop: 4,
              }}
              src={
                nobalance
                  ? "/Images/popups/insuficient.png"
                  : "/Images/popups/confirm.png"
              }
              alt=""
            />
            <DialogTitle
              sx={{
                color: "black",
                fontWeight: 600,
              }}
            >
              {nobalance
                ? "Insufficient Balance"
                : `Game Confirmation Of Pick ${rowData?.game?.gameType}`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  color: "gray",
                }}
                id="alert-dialog-slide-description"
              >
                {nobalance
                  ? "You don’t have enough credits to enter the contest"
                  : `Please confirm entry fee of $${
                      rowData?.game?.entryFees?.toFixed(2) ?? 0
                    } USDC.`}
              </DialogContentText>
              {joinContestProgress && (
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    paddingTop: "5%",
                  }}
                >
                  <LinearProgressWithLabel value={joinContestProgress} />
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              {/* {nobalance ? (
                  <>
                    <Button
                      sx={{
                        backgroundColor: "#F41111",
                        color: "white",
                        textTransform: "none",
                        paddingX: 2,
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: alpha("#F41111", 0.5),
                        },
                      }}
                      onClick={handleClose}
                    >
                      Discard
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#58B112",
                        color: "white",
                        textTransform: "none",
                        paddingX: 2,
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: alpha("#58B112", 0.5),
                        },
                      }}
                      onClick={() => navigate("/deposit/usdc")}
                    >
                      Deposit
                    </Button>
                  </>
                ) : (
                  <> */}
              <Button
                sx={{
                  backgroundColor: "#F41111",
                  color: "white",
                  textTransform: "none",
                  paddingX: 2,
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: alpha("#F41111", 0.5),
                  },
                }}
                onClick={handleClose}
              >
                Discard
              </Button>
              <LoadingButton
                loading={isSubmitting}
                sx={{
                  backgroundColor: "#58B112",
                  color: "white",
                  textTransform: "none",
                  paddingX: 2,
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: alpha("#58B112", 0.5),
                  },
                }}
                onClick={
                  nobalance
                    ? () => navigate("/deposit/usdc")
                    : handleAddPariticpant
                }
              >
                {nobalance ? "Deposit" : "Confirm"}
              </LoadingButton>
              {/* </>
                )} */}
            </DialogActions>
          </Box>
        </Dialog>
      </>
    </>
  );
};

export default SelectStocksPage;
