import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  ListItem,
  Badge,
} from "@mui/material";
import React from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { IoIosArrowForward } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import styled from "styled-components";

const StyledMyGamesBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -20,
    top: 16,
    backgroundColor: "#FF3030",
    color: "white",
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: "1 6px",
  },
}));

const ExpandableMenu = ({
  getIcons,
  text,
  expandableListItem = [],
  background,
  hoverBackground,
  joinedGameCount,
}) => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ListItemButton
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          borderRadius: "17px",
          paddingY: 1.8,
          background: background ?? "none",
          "&:hover": {
            background: hoverBackground ?? "none",
          },
        }}
        onClick={handleClick}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {getIcons}

          <StyledMyGamesBadge
            // color="secondary"
            overlap="circular"
            badgeContent={joinedGameCount}
          >
            <ListItemText
              sx={{
                marginLeft: 2,
                // textShadow: "6px 3px 15px #39B149",
                // color: "white",
                color: "#AFB5C0",
              }}
              primary={text}
            />
          </StyledMyGamesBadge>
        </div>

        <IoIosArrowForward fontSize={18} color="#AFB5C0" />
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {expandableListItem &&
            expandableListItem?.map((e, i) => (
              <ListItem
                key={i}
                disablePadding
                components={NavLink}
                sx={{
                  paddingX: 1,
                  // paddingLeft: collapsed ? 1 : 2
                }}
              >
                <NavLink
                  to={`${e?.link}`}
                  style={({ isActive }) => ({
                    background: isActive ? "#001D2A" : "none",
                    textDecoration: "none",
                    color: isActive ? "white" : "white",
                    width: "100%",
                    borderRadius: "14px",
                    fontWeight: isActive ? 800 : 400,

                    // padding: "5px 5px",
                  })}
                >
                  <ListItemButton
                    sx={{
                      width: "100%",
                      borderRadius: "17px",
                      paddingY: 1.8,
                      // marginBottom: 1,
                      color: e?.color ?? "#AFB5C0",
                      transition: "all 0.5s ease",
                    }}
                    // onClick={() => handleToggle(index)}
                  >
                    {/* {i === 1 ? (
                      <FaArrowTrendUp size={18} />
                    ) : (
                      <FaArrowTrendDown size={18} />
                    )} */}
                    {e?.icon ?? <></>}

                    {/* <StyledMyGamesBadge
                      // color="secondary"
                      overlap="circular"
                      badgeContent={e?.joinedGameCount}
                    > */}
                    <ListItemText
                      sx={{
                        display: "block",
                        marginLeft: 2,
                      }}
                      primary={e?.label}
                    />
                    {/* </StyledMyGamesBadge> */}
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default ExpandableMenu;

{
  /* <NavLink
  to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
  className="nav-link-bw"
>
  <ListItemButton
    sx={{
      width: "100%",
      borderRadius: "17px",
      paddingY: 1.8,
      // marginBottom: 1,
    }}
  >
    {getIcons(1)}

    <ListItemText
      sx={{
        marginLeft: 2,
        textShadow: "6px 3px 15px #39B149",
        color: "white",
      }}
      primary={text}
    />

    <IoIosArrowForward fontSize={18} />
  </ListItemButton>
</NavLink>; */
}
