import { Box, Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkTimeReached } from "../../Services/Service";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";

const JoinButton = ({ row }) => {
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("token");
  const { userBalance } = useContext(AuthContext);

  // const [isLoading, setIsLoading] = useEffect(false);

  console.log(row?.participantsLength, "participantsLength");

  const isWithinTwoMinutesBefore = async (row) => {
    // setIsLoading(true);
    try {
      // console.log(
      //   userBalance,
      //   row?.game?.entryFees,
      //   userBalance < row?.game?.entryFees,
      //   "USERBALACE"
      // );
      if (userId && token) {
        if (
          parseFloat(userBalance ?? 0) < parseFloat(row?.game?.entryFees ?? 0)
        ) {
          return toast.error(
            "You don't have enough credits to enter the contest"
          );
        }

        const response = await checkTimeReached({
          startTime: row?.startTime,
        });
        const result = response?.data;
        console.log(result, "Time Reached");
        if (result?.data === true) {
          // setIsLoading(false);
          return toast.error(
            "You can not join a contest 2 min prior to start time"
          );
        }
        // setIsLoading(false);
        console.log("SUCCESS");

        if (row?.game?.gameAssetType === "STOCKS") {
          navigate("/select-stocks", {
            state: { rowData: row },
          });
        } else {
          navigate("/select-cryptos", {
            state: { rowData: row },
          });
        }
      } else {
        navigate("/signup");
      }

      // if (userId && token) {
      //   navigate("/select-cryptos", {
      //     state: { rowData: row },
      //   });
      // } else {
      //   navigate("/signup");
      // }
    } catch (error) {
      console.error(error);
      // setIsLoading(false);
      if (error?.response?.data?.message) {
        return toast.error(
          `${error?.response?.data?.message} Please re-login and continue.`
        );
      }
      if (error?.response?.data?.error) {
        return toast.error(`${error?.response?.data?.error}`);
      }

      return toast.error(
        `Something went wrong, please try again later. ${error?.message}`
      );
    }
  };

  const disableButton = (status) => {
    switch (status) {
      case "waiting":
        return row?.participantsLength === row?.game?.maxPlayer ? true : false;
      case "upcoming":
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            // height: 35,
            width: 80,
            borderRadius: "20px",
            background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
            textTransform: "none",
            display: "flex",
            // display: "inline-block",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            "&:disabled": {
              backgroundColor: "#085A7D",
            },
            "&:hover": {
              backgroundColor: " #10ADEF95",
            },
            px: 1,
          }}
          onClick={async () => {
            // const startTime = row?.startTime;
            // console.log(startTime);
            await isWithinTwoMinutesBefore(row);
            // console.log(result); // true or false
          }}
          // disabled={
          //   row?.participantsLength === row?.game?.maxPlayer ? true : false
          // }
          disabled={disableButton(row?.status)}
        >
          {/* {row.isJoined ? "Joined" : "Join"} */}
          {/* {row.isJoined ? "Join" : "Join"} */}

          {row?.status === "upcoming" ? "Join" : "Join"}
        </Button>
      </Box>
    </>
  );
};

export default JoinButton;
