import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AvatarGroups from "../AvatarGroup";
import { useTheme } from "@emotion/react";

export const FooterSection = ({ text, link, onClick, size }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <Box
        // sx={{ position: "absolute", left: 0 }}
        display={"flex"}
        alignItems={"center"}
        flexDirection={{ md: "row", xs: "column" }}
        gap={2}
        marginBottom={4}
        justifyContent={isSmall && "start"}
      >
        <AvatarGroups size={size ?? 5} />
        <Box display={"flex"} flexDirection={"column"} marginLeft={1}>
          {" "}
          <Typography
            variant="body2"
            sx={{
              color: "white",
              // fontWeight: 300,
              fontFamily: "Montserrat",
              marginBottom: 1,
            }}
          >
            {text}
            <Link
              component="button"
              variant="body2"
              to={onClick}
              style={{
                color: "white",
              }}
            >
              {link}
            </Link>
          </Typography>
          <Typography variant="caption" color={"white"}>
            100+ Users joined last Month
          </Typography>
        </Box>
      </Box>
    </>
  );
};
