import { useTheme } from "@emotion/react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getHomePageLeaderBordData } from "../../Services/Service";
import { FiCalendar } from "react-icons/fi";
import HomeLeaderBoardTeam from "./HomeLeaderBoardTeam";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const HomeLeaderBoard = () => {
  const [currentTab, setCurrentTab] = useState("0");
  const [seriesData, setSeriesData] = useState("month");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const page = 0;
  const limit = 10;
  // const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const [gameType, setGameType] = useState("Biggest Winner");

  const winnerType = ["Biggest Winner", "Biggest Loser", "Crypto Parlay"];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
    console.log(e.target.id);
    setGameType(winnerType[e.target.id]);
  };

  const handleChangeSeriesData = (event) => {
    setSeriesData(event.target.value);
  };

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const { data, isLoading, refetch } = useQuery(
    ["homePageLeaderBoard", gameType, seriesData, page, limit],
    async () => {
      try {
        // await delay(200);
        const response = await getHomePageLeaderBordData({
          dateFilter: seriesData,
          winnerType: gameType,
          page: page + 1,
          limit,
        });
        return response.data?.data?.participants;
      } catch (error) {
        console.log(error);
      }
    },
    {
      refetchOnWindowFocus: false,
      // cacheTime: 10 * 1000,
      cacheTime: 0,
      retry: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  console.log(data, "HLB");
  console.log(gameType);

  const tabs = [
    {
      id: "1",
      tabTitle: "Biggest Winner",
      // data,
    },
    {
      id: "2",
      tabTitle: "Biggest Loser",
    },
    // {
    //   id: "3",
    //   tabTitle: "Crypto Parlay",
    // },
  ];

  const options = [
    { option: "Weekly", value: "week" },
    { option: "Monthly", value: "month" },
  ];

  return (
    <Box
      sx={{
        // width: "100%",
        height: "510px",
        // background: "linear-gradient(180deg, #001D2A 23.5%, #002839 100%)",
        background: "#002839",
        marginTop: isMobile ? 1 : 4,
        borderRadius: "20px",
        padding: isMobile ? 0.5 : 3,
        display: "flex",
        flexDirection: "column",
      }}
      gap={4}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          paddingTop={1.5}
          paddingLeft={1}
          fontWeight={500}
          fontSize={20}
        >
          Leaderboard
        </Typography>

        <Box
          display={"flex"}
          alignItems={"center"}
          paddingTop={1.5}
          // marginRight={isMobile ? 1 : 2}
        >
          <FiCalendar size={isMobile ? 18 : 24} />
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              width: "82px",
              "& fieldset": { border: "0 !important" },
              "& select": {
                pl: 1,
                py: 0.5,

                pr: "24px !important",
                typography: "subtitle2",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: 0.75,
                bgcolor: "background.neutral",
              },
              "& .MuiNativeSelect-icon": {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.option}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#033D55",
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "12px",
        }}
        // gap={1}
      >
        {tabs.map((tab, index) => (
          <Button
            key={index}
            id={index}
            disabled={currentTab === `${index}`}
            onClick={handleTabClick}
            sx={{
              color: "white",
              width: "100%",
              // paddingY: 1,
              borderRadius: "7px",
              "&.Mui-disabled": {
                background:
                  "linear-gradient(90deg, #DEB940 0%, #BC9000 28%, #C7A947 51%, #C79902 71.5%, #F2CA46 100%)",
                color: "white",
                border: 1,
                borderColor: "#FFE17E",
              },
              textTransform: "none",
              fontSize: isMobile ? 7 : 16,
            }}
          >
            {tab.tabTitle}
          </Button>
        ))}
      </Box>

      <Box
        sx={{
          overflow: "auto",
        }}
      >
        {/* {tabs.map(
          (tab, index) =>
            currentTab === `${tab.id}` && ( */}

        {isLoading ? (
          <>
            <Box sx={{ width: "100%", height: "100%" }}>
              <Skeleton animation="wave" height={80} />
              <Skeleton animation="wave" height={80} />
              <Skeleton animation="wave" height={80} />
            </Box>
          </>
        ) : (
          <>
            {data.length === 0 ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    paddingTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>There are no data.</Typography>
                </Box>
              </>
            ) : (
              <>
                {" "}
                <Box
                  // key={index}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={isMobile ? 2 : 4}
                  sx={{
                    width: "100%",
                  }}
                >
                  {data.map((user) => (
                    <Stack
                      key={user?._id}
                      sx={{
                        // background: "#042836",
                        padding: 1,
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderRadius: "12px",
                      }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        gap={isMobile ? 1 : 2}
                        sx={{
                          minWidth: { xs: "60%", sm: "50%", md: "45%" },
                        }}
                      >
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: isMobile ? 30 : 50,
                              height: isMobile ? 30 : 50,
                            }}
                            alt="Travis Howard"
                            src={user?.user?.profilePic}
                          />
                        </Badge>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                        >
                          <Typography
                            variant="caption"
                            fontSize={isMobile ? 8 : 16}
                            fontWeight={500}
                          >
                            {user.user?.name}
                          </Typography>
                          <HomeLeaderBoardTeam
                            team={
                              user?.gameData[0]?.gameAssetType === "STOCKS"
                                ? user?.selectedStocks
                                : user?.selectedCryptos
                            }
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          fontSize={isMobile ? 8 : 16}
                          marginTop={0.5}
                          noWrap
                        >
                          {/* + ${user?.totalAmount.toFixed(2)} */}+ $
                          {champsCovertDecimals(
                            user?.totalAmount,
                            commonData?.tokenDecimal
                          )}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          sx={{
                            // background:
                            //   "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                            // paddingY: isMobile ? 0.4 : 0.8,
                            background:
                              "linear-gradient(90deg, #DEB940 0%, #BC9000 28%, #C7A947 51%, #C79902 71.5%, #F2CA46 100%)",
                            border: "1px solid var(--goldStroke, #FFE17E)",
                            height: { xs: 20, sm: 30, md: 30 },
                            paddingX: isMobile ? 0 : 2,
                            // width: isMobile ? "10px" : "20px",
                            minWidth: isMobile ? "40px" : "50px",
                            // "&:hover": {
                            //   background: "#FFE17E",
                            // },
                            borderRadius: "18px",
                            color: "white",
                            fontSize: isMobile ? 6 : 12,
                            textTransform: "none",
                          }}
                          onClick={() =>
                            navigate(`/leaderboard-details/${user?.user?._id}`)
                          }
                        >
                          Details
                        </Button>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </>
            )}
          </>
        )}

        {/* //     )
        // )} */}
      </Box>
    </Box>
  );
};

export default HomeLeaderBoard;
