import {
  Avatar,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import PageLoader from "../Loaders/PageLoader";
import { useTheme } from "@emotion/react";
import { useQuery } from "react-query";
import {
  // GetDetailedResult,
  getSinglePlayerLeaderBoard,
} from "../../Services/Service";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import CryptoTooltip from "../Tooltip/LightToolTip";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#03364C",
  fontSize: "16px",
  fontWeight: "500",
  borderBottom: "none",
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "#022737",
  borderRadius: "48px",
}));
const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: 700, lg: 700 },
  bgcolor: "#03364C",
  borderRadius: "35px",
  //   border: "2px solid #000",
  padding: 2,
  //   boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 550, sm: 550, md: 600, lg: 600 },
  overflowY: "scroll",
};

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//     fontWeight: 500,
//   },
// }));

const LiveStockScoreModal = ({ row }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rowId, setRowId] = useState();
  const [participantId, setParticipantId] = useState();
  const [showFullStocks, setShowFullStocks] = useState(false);
  const [showModalFullStocks, setShowModalFullStocks] = useState(false);

  const handleOpen = async (id, participantId) => {
    setOpen(true);
    setRowId(id);
    setParticipantId(participantId);
  };

  const handleClose = () => {
    setOpen(false);
    setRowId("");
    setParticipantId("");
  };

  const userId = sessionStorage.getItem("userId");

  const handleShowAllStocks = () => setShowFullStocks((prev) => !prev);

  const handleShowModalAllStocks = () =>
    setShowModalFullStocks((prev) => !prev);

  const { data, isLoading } = useQuery(
    ["contestResult", rowId],
    async () => {
      try {
        if (rowId) {
          const contestResult = await getSinglePlayerLeaderBoard({
            gameInstanceId: rowId,
            userId,
            participantId,
          });
          console.log("CALL GCR");
          return contestResult?.data?.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    {
      retryOnMount: true,
      staleTime: 5 * 1000,
      enabled: true,
      refetchInterval: 5 * 1000,
    }
    // {
    //   refetchInterval: 5000,
    //   refetchIntervalInBackground: false,
    //   refetchOnMount: "always",
    //   cacheTime: 0,
    // }
  );

  console.log(data, "contestResult");

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          cursor: "pointer",
        }}
        // onClick={() => handleOpen(row?._id)}
      >
        <Box
          onClick={() => handleOpen(row?._id, row?.participant?._id)}
          display={"flex"}
          gap={1}
          sx={
            {
              // "&:hover": {
              //   borderBottom: "1px solid #11B1F4",
              //   paddingBottom: 0.4,
              //   cursor: "pointer",
              //   // "& > .avatar": {
              //   //   // Apply styles to avatars when Box is hovered
              //   //   border: "2px solid #11B1F4", // Add any additional styles here
              //   // },
              // },
            }
          }
        >
          {row.participant?.selectedStocks?.slice(0, 4).map((stock, index) => (
            <div key={index}>
              <CryptoTooltip
                disableFocusListener
                disableTouchListener
                title={stock?.stocks?.name}
                placement="top"
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                        {
                          marginBottom: "5px",
                        },
                    },
                  },
                }}
              >
                <Avatar
                  // className="avatar"
                  sx={{
                    width: 26,
                    height: 26,
                    border: "1px solid white",
                    padding: 0.3,
                  }}
                  src={stock.stocks?.image}
                />
              </CryptoTooltip>
            </div>
          ))}
          {row.participant?.selectedStocks
            ?.slice(4, 100)
            .map((stock, index) => (
              <div key={index}>
                <CryptoTooltip
                  disableFocusListener
                  disableTouchListener
                  title={stock?.stocks?.name}
                  placement="top"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                          {
                            marginBottom: "5px",
                          },
                      },
                    },
                  }}
                >
                  <Avatar
                    // className="avatar"
                    sx={{
                      display: showFullStocks ? "block" : "none",
                      width: 26,
                      height: 26,
                      border: "1px solid white",
                      padding: 0.3,
                    }}
                    src={stock.stocks?.image}
                  />
                </CryptoTooltip>
              </div>
            ))}
        </Box>
        {row.participant?.selectedStocks?.length > 4 && (
          <Box
            // className="avatar"
            sx={{
              width: 26,
              height: 26,
              marginLeft: 1,
              borderRadius: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: 16,
              color: "white",
              "&:hover": {
                border: "1px solid #11B1F4",
                cursor: "pointer",
              },
              padding: 1,
              background:
                "linear-gradient(to right, #11B1F4 10%, #085A7D  100%, #085A7D 100%)",
            }}
            onClick={handleShowAllStocks}
          >
            {showFullStocks ? "-" : "+"}
          </Box>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: isMobile ? "column" : "row",
              flexDirection: "row",
              // alignItems: isMobile ? "start" : "center",
              alignItems: "center",
              // justifyContent: isMobile ? "center" : "space-between",
              justifyContent: "space-between",
              gap: 2,
              // margin: 2
              marginTop: 2,
              marginBottom: 2,
              marginX: { xs: 1, md: 2 },
              position: "relative",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={500}
              fontSize={isMobile ? 20 : 24}
            >
              Live Score
            </Typography>

            {/* <Button
            endIcon={<CancelIcon fontSize="large" />}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              "&:hover": {
                color: "#11B1F4",
              },
            }}
          /> */}

            {/* <IconButton
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -25,
                  "&:hover": {
                    color: "#11B1F4",
                    backgroundColor: "transparent",
                  },
                  display: isMobile ? "block" : "none",
                }}
                aria-label="delete"
                size="medium"
                onClick={handleClose}
              >
                <CloseIcon fontSize="large" />
              </IconButton> */}
            {row.game?.gamePaymentType === "Free" ? (
              <Typography fontWeight={700}>$0.00</Typography>
            ) : (
              <Typography fontWeight={700}>
                {/* ${data?.amount?.toFixed(2)} USDC */}$
                {champsCovertDecimals(data?.amount, commonData?.tokenDecimal)}{" "}
                USDC
              </Typography>
            )}
          </Box>

          {/* <div> */}
          {isLoading ? (
            <>
              <PageLoader />
            </>
          ) : (
            <>
              {/* {data?.map((user) => ( */}
              <>
                {/* <Typography fontWeight={700} textAlign={"end"} margin={2}>
                    ${data?.amount?.toFixed(2)} USDC
                  </Typography> */}
                <TableContainer>
                  <Table
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0px 20px",
                      width: "100%",
                    }}
                  >
                    <StyledTableHead>
                      <StyledTableCell
                        sx={{
                          background: "#022737",
                          borderBottomLeftRadius: "48px",
                          borderTopLeftRadius: "48px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 2,
                            gap: 1,
                          }}
                        >
                          <Typography
                            fontWeight={700}
                            fontSize={24}
                            marginRight={2}
                          >
                            {row?.status === "started"
                              ? data?.livePosition
                              : data?.position}
                          </Typography>
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                            }}
                            src={data?.userId?.profilePic}
                          />
                          <Typography>{data?.userId?.username}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          background: "#022737",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {" "}
                        <Box display={"flex"} gap={1} flexDirection={"row"}>
                          {row.participant?.selectedStocks
                            ?.slice(0, 4)
                            .map((stock, index) => (
                              <div key={index}>
                                <CryptoTooltip
                                  disableFocusListener
                                  disableTouchListener
                                  title={stock?.stocks?.name}
                                  placement="top"
                                  slotProps={{
                                    popper: {
                                      sx: {
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginBottom: "5px",
                                          },
                                      },
                                    },
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 26,
                                      height: 26,
                                      border: "1px solid white",
                                      padding: 0.3,
                                    }}
                                    src={stock.stocks?.image}
                                  />
                                </CryptoTooltip>
                              </div>
                            ))}

                          {row.participant?.selectedStocks
                            ?.slice(4, 100)
                            .map((stock, index) => (
                              <Avatar
                                key={index}
                                // className="avatar"
                                sx={{
                                  display: showModalFullStocks
                                    ? "block"
                                    : "none",
                                  width: 26,
                                  height: 26,
                                  border: "1px solid white",
                                  padding: 0.3,
                                }}
                                src={stock.stocks?.image}
                              />
                            ))}
                        </Box>
                        {(row.participant?.selectedStocks?.length ?? 0) > 4 && (
                          <Box
                            // className="avatar"
                            sx={{
                              width: 26,
                              height: 26,
                              marginLeft: 1,
                              borderRadius: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 600,
                              fontSize: 16,
                              color: "white",
                              "&:hover": {
                                cursor: "pointer",
                                borderBottom: "1px solid #11B1F4",
                              },
                              padding: 1,
                              background:
                                "linear-gradient(to right, #11B1F4 10%, #085A7D  100%, #085A7D 100%)",
                            }}
                            onClick={handleShowModalAllStocks}
                          >
                            {showModalFullStocks ? "-" : "+"}
                          </Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          background: "#022737",
                          borderBottomRightRadius: "48px",
                          borderTopRightRadius: "48px",
                        }}
                      >
                        {data?.averagePercentage <= 0 ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            fontWeight={700}
                          >
                            {" "}
                            <TrendingDownIcon
                              sx={{
                                color: "#FF4C67",
                              }}
                            />
                            <Typography
                              fontWeight={700}
                              fontSize={20}
                              color={"#FF4C67"}
                            >
                              {data?.averagePercentage?.toFixed(5)}%
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            color={"#00FF66"}
                            fontWeight={700}
                            fontSize={20}
                          >
                            <TrendingUpIcon
                              sx={{
                                color: "#00FF66",
                              }}
                            />{" "}
                            +{data?.averagePercentage?.toFixed(5)}%
                          </Box>
                        )}
                      </StyledTableCell>
                    </StyledTableHead>

                    {data?.selectedStocks?.map((stock, index) => (
                      <TableBody key={index}>
                        <TableRow
                          sx={{
                            background: "#042836",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              borderBottomLeftRadius: "48px",
                              borderTopLeftRadius: "48px",
                              //   fontSize: 26,
                              //   fontWeight: 500,

                              paddingLeft: 3,
                            }}
                          >
                            <Box display={"flex"} gap={1}>
                              <Avatar
                                sx={{
                                  width: 35,
                                  height: 35,
                                  border: "1px solid white",
                                  padding: 0.5,
                                }}
                                src={stock.stocks?.image}
                              />
                              <Box
                                height={"100%"}
                                display={"flex"}
                                alignItems={"start"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                paddingTop={1}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight={500}
                                  fontSize={18}
                                  lineHeight={0}
                                  whiteSpace={"nowrap"}
                                >
                                  {stock.stocks?.name?.length > 18
                                    ? `${stock.stocks?.name.slice(0, 18)}...`
                                    : stock.stocks?.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  fontWeight={500}
                                  fontSize={12}
                                  color={"#AAAAAA"}
                                  marginTop={1.6}
                                  // lineHeight={3}
                                >
                                  {stock.stocks?.symbol}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>

                          <TableCell sx={{ borderBottom: "none" }}></TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "none",
                              borderBottomRightRadius: "48px",
                              borderTopRightRadius: "48px",
                              color: "white",
                              fontWeight: 500,

                              fontSize: 16,
                            }}
                          >
                            {data.gameInstance?.status === "started" ? (
                              stock?.livePercentage <= 0 ? (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={1}
                                  fontWeight={700}
                                >
                                  {" "}
                                  <TrendingDownIcon
                                    sx={{
                                      color: "#FF4C67",
                                    }}
                                  />
                                  {stock?.livePercentage === null
                                    ? 0.0
                                    : stock?.livePercentage?.toFixed(5)}
                                  %
                                </Box>
                              ) : (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={1}
                                  fontWeight={700}
                                >
                                  <TrendingUpIcon
                                    sx={{
                                      color: "#09D780",
                                    }}
                                  />{" "}
                                  +{stock?.livePercentage?.toFixed(5)}%
                                </Box>
                              )
                            ) : (
                              <>
                                {stock?.closedPercentage <= 0 ? (
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                    fontWeight={700}
                                  >
                                    {" "}
                                    <TrendingDownIcon
                                      sx={{
                                        color: "#FF4C67",
                                      }}
                                    />
                                    {stock?.closedPercentage === null
                                      ? 0.0
                                      : stock?.closedPercentage?.toFixed(5)}
                                    %
                                  </Box>
                                ) : (
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                    fontWeight={700}
                                  >
                                    <TrendingUpIcon
                                      sx={{
                                        color: "#09D780",
                                      }}
                                    />{" "}
                                    +{stock?.closedPercentage?.toFixed(5)}%
                                  </Box>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </>
              {/* ))} */}

              {/* <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
            /> */}
            </>
          )}

          {/* </div> */}
        </Box>
      </Modal>
    </div>
  );
};

export default LiveStockScoreModal;
