import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  Popover,
  Skeleton,
  Stack,
  Typography,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { AiOutlineSend } from "react-icons/ai";
import { io } from "socket.io-client";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import cancelLiveChatSvg from "../../asset/cancelLiveChat.svg";
import sendMessageSvg from "../../asset/send.svg";
import { useTheme } from "@emotion/react";
import CryptoTooltip from "../Tooltip/LightToolTip";
import { ArrowDownward } from "@mui/icons-material";
import EmojiData from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
// import "@emoji-mart/css/emoji-mart.css";
import { DrawerContext } from "../../context/DrawerContext";
import CloseIcon from "@mui/icons-material/Close";
import { LuSticker } from "react-icons/lu";
import Sticker from "./Sticker";
import ReactTelegramStickerViewer from "./ReactTelegramStickerViewer";

const LiveCircleDot = () => {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#FF3030",
        display: "inline-block",
        marginLeft: 1,
        marginRight: 1,
      }}
    />
  );
};

// const userId = sessionStorage.getItem("token");

// const newSocket = io("http://localhost:3002/live-chat", {
//   query: { token: `${userId}` }, // Send the token as part of the connection query
// }); // Adjust the port if necessary

const SEND_MESSAGE = "SEND_MESSAGE";
const GET_ALL_MESSAGE = "GET_ALL_MESSAGE";
const MESSAGE = "MESSAGE";

const LiveTelegramChat = ({ handleLiveChatOpen }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const userId = sessionStorage.getItem("userId");
  const accessToken = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  const [isLoading, setLoading] = useState(false);
  const [sendMsgLoading, setSendMsgLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [hasMore, setHasMore] = useState(true);

  const [smallScreen, setSmallScreen] = useState(false); // Initial width
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { isHomePage } = useContext(DrawerContext);

  const [openEmoji, setOpenEmoji] = useState(false);
  const handleEmojiAction = () => {
    // console.log("INN");
    setOpenEmoji(!openEmoji);
    setOpenSticker(false);
  };

  const [openSticker, setOpenSticker] = useState(false);
  const handleStickerAction = () => {
    // console.log("INN");
    setOpenSticker(!openSticker);
    setOpenEmoji(false);
  };

  useEffect(() => {
    setSmallScreen(isSmallScreen);
  });

  const scrollableDivRef = useRef(null);

  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      // scrollableDivRef.current.scrollTop =
      //   scrollableDivRef.current.scrollHeight;

      scrollableDivRef.current.scrollTo({
        top: scrollableDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (sendMsgLoading === true) {
      scrollToBottom();
      setSendMsgLoading(false);
    }

    if (scrollableDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableDivRef.current;
      console.log("Scroll values:", { scrollTop, scrollHeight, clientHeight });
      const bottomThreshold = 80; // Adjust this value as needed

      // Check if user is near the bottom
      const isNearBottom =
        scrollHeight - scrollTop - clientHeight <= bottomThreshold;

      console.log("Should show button:", isNearBottom);

      setShowScrollButton(!isNearBottom);
    }
  }, [messages]);

  useEffect(() => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    // const newSocket = io("http://localhost:3005/live-chat", {
    //   query: { token: token },
    //   transports: ["websocket"],
    // });

    // //USING - staging
    // const newSocket = io("https://dev-staging-api.cryptochamps.io/live-chat", {
    //   query: { token: token },
    //   transports: ["websocket"],
    // });

    // const newSocket = io("wss://api.cryptochamps.io/live-chat", {
    //   query: { token: token },
    //   transports: ["websocket"],
    // });

    //USING - production
    const newSocket = io("https://api.cryptochamps.io/live-chat", {
      query: { token: token },
      transports: ["websocket"],
    });

    // const newSocket = io(`${process.env.REACT_APP_BACKEND_URL}/live-chat`, {
    //   query: { token: token },
    //   transports: ["websocket"],
    // });
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to socket");
      fetchMessages(newSocket, 1);
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from socket");
      setLoading(false);
    });

    newSocket.on("error", (error) => {
      console.error("Socket error:", error);
      setLoading(false);
    });

    newSocket.on(GET_ALL_MESSAGE, (message) => {
      console.log("Received messages", message.data.chats);

      if ((message?.data?.chats?.length ?? 0) > 0) {
        setMessages((prevMessages) => {
          const newMessage = message.data.chats.reverse();

          const uniqueMessages = newMessage.filter(
            (msg) => !prevMessages.some((prevMsg) => prevMsg._id === msg._id)
          );

          return [...uniqueMessages, ...prevMessages];
        });
        setHasMore(message.data.chats.length === limit); // Assuming server sends exactly 5 if there are more
      } else {
        setHasMore(false);
      }
      setLoading(false);
    });

    // newSocket.on(MESSAGE, (message) => {
    //   console.log("Received messages send", message);
    //   setMessages((prevMessages) => [...prevMessages, message]);
    // });

    newSocket.on(MESSAGE, (message) => {
      console.log("Received message send:", message);
      setMessages((prevMessages) => {
        // Check if a message with the same _id already exists
        const isDuplicate = prevMessages.some((msg) => msg._id === message._id);

        console.log(isDuplicate);
        // If it's not a duplicate, add it to the array
        if (!isDuplicate) {
          return [...prevMessages, message];
        }

        // If it's a duplicate, return the previous state unchanged
        return prevMessages;
      });

      if (sendMsgLoading === true) {
        setSendMsgLoading(false);
      }
    });

    return () => {
      newSocket.off(GET_ALL_MESSAGE);
      newSocket.off("error");
      newSocket.off("connect");
      newSocket.off("disconnect");
      newSocket.disconnect();
    };
  }, []);

  const fetchMessages = (socketToUse, pageNum = 1) => {
    console.log("Fetching messages");
    const requestData = {
      page: pageNum,
      limit: limit,
    };
    socketToUse.emit(GET_ALL_MESSAGE, requestData);
  };

  const loadMoreMessages = () => {
    console.log(`Has more : ${hasMore}, page : ${page}`);

    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
      fetchMessages(socket, page + 1);

      console.log(`Has more : ${hasMore}, page : ${page}`);
    }
  };

  const handleSendMessage = (stickerFileId) => {
    // const chatId = "-1002248352973"; // Replace with actual chat ID //production
    const chatId = "-1002219513434"; // Replace with actual chat ID //production
    // const chatId = "-1002229185861"; // Replace with actual chat ID //staging #TODO
    if (newMessage.trim() && socket) {
      const payload = {
        userId: userId,
        chatId: chatId,
        text: newMessage,
        replyToMessageId: null,
        username: userName, // Replace with actual username
        stickerFileId: stickerFileId ?? null,
        // "CAACAgQAAxUAAWd8_I88UicMmvfculBikft7s7uDAAJKEAACa7hwUlwdtfYaplR4NgQ",
      };
      console.log("Sending message:", payload);
      socket.emit(SEND_MESSAGE, payload);

      setNewMessage("");
      setSendMsgLoading(true);
      setOpenEmoji(false);
    } else if (stickerFileId && socket) {
      const payload = {
        userId: userId,
        chatId: chatId,
        replyToMessageId: null,
        username: userName, // Replace with actual username
        stickerFileId: stickerFileId ?? null,
        // "CAACAgQAAxUAAWd8_I88UicMmvfculBikft7s7uDAAJKEAACa7hwUlwdtfYaplR4NgQ",
      };
      console.log("Sending message:", payload);
      socket.emit(SEND_MESSAGE, payload);

      setNewMessage("");
      setSendMsgLoading(true);
      setOpenEmoji(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        flexDirection: "column",
        height: "100%",
        // minHeight: "100%",
        // maxHeight: "100%",
        justifyContent: "space-between",
        // position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "93%",
          // height: "450px",
          // background: "linear-gradient(180deg, #001D2A 23.5%, #002839 100%)",
          backgroundColor: "#002839",
          // marginTop: 4,
          // borderRadius: "20px",

          padding: 3,
          display: "flex",
          flexDirection: "column",
          borderRadius: "30px",
          position: "relative",
        }}
        // whiteSpace={"nowrap"}
      >
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Box
            // key={user?._id}
            sx={{
              // background: "#042836",
              // padding: 1,
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              //   justifyContent: "space-between",
              borderRadius: "12px",
            }}
          >
            <Box>
              <Typography
                variant="body2"
                paddingTop={1.5}
                //   paddingLeft={1}
                fontWeight={500}
                fontSize={20}
              >
                Live Chat
              </Typography>
            </Box>

            <Box
              width={30}
              height={50}
              sx={{
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <LiveCircleDot />
            </Box>
          </Box>

          <CryptoTooltip
            disableFocusListener
            disableTouchListener
            title={"Close"}
            placement="top"
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                    {
                      marginBottom: "5px",
                    },
                },
              },
            }}
          >
            <Box
              width={45}
              height={45}
              sx={{
                background: "#064865",
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  background: "#11B1F4",
                },
              }}
              onClick={handleLiveChatOpen}
            >
              {" "}
              {/* <KeyboardTabIcon size={22} className="gricon" /> */}
              <img src={cancelLiveChatSvg} alt="" />
            </Box>
          </CryptoTooltip>
        </Stack>
        <br></br>

        {/*##########################[CHAT]############################## */}

        {isLoading ? (
          <>
            <>
              <Box sx={{ width: "100%", height: "100%" }}>
                {[...Array(isSmallScreen ? 3 : isHomePage ? 7 : 3)].map(
                  (_, index) => (
                    <Skeleton key={index} animation="wave" height={80} />
                  )
                )}
              </Box>
            </>
          </>
        ) : (
          <>
            {messages?.length === 0 ? (
              <Box>
                <Typography
                  textAlign={"center"}
                  marginTop={4}
                  // sx={{ height: smallScreen ? 220 : 280 }}
                >
                  There are no messages{" "}
                </Typography>{" "}
              </Box>
            ) : (
              <div
                id="scrollableDiv"
                ref={scrollableDivRef}
                style={{
                  // height: smallScreen ? 220 : 300,
                  height: "100%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <InfiniteScroll
                  dataLength={messages.length}
                  next={loadMoreMessages}
                  style={{
                    display: "flex",
                    // flexDirection: "column-reverse"
                    flexDirection: "column",
                  }} //To put endMessage and loader to the top.
                  inverse={true} //
                  hasMore={hasMore}
                  // loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {showScrollButton && (
                    <Box
                      width={isSmallScreen ? 30 : 40}
                      height={isSmallScreen ? 30 : 40}
                      sx={{
                        background: "#064865",
                        borderRadius: 50,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        // "&:hover": {
                        //   background: "#11B1F4",
                        // },
                        position: "absolute", // Add this line
                        bottom: isSmallScreen ? 75 : 75, // Add this line
                        right: isSmallScreen ? 35 : 35, // Add this line
                        zIndex: 1, // Add this line
                      }}
                      onClick={scrollToBottom}
                    >
                      <ArrowDownward
                        style={{
                          width: isSmallScreen ? 15 : null,
                          height: isSmallScreen ? 15 : null,
                        }}
                      />
                    </Box>
                  )}
                  <Stack
                    direction={"column"}
                    gap={2}
                    sx={{ width: "100%", overflowY: "auto" }}
                  >
                    {messages?.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        // key={index}
                      >
                        <Box display={"flex"} alignItems={"start"} gap={1}>
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 30,
                              height: 30,
                            }}
                            src={data?.user?.profilePic}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", sm: "row" },
                              alignItems: { xs: "flex-start", sm: "start" },
                              gap: isSmallScreen ? 0 : 1, // Adds some space between elements
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="#11B1F4"
                              fontSize={{ xs: 15, sm: 14, md: 15 }}
                            >
                              {data?.user?.username ??
                                data?.data?.from?.first_name ??
                                "Admin"}
                            </Typography>

                            {data?.message && (
                              <Typography
                                fontSize={{ xs: 15, sm: 14, md: 15 }}
                                sx={{ width: "100%" }}
                              >
                                {
                                  // data?.message?.replace(userName ?? "", "") ?? ""
                                  // (data?.message ?? "").trim()
                                  data?.message?.replace(/^\S+\s*/, "") ?? ""
                                }
                              </Typography>
                            )}

                            {data?.stickers?.stickerUrl &&
                              data?.stickers?.stickerUrl?.endsWith(".tgs") && (
                                <ReactTelegramStickerViewer
                                  tgsUrl={data?.stickers?.stickerUrl}
                                  style={{ height: "100px", width: "100px" }}
                                />
                              )}

                            {data?.stickers?.stickerUrl &&
                              !data?.stickers?.stickerUrl?.endsWith(".tgs") && (
                                <img
                                  style={{
                                    backgroundColor: "transparent",
                                  }}
                                  src={
                                    // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_8.png"
                                    // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_9.png"
                                    // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_9.png"
                                    // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_10.png"
                                    data?.stickers?.stickerUrl
                                  }
                                  alt="sticker"
                                  width={100}
                                  height={100}
                                />

                                // <div data></div>
                                // <Image
                                //   src={data?.stickers?.stickerUrl}
                                //   webp={data?.stickers?.stickerUrl}
                                //   alt="I'm a webp image"
                                //   width={100}
                                //   height={100}
                                // />

                                // <div
                                //   // style={styles.container}
                                //   // data-src="https://cdn2.combot.org/utyaduck/webp/2xf09f918d.webp"
                                //   data-src={data?.stickers?.stickerUrl}
                                //   data-loaded="true"
                                // >
                                // <img
                                //   src="https://cdn2.combot.org/utyaduck/webp/2xf09f918d.webp"
                                //   // src={data?.stickers?.stickerUrl}
                                //   alt="Sticker"
                                //   width={100}
                                //   height={100}
                                // />
                                // </div>

                                // <picture>
                                //   <source
                                //     srcSet={data?.stickers?.stickerUrl}
                                //     type="image/webp"
                                //   />
                                //   <img
                                //     style={{
                                //       backgroundColor: "transparent",
                                //       // mixBlendMode: "soft-light",
                                //     }}
                                //     src={
                                //       "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/dda30165-fe8e-42c3-a1c3-19e88abf8f97_file_8.webp"
                                //     }
                                //     alt="sticker"
                                //     width={100}
                                //     height={100}
                                //   />
                                // </picture>
                              )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                </InfiniteScroll>
              </div>
            )}
          </>
        )}
        {openEmoji && (
          // <Box
          //   sx={{
          //     position: "absolute",
          //     bottom: 0,
          //     // width: "100%",
          //     // height: 10,
          //   }}
          // >
          //   <EmojiPicker
          //     theme="dark" // You can adjust this to 'light' or 'dark' based on your preference
          //     data={EmojiData}
          //     onEmojiSelect={(emoji) => {
          //       console.log(emoji);
          //       setNewMessage(newMessage + `${emoji?.native ?? ""}`);
          //     }}
          //   />
          // </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              // width: "100%", // Adjust as needed
              // backgroundColor: "rgba(0, 0, 0, 0.8)", // Optional: add a background color
              backgroundColor: "#181818",
              borderTopLeftRadius: 8, // Optional: rounded corners
              borderTopRightRadius: 8, // Optional: rounded corners
            }}
          >
            {/* Cancel button at the top */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 1, // Adjust padding as needed
              }}
            >
              <IconButton
                onClick={() => setOpenEmoji(false)} // Replace with your close handler
                sx={{ color: "white" }} // Set button color
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Emoji Picker */}

            <EmojiPicker
              // emojiSize={16}
              // perLine={11}
              // onClickOutside={fals}
              // onClickOutside={() => setOpenEmoji(true)}
              theme="dark"
              data={EmojiData}
              onEmojiSelect={(emoji) => {
                console.log(emoji);
                setNewMessage(newMessage + `${emoji?.native ?? ""}`);
              }}
              emojiSize={20}
              perLine={isSmallScreen ? 7 : 9}
            />
          </Box>
        )}

        {openSticker && (
          <Box
            sx={{
              width: "88%",
              position: "absolute",
              bottom: 0,
              // width: "100%", // Adjust as needed
              // backgroundColor: "rgba(0, 0, 0, 0.8)", // Optional: add a background color
              backgroundColor: "#181818",
              borderTopLeftRadius: 8, // Optional: rounded corners
              borderTopRightRadius: 8, // Optional: rounded corners
            }}
          >
            <Sticker
              setOpenSticker={setOpenSticker}
              openSticker={openSticker}
              handleSendMessage={handleSendMessage}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          color: "transparent",
          backgroundColor: "transparent",
          width: "100%",
          height: 10,
        }}
      ></Box>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "#002839",
          flexDirection: "column",
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: "25px",
        }}
      >
        <Input
          autoFocus
          fullWidth
          disableUnderline
          value={newMessage}
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Send a message"
          // onKeyPress={(e) => {
          //   if (e.key === "Enter") {
          //     e.preventDefault();
          //     handleSendMessage();
          //   }
          // }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          endAdornment={
            <InputAdornment position="start">
              <Box onClick={handleSendMessage}>
                {/* <AiOutlineSend size={24} color="#D0D4DC" /> */}
                <img
                  src={sendMessageSvg}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                aria-label="delete"
                // disabled
                color="primary"
                onClick={handleEmojiAction}
              >
                <EmojiEmotionsIcon
                  sx={{
                    color: "#085a7d",
                  }}
                />
              </IconButton>
              <IconButton
                aria-label="delete"
                // disabled
                color="primary"
                onClick={handleStickerAction}
              >
                <LuSticker color="#085a7d" />
              </IconButton>
              {/* <EmojiPicker data={EmojiData} onEmojiSelect={console.log} /> */}
            </InputAdornment>
          }
          sx={{
            color: "white",
            fontSize: 15,
          }}
        />
      </Box>

      {/* <Box sx={{ backgroundColor: "transparent", width: "100%", height: 20 }}>
        {""}
      </Box> */}
    </Box>
  );
};

export default LiveTelegramChat;
