import React, { useState } from "react";
import "../../Styles/main.css";
import { getLandingPageData } from "../../Services/Service";
import { useInfiniteQuery } from "react-query";
// import HeroComponent from "../../Components/Landing/HeroComponent";
import GeneralFaqs from "../../Components/Landing/GeneralFaqs";
// import GameTime from "../../Components/Landing/GameTime";
// import AboutUs from "../../Components/Landing/AboutUs";
import FutureComponent from "../../Components/Landing/FutureComponent";
import CampaignTable from "../../Components/Landing/CampaignTable";
import { Box } from "@mui/material";
// import { Link } from "react-router-dom";
// import CryptoChampTopBar from "./Topbar";
// import LandingPageSidebar from "./LandingPageSideBar";
import { LandingTopBanner } from "../../Components/Landing/TopBanner";
import { LandingMiddleVideo } from "../../Components/Landing/LandingMiddleVideo";
import LandingAmbassadorBanner from "../../Components/Landing/AmbassadorBanner";
import ChampsTokenRoadMap from "../../Components/Landing/ChampsTokenRoadMap";

const NewLandingPage = ({ isCollapsed }) => {
  const [homeData, setHomeData] = useState([]);
  const fetchHomePageData = async ({ pageParam = 1 }) => {
    const result = await getLandingPageData({
      page: pageParam,
      limit: 7,
    });
    setHomeData(result?.data?.data);
    return result?.data?.data?.faq;
  };

  const {
    data: faqs,
    isSuccess,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery("faqResults", fetchHomePageData, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page < Math.ceil(lastPage.totalCount / 7))
        return lastPage.page + 1;
      return false;
    },
    retry: true,
    refetchOnWindowFocus: false,
  });

  console.log(homeData, "faqs");

  return (
    <Box>
      {/* <Box>
        <LandingPageSidebar />
      </Box> */}

      <Box>
        {/* <CryptoChampTopBar /> */}
        {/* <HeroComponent homeData={homeData} /> */}
        <LandingTopBanner />
        {/* <AboutUs homedata={homeData} /> */}
        {/* <FutureComponent isCollapsed={isCollapsed} /> */}
        <LandingMiddleVideo />
        {/* <GameTime homedata={homeData} /> */}

        <CampaignTable />
        <ChampsTokenRoadMap />
        <LandingAmbassadorBanner />
        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: { md: 10 },
            pb: 3,
          }}
        >
          <a
            // to={"/"}
            href="#FAQ_SECTION"
            style={{
              // width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/Images/landingPage/ambassador.png"
              style={{
                width: "100%",
              }}
              loading="lazy"
            />
          </a>
        </Box> */}
        <GeneralFaqs
          faqs={faqs}
          isSuccess={isSuccess}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Box>
    </Box>
  );
};

export default NewLandingPage;
