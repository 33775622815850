import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { makeMagic } from "../../../lib/magic";
import {
  getBlockChianConfig,
  transactionGasFee,
} from "../../../Services/Service";

import contestAbi from "../../../Contract/contestAbi.json";
import USDCToken from "../../../Contract/USDC.json";
import { commonData } from "../../../constants/common";

export const useStockContractInitialization = () => {
  const [usdcContract, setUsdcContract] = useState(null);
  const [contestContract, setContestContract] = useState(null);
  const [blockChainConfig, setBlockChainConfig] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);
  const [isAlreadyApproved, setIsAlreadyApproved] = useState(false);
  const [nonceTx, setNonceTx] = useState(0);
  const [allowFeed, setAllowFeed] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // You can add any initialization logic here if needed
  }, []);

  const initializeContracts = async (usdcAmount) => {
    try {
      setProcessing(true);
      const blockChainConfig = await getBlockChianConfig();
      const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const contractAddress =
        blockChainConfig?.data?.stockGameSc?.contractAddress;

      const initContestContract = new ethers.Contract(
        contractAddress,
        contestAbi,
        signer
      );
      setContestContract(initContestContract);

      const paymentToken = await initContestContract.paymentToken();
      console.log("Selecting Crypto", paymentToken, "paymentToken");

      const finalAmount = ethers.utils.parseUnits(
        // usdcAmount.toString(),
        `${commonData?.allowanceAmount}`,
        commonData?.usdcDecimals
      );

      const usdcContract = new ethers.Contract(paymentToken, USDCToken, signer);

      const allowance = await usdcContract.functions.allowance(
        address,
        contractAddress
      );

      const isAmountExist = ethers.utils.formatUnits(
        allowance.toString(),
        commonData?.usdcDecimals
      );

      console.log("Amount exist ", isAmountExist.toString());

      // if (parseFloat(isAmountExist) < parseFloat(usdcAmount)) {
      if (parseFloat(isAmountExist) < parseFloat(usdcAmount)) {
        // Get the current nonce
        await approveAmountTx(
          usdcContract,
          provider,
          contractAddress,
          finalAmount,
          address
        );
        setIsAlreadyApproved(true);
      } else {
        setNonceTx(0);
        setIsAlreadyApproved(true);
        setProcessing(false);
        console.log("Selecting Crypto", "User Already approved");
      }

      setUsdcContract(usdcContract);
      setProvider(provider);
      setSigner(signer);
      setAddress(address);
      setBlockChainConfig(blockChainConfig);
    } catch (error) {
      console.log("Selecting Crypto", "INITIALIZE CONTRACT ", error);
      // throw error;
      setNonceTx(0);
      setProcessing(false);
    }
  };

  const approveAmountTx = async (
    usdcContract,
    provider,
    contractAddress,
    finalAmount,
    address
  ) => {
    const startTime = performance.now();
    let approveTransaction;
    try {
      const gasLimit = await usdcContract.estimateGas.approve(
        contractAddress,
        finalAmount
      );

      const gasPrice = await provider.getGasPrice();
      const gasPriceWithBuffer = gasPrice.mul(120).div(100);
      console.log(
        "Selecting Crypto",
        ethers.utils.formatEther(gasPriceWithBuffer.toString()),
        "gas price with buffer"
      );

      // Get signer's balance
      const signerBalance = await provider.getBalance(address);
      console.log(
        "Selecting Crypto",
        ethers.utils.formatEther(signerBalance.toString()),
        "Matic signerBalance"
      );

      const gasLimitWithBuffer = gasLimit.mul(120).div(100); // 120% of the estimate
      console.log(
        "Selecting Crypto",
        ethers.utils.formatEther(gasLimitWithBuffer.toString()),
        "gasLimitWithBuffer"
      );

      const tx_cost_with_buffer = gasPriceWithBuffer.mul(gasLimitWithBuffer);
      console.log(
        "Selecting Crypto",
        ethers.utils.formatEther(tx_cost_with_buffer.toString()),
        "tx_cost_with_buffer"
      );

      console.log(
        "Selecting Crypto",
        signerBalance.lt(tx_cost_with_buffer),
        "signerBalance.lt(tx_cost_with_buffer)"
      );

      if (signerBalance.lt(tx_cost_with_buffer)) {
        throw new Error(
          `There isn't enough MATIC in the sender's account to cover the gas fees`
        );
      } else {
        setAllowFeed(false);
        setProcessing(false);
      }

      const currentNonce = await provider.getTransactionCount(address);
      console.log("Selecting Crypto", "Nonce from select crypto", currentNonce);
      setNonceTx(currentNonce);

      const transaction = await usdcContract.approve(
        contractAddress,
        finalAmount,
        {
          gasLimit: gasLimit,
          gasPrice: gasPriceWithBuffer,
          nonce: currentNonce,
        }
      );

      const endTimeT = performance.now();
      const executionTimeT = endTimeT - startTime;
      console.log(
        `"Selecting Crypto  Transaction execution time: ${executionTimeT.toFixed(
          2
        )} milliseconds`
      );

      setProcessing(false);
      approveTransaction = await transaction.wait();

      console.log("Selecting Crypto", approveTransaction, "approveTransaction");
      // if (
      //   !approveTransaction?.transactionHash &&
      //   approveTransaction?.status !== 1
      // ) {
      //   // return ;
      //   throw new Error("Approve transaction is failed");
      // }
    } catch (error) {
      setProcessing(false);
      throw error;
    }
  };

  return {
    usdcContract,
    contestContract,
    blockChainConfig,
    provider,
    signer,
    address,
    isAlreadyApproved,
    nonceTx,
    allowFeed,
    processing,
    initializeContracts,
  };
};
