import React, { useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableRowClasses,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import {
  CancelGameInsance,
  getContestResult,
  checkTimeReached,
  getBlockChianConfig,
  magicGetRequestState,
  transactionGasFee,
} from "../../Services/Service";
import { useQuery } from "react-query";
import MyGamesBanner from "./MyGamesBanner";
import Toast from "../../Components/Snackbar/Toast";
import AlertPopup from "../../Components/Popups/AlertPopup";
import PageLoader from "../../Components/Loaders/PageLoader";
import { useTheme } from "@emotion/react";
import GameResultStatus from "../../Components/GameResult/GameResultStatus";
import GameDuration from "../../Components/Game/GameDuration";
import { useJoinedGameCount } from "../../constants/useJoinedGameCount";
import InvitationPopup from "../../Components/Popups/InvitationPopup";
import { ethers } from "ethers";
import contestAbi from "../../Contract/contestAbi.json";
import { makeMagic } from "../../lib/magic";
import JoinedPlayersProgressBar from "../../Components/Game/JoinedPlayersProgressBar";
import CryptoTooltip from "../../Components/Tooltip/LightToolTip";
import reactToast from "react-hot-toast";
import LiveStockScoreModal from "../../Components/Game/LiveStockScoreModel";
import LeaderBoardStockModal from "./LeaderBoardStockModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#03364C",
  fontSize: "16px",
  fontWeight: "500",
  borderBottom: "none",
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "#03364C",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "50px !important",
    padding: "0px",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 500,
  },
}));

const StockGameResult = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userId = sessionStorage.getItem("userId");

  const [currentTab, setCurrentTab] = useState("0");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState(0);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [toast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [gameInstanceId, setGameInstanceId] = useState();
  const [selectedGameInstance, setSelectedGameInstance] = useState();
  const [gameType, setGameType] = useState("AllGames");
  const [showFullStocks, setShowFullStocks] = useState(false);
  const [isInvitationPopupOpen, setIsInvitationPopupOpen] =
    React.useState(false);
  const [inviteRowData, setInviteRowData] = useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const winnerType = [
    "AllGames",
    "PastGames",
    "Biggest Winner",
    "Biggest Loser",
  ];

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const { refetch: refetchJoinedGameCount } = useJoinedGameCount();

  const {
    data: gameresultData,
    isLoading,
    refetch,
  } = useQuery(
    ["contestResult", page, limit, gameType],
    async () => {
      try {
        // await delay(200);
        const contestResult = await getContestResult({
          userId,
          page: page + 1,
          limit,
          gameType,
          gameAssetType: "STOCKS",
        });
        setTotal(contestResult?.data?.data?.total);
        contestResult?.data?.data?.gameInstances.sort((a, b) => {
          if (a.status === b.status) {
            if (a.status === "waiting") {
              // If status is waiting, sort by startTime ascending
              return new Date(a.startTime) - new Date(b.startTime);
            }
            // If status is not waiting, maintain the current sorting order
            return 0;
          }
          // Otherwise, sort by status (waiting, started, finished)
          const statusOrder = {
            started: 1,
            waiting: 2,
            closed: 3,
            "canceled ": 4,
          };
          return statusOrder[a.status] - statusOrder[b.status];
        });
        await refetchJoinedGameCount();
        return contestResult?.data?.data?.gameInstances;
      } catch (error) {
        console.log(error);
        // setResultLoading(false);
      }
    },
    {
      staleTime: 5000,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: true,
    }
  );

  console.log(gameresultData, "GD");

  const gameTabs = [
    {
      id: "1",
      tabTitle: "Up Coming",
      value: "AllGames",
    },
    {
      id: "2",
      tabTitle: "Past Games",
      value: "PastGames",
    },
    {
      id: "3",
      tabTitle: "Biggest Winner",
      value: "Biggest Winner",
    },
    {
      id: "4",
      tabTitle: "Biggest Loser",
      value: "Biggest Loser",
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
    console.log(e.target.id);
    setGameType(winnerType[e.target.id]);
    setPage(0);
  };
  const handleShowAllStocks = () => setShowFullStocks((prev) => !prev);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleToastClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  const handleCancelClick = async (id, gameInstance) => {
    const startTime = performance.now(); //Timer

    try {
      const timeReached = await checkTimeReached({
        startTime: gameInstance?.startTime,
      });

      console.log(timeReached);
      if (timeReached?.data?.data === true) {
        // return setToastMessage("Unable to cancel the game, Time out! ");
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");
        return setToastMessage(
          "Contests cannot be canceled two minutes prior to their start time. "
        );
      }

      let receipt;
      let query = {};

      if (gameInstance?.game?.gamePaymentType === "Paid") {
        const blockChainConfig = await getBlockChianConfig();
        const contractAddress =
          blockChainConfig?.data?.stockGameSc?.contractAddress;
        console.log(contractAddress, "Contract address");

        if (!contractAddress) {
          handleDialogClose();
          setOpenToast(true);
          setToastStatus("failed");
          return setToastMessage(
            "Oops! something went wrong please try again later - [contract]"
          );
        }

        const provider = new ethers.providers.Web3Provider(
          makeMagic.rpcProvider
        );

        // ⭐️ After user is successfully authenticated
        const signer = provider.getSigner();

        const address = await signer.getAddress();

        const contestContract = new ethers.Contract(
          // "0x267065D416240f092aDA1e72725C84A453BA5d22", //contract address
          contractAddress,
          contestAbi,
          signer
        );

        // /*
        const withdrawTrans =
          await contestContract.populateTransaction.withdraw(
            `${gameInstance?.contestId}`,
            `1`
            //   {
            //   gasLimit: 300000,
            // }
          );

        console.log(withdrawTrans, "joinContestTransaction");

        // Send gasless transaction to Magic Relayer (after user login)
        const gasless_withdraw_request =
          await makeMagic.wallet.sendGaslessTransaction(
            address, // User's Wallet address
            withdrawTrans
          );

        console.log(gasless_withdraw_request, "gasless_joinContest_request");

        // const withdrawResponse = await magicGetRequestState(
        //   gasless_withdraw_request.request_id
        // );
        const withdrawResponse = await pollTransactionStatus(
          gasless_withdraw_request?.request_id
        );

        receipt = withdrawResponse;

        if (!receipt?.transactionHash || receipt?.status !== 1) {
          handleDialogClose();
          setOpenToast(true);
          setToastStatus("failed");
          return setToastMessage(
            "Unable to cancel the game, Transaction failed"
          );
        }

        // */

        query = {
          transactionHash: `${receipt?.transactionHash}`,
        };
      }

      query = {
        ...query,
        participantId: gameInstance?.participant?._id,
      };

      console.log(query);
      const response = await CancelGameInsance(userId, id, query);
      if (response) {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("success");
        setToastMessage("Game Cancelled Successfully");
        refetch();
      }

      const endTimeT = performance.now();
      const executionTimeT = endTimeT - startTime;
      console.log(
        `Transaction cancel game execution time: ${executionTimeT.toFixed(
          2
        )} milliseconds`
      );
    } catch (error) {
      console.log(error);
      console.log(error.error?.rawMessage);
      if (error.response?.data?.error === "Time_Reached") {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");
        // setToastMessage("Unable to cancel the game, Time out! ");
        return setToastMessage(
          "Contests cannot be canceled two minutes prior to their start time. "
        );
      } else {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");

        if (error.error?.rawMessage)
          return setToastMessage(error?.error?.rawMessage);

        return setToastMessage("Failed to cancel the game");
        // else setToastMessage(error?.message);
      }
    }
  };

  const handleRefundClick = async (id, gameInstance) => {
    const startTime = performance.now();
    let receipt;

    try {
      if (!gameInstance?.contestId) {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");
        return setToastMessage("contest is not exist");
      }

      const blockChainConfig = await getBlockChianConfig();
      const contractAddress =
        blockChainConfig?.data?.stockGameSc?.contractAddress;

      if (!contractAddress) {
        return toast.error(
          "Oops! something went wrong please try again later - [contract]"
        );
      }

      const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);

      // ⭐️ After user is successfully authenticated
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      const contestContract = new ethers.Contract(
        // "0x267065D416240f092aDA1e72725C84A453BA5d22", //contract address
        contractAddress,
        contestAbi,
        signer
      );

      /*
      const data = await contestContract.refund(
        `${gameInstance?.contestId}`
        //   {
        //   gasLimit: 300000,
        // }
      );
      */

      // /*
      const refundPopulate = await contestContract.populateTransaction.refund(
        `${gameInstance?.contestId}`
        //   {
        //   gasLimit: 300000,
        // }
      );
      console.log(refundPopulate, "refundPopulate");

      // Send gasless transaction to Magic Relayer (after user login)
      const gasless_request = await makeMagic.wallet.sendGaslessTransaction(
        address, // User's Wallet address
        refundPopulate
      );

      console.log(gasless_request, "gasless_joinContest_request");

      const withdrawResponse = await pollTransactionStatus(
        gasless_request?.request_id
      );

      receipt = withdrawResponse;

      if (!receipt?.transactionHash || receipt?.status !== 1) {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");
        return setToastMessage("Unable to refund the game, Transaction failed");
      }

      console.log("Transaction Contest=> ", receipt?.transactionHash);

      // */

      const response = await CancelGameInsance(userId, id, {
        transactionHash: `${receipt?.transactionHash}`,
        isFromRefund: true,
        participantId: gameInstance?.participant?._id,
      });

      if (response) {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("success");
        setToastMessage("Game Refunded Successfully");
        refetch();
      }

      const endTimeT = performance.now();
      const executionTimeT = endTimeT - startTime;
      console.log(
        `Transaction refund game execution time: ${executionTimeT.toFixed(
          2
        )} milliseconds`
      );
    } catch (error) {
      console.log(error);
      console.log(error.error?.rawMessage);
      if (error.response?.data?.error) {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");
        return setToastMessage(error.response?.data?.error);
      } else {
        handleDialogClose();
        setOpenToast(true);
        setToastStatus("failed");
        return setToastMessage(error.error?.rawMessage ?? error?.message);
      }
    }
  };

  // Function to poll the transaction status
  const pollTransactionStatus = async (
    requestId,
    maxAttempts = 40,
    interval = 1000
  ) => {
    if (!requestId) throw new Error("Transaction failed");

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const response = await magicGetRequestState(requestId);
      /*  console.log(
        `Attempt ${attempt + 1}: Transaction status -`,
        response?.data?.tx_receipt?.status
      );
      console.log(
        `Attempt ${attempt + 1}: Transaction status -`,
        response?.data
      );

      if (response?.data?.tx_receipt?.status === 1) {
        return response?.data?.tx_receipt;
      } else if (response?.data?.tx_receipt?.status === 0) {
        throw new Error("Transaction failed");
      }
        */

      console.log(
        `Attempt ${attempt + 1}: Transaction status -`,
        response?.data?.state
      );
      console.log(`Attempt ${attempt + 1}: Transaction status -`, response);
      if (response?.data?.state === "FAILED") {
        throw new Error("Transaction failed");
      } else if (response?.data?.state === "COMPLETED") {
        return response?.data?.tx_receipt;
      }

      // Wait for the specified interval before the next attempt
      await new Promise((resolve) => setTimeout(resolve, interval));
    }

    throw new Error("Transaction timed out");
  };

  return (
    <>
      <InvitationPopup
        open={isInvitationPopupOpen}
        setOpen={setIsInvitationPopupOpen}
        inviteRowData={inviteRowData}
      />

      <AlertPopup
        isSubmitting={isSubmitting}
        open={isDialogOpen}
        alertWidth={
          selectedGameInstance?.gamePaymentType === "Paid" &&
          selectedGameInstance?.status !== "canceled "
            ? "395px"
            : null
        }
        setOpen={setDialogOpen}
        title={
          selectedGameInstance?.status === "canceled "
            ? "Refund Game? "
            : "Cancel Game? "
        }
        content={
          <>
            <Typography textAlign={"center"}>
              {selectedGameInstance?.status === "canceled "
                ? "Are you sure you want to refund the game"
                : `Are you sure you want to cancel the game?`}
            </Typography>

            {/* Warning message about gas fees */}
            {selectedGameInstance?.gamePaymentType === "Paid" &&
              selectedGameInstance?.status !== "canceled " && (
                <Alert
                  severity="error"
                  variant="outlined"
                  // icon={false}
                  sx={{
                    color: "red",
                    marginTop: 2,
                    fontSize: 11,
                    textAlign: "center",
                  }}
                >
                  {"Gas fees have been deducted from your refund. "}
                </Alert>
              )}

            {/* <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Typography
                variant="body2"
                fontSize={10}
                sx={{
                  marginTop: 2,
                  fontWeight: "bold",
                  textAlign: "center", // Center the text
                  padding: 1, // Add padding inside the border
                  border: "1px solid red", // Add a red border
                  borderRadius: "4px", // Round the corners of the border
                  backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background for emphasis
                  color: "red", // Red text color to match the theme
                  maxWidth: "300px", // Limit the width of the message box
                  margin: "0 auto", // Center the message box itself
                }}
              >
                {"Gas fees have been deducted from your refund. " +
                  selectedGameInstance?.game?.entryFees}
              </Typography>
            </Box> */}
          </>
        }
        close={"Resume"}
        confirm={"Confirm"}
        handleSubmit={async () => {
          setIsSubmitting(true);
          if (selectedGameInstance?.status === "canceled ") {
            await handleRefundClick(gameInstanceId, selectedGameInstance);
          } else {
            await handleCancelClick(gameInstanceId, selectedGameInstance);
          }
          setIsSubmitting(false);
        }}
      />

      <Toast
        toast={toast}
        handleClose={handleToastClose}
        toastMsg={toastMessage}
        toastStatus={toastStatus}
      />

      {/*---------------------------------------New UI-----------------------------------  */}
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <MyGamesBanner />
      {/* </Suspense> */}
      <>
        <Card
          sx={{
            background: "#03364C",
            padding: 2,
            borderRadius: "18px",
            zIndex: 52,
            marginTop: 4,
            // overflow: "auto",
            // display: tab.id === "2" ? "none" : "block",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: isTab ? "start" : "center",
              flexDirection: isTab ? "column" : "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <CardHeader title={"Current Games"} />
            {/*  */}
            <Box
              sx={{
                background: "#042836",
                padding: 1,
                display: isMobile ? "none" : "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "100px",
                height: 38,
              }}
              gap={2}
            >
              {gameTabs.map((gameTab, index) => (
                <Button
                  key={index}
                  id={index}
                  disabled={currentTab === `${index}`}
                  onClick={handleTabClick}
                  sx={{
                    color: "white",
                    width: 150,
                    height: 28,
                    borderRadius: "19px",
                    "&.Mui-disabled": {
                      background:
                        "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)",
                      color: "white",
                    },
                    textTransform: "none",
                  }}
                >
                  {gameTab.tabTitle}
                </Button>
              ))}
            </Box>
            {/*  */}

            <TextField
              id="demo-select-small-label"
              select
              variant="outlined"
              defaultValue={gameType}
              sx={{
                "& fieldset": { border: "none", paddingY: 0, height: 40 },
                background:
                  "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)",
                borderRadius: "42px",
                width: 160,
                height: 40,
                paddingY: 0,
                // marginBottom: 5,
                border: 4,
                borderColor: "#042836",
                display: isMobile ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
              }}
              // defaultValue={"All Games"}
              // value={gameType}
              onChange={(e) => {
                setGameType(e.target.value);
              }}
            >
              {gameTabs.map((gameTab, index) => (
                <MenuItem key={index} value={gameTab.value}>
                  {gameTab.tabTitle}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <>
            {isLoading ? (
              <>
                <PageLoader />
              </>
            ) : (
              <>
                {!gameresultData || gameresultData.length === 0 ? (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingY: 10,
                        overflow: "auto",
                      }}
                    >
                      <Typography>There are no records to display.</Typography>
                    </Box>
                  </>
                ) : (
                  <Box>
                    <TableContainer sx={{ overflow: "scroll" }}>
                      <Table
                        sx={{
                          borderCollapse: "separate",
                          borderSpacing: "0px 20px",
                          width: "100%",
                        }}
                      >
                        <StyledTableHead>
                          {/* <TableRow> */}
                          <StyledTableCell>Status</StyledTableCell>
                          <StyledTableCell>My Team</StyledTableCell>
                          <StyledTableCell>Duration</StyledTableCell>
                          {/* <StyledTableCell>Winning</StyledTableCell> */}
                          <StyledTableCell>Prize</StyledTableCell>
                          <StyledTableCell>Leaderboard</StyledTableCell>
                          <StyledTableCell>My Place</StyledTableCell>

                          <StyledTableCell
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Game ID
                          </StyledTableCell>
                          <StyledTableCell>Total Players</StyledTableCell>
                          <StyledTableCell></StyledTableCell>

                          {/* </TableRow> */}
                        </StyledTableHead>
                        <TableBody>
                          {gameresultData.map((row, index) => (
                            <StyledTableRow
                              key={index}
                              sx={{
                                background: "#042836",
                              }}
                            >
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  borderBottomLeftRadius: "48px",
                                  borderTopLeftRadius: "48px",
                                }}
                              >
                                <GameResultStatus tab={row} />
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                }}
                              >
                                {row.status === "waiting" ? (
                                  <Box display={"flex"} gap={1}>
                                    {row.participant?.selectedStocks
                                      ?.slice(0, 4)
                                      .map((stock, index) => (
                                        <div key={index}>
                                          <CryptoTooltip
                                            disableFocusListener
                                            disableTouchListener
                                            title={stock?.stocks?.name}
                                            placement="top"
                                            slotProps={{
                                              popper: {
                                                sx: {
                                                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                    {
                                                      marginBottom: "5px",
                                                    },
                                                },
                                              },
                                            }}
                                          >
                                            <Avatar
                                              sx={{
                                                width: 26,
                                                height: 26,
                                                border: "1px solid white",
                                                padding: 0.3,
                                              }}
                                              src={stock.stocks?.image}
                                            />
                                          </CryptoTooltip>
                                        </div>
                                      ))}
                                    {row.participant?.selectedStocks
                                      ?.slice(4, 100)
                                      .map((stock, index) => (
                                        <div key={index}>
                                          <CryptoTooltip
                                            disableFocusListener
                                            disableTouchListener
                                            title={stock?.stocks?.name}
                                            placement="top"
                                            slotProps={{
                                              popper: {
                                                sx: {
                                                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                    {
                                                      marginBottom: "5px",
                                                    },
                                                },
                                              },
                                            }}
                                          >
                                            <Avatar
                                              // className="avatar"
                                              sx={{
                                                display: showFullStocks
                                                  ? "block"
                                                  : "none",
                                                width: 26,
                                                height: 26,
                                                border: "1px solid white",
                                                padding: 0.3,
                                              }}
                                              src={stock.stocks?.image}
                                            />
                                          </CryptoTooltip>
                                        </div>
                                      ))}
                                    {row.participant?.selectedStocks?.length >
                                      4 && (
                                      <Box
                                        // className="avatar"
                                        sx={{
                                          width: 26,
                                          height: 26,
                                          // marginLeft: 1,
                                          borderRadius: "50px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontWeight: 600,
                                          fontSize: 16,
                                          color: "white",
                                          "&:hover": {
                                            borderBottom: "1px solid #11B1F4",
                                            cursor: "pointer",
                                          },
                                          padding: 1,
                                          background:
                                            "linear-gradient(to right, #11B1F4 10%, #085A7D  100%, #085A7D 100%)",
                                        }}
                                        onClick={handleShowAllStocks}
                                      >
                                        {showFullStocks ? "-" : "+"}
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  <LiveStockScoreModal row={row} />
                                )}
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none" }}>
                                <GameDuration
                                  endTime={row.endTime}
                                  startTime={row.startTime}
                                />
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none" }}>
                                {row.game?.entryFees * row.game?.minPlayer -
                                  row.game?.entryFees *
                                    row.game?.minPlayer *
                                    0.2}{" "}
                                USDC
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  fontWeight: 800,
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                {row.status === "waiting" ? (
                                  <>------------------</>
                                ) : (
                                  <>
                                    <LeaderBoardStockModal
                                      gameInstanceId={row?._id}
                                      totalParticipants={row?.totalParticipants}
                                      slicedParticipants={
                                        row?.slicedParticipants
                                      }
                                      gameName={row?.game?.winnerType}
                                      gamePaymentType={
                                        row?.game?.gamePaymentType
                                      }
                                      status={row.status}
                                      endTime={row.endTime}
                                      participantId={row?.participant?._id}
                                    />

                                    {row.status === "started" ? (
                                      <Typography
                                        variant="caption"
                                        fontSize={8}
                                        color={"gold"}
                                        lineHeight={0}
                                      >
                                        Click to see live scores
                                      </Typography>
                                    ) : (
                                      <Typography
                                        variant="caption"
                                        fontSize={8}
                                        color={"gold"}
                                        lineHeight={0}
                                      >
                                        Click to see scores
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  fontWeight: 800,
                                }}
                              >
                                {row.status === "waiting" ? (
                                  <>------</>
                                ) : (
                                  <>
                                    {row?.ranking} out of {row.game?.maxPlayer}
                                  </>
                                )}
                              </TableCell>

                              <TableCell
                                sx={{ borderBottom: "none", fontWeight: 800 }}
                              >
                                {row._id
                                  .substring(row._id.length - 7)
                                  .toUpperCase()}
                              </TableCell>

                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  minWidth: "140px",
                                }}
                              >
                                <JoinedPlayersProgressBar
                                  participantsLength={row?.totalParticipants}
                                  maxPlayer={row.game?.maxPlayer}
                                  minPlayer={row.game?.minPlayer}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  borderBottomRightRadius: "48px",
                                  borderTopRightRadius: "48px",
                                  padding: 0,
                                  minHeight: "50px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <LightTooltip
                                    disableFocusListener
                                    disableTouchListener
                                    // followCursor
                                    title={
                                      row.status === "canceled "
                                        ? row?.participant?.refundClaimed ===
                                          true
                                          ? "Refund success"
                                          : ""
                                        : ""
                                    }
                                    placement="top"
                                    slotProps={{
                                      popper: {
                                        sx: {
                                          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                            {
                                              marginBottom: "5px",
                                            },
                                        },
                                      },
                                    }}
                                  >
                                    <span>
                                      <Button
                                        sx={{
                                          height: "48px",
                                          maxWidth: "48px",
                                          minWidth: "48px",
                                          borderRadius: "29px",
                                          background:
                                            "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)",
                                          textTransform: "none",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontWeight: 600,
                                          fontSize: "12px",
                                          color: "white",
                                          "&:disabled": {
                                            backgroundColor: "#085A7D",
                                          },
                                          "&:hover": {
                                            backgroundColor: " #10ADEF95",
                                          },
                                        }}
                                        disabled={
                                          row?.game?.gamePaymentType === "Paid"
                                            ? //Paid game
                                              row.status === "waiting"
                                              ? false
                                              : row.status === "canceled "
                                              ? row?.participant
                                                  ?.refundClaimed ?? false
                                              : true
                                            : //free game
                                            row.status === "waiting"
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          if (
                                            row?.game?.gamePaymentType ===
                                            "Paid"
                                          ) {
                                            handleDialogOpen();
                                            setGameInstanceId(row._id);
                                            setSelectedGameInstance(row);
                                          } else {
                                            console.log("It's Free Game");
                                            handleDialogOpen();
                                            setGameInstanceId(row._id);
                                            setSelectedGameInstance(row);
                                          }
                                        }}
                                      >
                                        {row?.game?.gamePaymentType === "Paid"
                                          ? row.status === "canceled "
                                            ? "Refund"
                                            : "Leave"
                                          : "Leave"}
                                      </Button>
                                    </span>
                                  </LightTooltip>
                                  <Button
                                    sx={{
                                      height: "48px",
                                      maxWidth: "48px",
                                      minWidth: "48px",
                                      borderRadius: "29px",
                                      // background:
                                      //   "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)",
                                      textTransform: "none",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      color: "white",
                                      // border:1,
                                      border: "2px solid",
                                      padding: 1,
                                      borderColor: "#11B1F4",
                                      marginLeft: 1,
                                      marginRight: 1,
                                      "&:disabled": {
                                        borderColor: "#085A7D",
                                      },
                                      "&:hover": {
                                        backgroundColor: " #10ADEF95",
                                      },
                                    }}
                                    onClick={() => {
                                      setInviteRowData(row);
                                      setIsInvitationPopupOpen(true);
                                    }}
                                    disabled={
                                      row.status === "waiting" ? false : true
                                    }
                                    // onClick={() => {
                                    //   handleDialogOpen();
                                    //   setGameInstanceId(row._id);
                                    // }}
                                    // disabled
                                  >
                                    Invite
                                  </Button>
                                </Box>
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                      />
                    </TableContainer>
                  </Box>
                )}
              </>
            )}
          </>
        </Card>
      </>
    </>
  );
};

export default StockGameResult;
