// import axios from "axios";
import { api } from "../Utils/axiosConfig";

const token = sessionStorage.getItem("token");
const getAuthHeaders = () => {
  const token = sessionStorage.getItem("token");

  if (token) {
    console.log("Access token available");
    return {
      headers: {
        Authorization: `${token}`,
      },
    };
  } else {
    // throw new Error("Access token not available");
    console.log("Access token not available");
  }
};

const configs = {
  BiggestWinner: "Biggest Winner",
  BiggestLoser: "Biggest Loser",
  HeadtoHead: "Head to Head",
  Multiplayer: "Multiplayer",
  waiting: "waiting",
};

//signup and login
export const checkEmail = (email) => api.get(`/users/getUserEmail/${email}`);
export const checkNumber = (phone) => api.get(`/users/getByNumber/${phone}`);
export const checkUserExists = () =>
  api.get(`/users/checkUserRegister/`, getAuthHeaders());
export const userSignup = async (data) =>
  await api.post("/users/create", data, getAuthHeaders());

export const updateMagicWalletAddress = async (data) =>
  await api.put("/users/updateCustomWalletAddress", data, getAuthHeaders());

export const updateFirebaseUid = async (data) =>
  await api.put("/users/updateFirebaseUid", data, getAuthHeaders());

//biggest winner
export const getBiggestWinnerMultiplayer = async ({
  limit,
  page,
  gameType,
  userId,
  gameAssetType = "CRYPTOS",
}) =>
  await api.get(
    `/game-instance/sorted?category=${configs.Multiplayer}&limit=${limit}&page=${page}&winnerType=${configs.BiggestWinner}&gameStatus=${configs.waiting}&gameType=${gameType}&userId=${userId}&gameAssetType=${gameAssetType}`,
    getAuthHeaders()
  );
export const getBiggestWinnerMultiplayerLandingPageData = async ({
  limit,
  page,
  gameType,
  gameAssetType = "CRYPTOS",
}) =>
  await api.get(
    `/game-instance/sorted?category=${configs.Multiplayer}&limit=${limit}&page=${page}&winnerType=${configs.BiggestWinner}&gameStatus=${configs.waiting}&gameType=${gameType}&gameAssetType=${gameAssetType}`,
    getAuthHeaders()
  );
export const getBiggestWinnerH2HInstances = async ({ limit, page }) =>
  await api.get(
    `/game/getGameByCategory?category=${configs.HeadtoHead}&limit=${limit}&page=${page}&winnerType=${configs.BiggestWinner}`,
    getAuthHeaders()
  );
export const getBiggestWinnerH2H = async ({ limit, page, userId }) =>
  await api.get(
    `/game-instance/get/invited/${userId}?category=${configs.HeadtoHead}&limit=${limit}&page=${page}&winnerType=${configs.BiggestWinner}`,
    getAuthHeaders()
  );

//biggest looser
export const getBiggestLooserMultiplayer = async ({
  limit,
  page,
  gameType,
  userId,
  gameAssetType = "CRYPTOS",
}) =>
  await api.get(
    `/game-instance/sorted?category=${configs.Multiplayer}&limit=${limit}&page=${page}&winnerType=${configs.BiggestLoser}&gameStatus=${configs.waiting}&gameType=${gameType}&userId=${userId}&gameAssetType=${gameAssetType}`,
    getAuthHeaders()
  );
export const getBiggestLooserMultiplayerLandingPageData = async ({
  limit,
  page,
  gameType,
  gameAssetType = "CRYPTOS",
}) =>
  await api.get(
    `/game-instance/sorted?category=${configs.Multiplayer}&limit=${limit}&page=${page}&winnerType=${configs.BiggestLoser}&gameStatus=${configs.waiting}&gameType=${gameType}&gameAssetType=${gameAssetType}`,
    getAuthHeaders()
  );
export const getBiggestLooserH2HInstances = async ({ limit, page }) =>
  await api.get(
    `/game/getGameByCategory?category=${configs.HeadtoHead}&limit=${limit}&page=${page}&winnerType=${configs.BiggestLoser}`,
    getAuthHeaders()
  );
export const getBiggestLooserH2H = async ({ limit, page, userId }) =>
  await api.get(
    `/game-instance/get/invited/${userId}?category=${configs.HeadtoHead}&limit=${limit}&page=${page}&winnerType=${configs.BiggestLoser}`,
    getAuthHeaders()
  );

//head to head instance creation
export const createParticipant = async (body) =>
  await api.post("/participant/create", body, getAuthHeaders());
export const createh2hInstance = async ({ gameId, body }) =>
  await api.post(
    `/game-instance/createHeadToHead/${gameId}`,
    body,
    getAuthHeaders()
  );
// export const getH2HUserData = async () =>
//   await api.get("/users/users?page=1&limit=9&searchTerm=", getAuthHeaders());

//result page
export const getContestResult = async ({
  userId,
  page,
  limit,
  gameType,
  gameAssetType,
}) =>
  await api.get(
    `/game-instance/getGameResult/${userId}?page=${page}&limit=${limit}&winnerType=${gameType}&gameAssetType=${gameAssetType}`,
    getAuthHeaders()
  );

// getJoinedGameCount
export const getJoinedGameCount = async ({ userId }) =>
  await api.get(
    `/game-instance/getJoinedGameCount/${userId}`,
    getAuthHeaders()
  );

export const createBlockchainTransaction = async (body) =>
  await api.post("/blockchainTransaction/create", body, getAuthHeaders());
export const getContestH2HResult = async (userId, page) =>
  await api.get(
    `/game-instance/getGameResult/${userId}?category=${configs.HeadtoHead}&page=${page}&limit=5`,

    getAuthHeaders()
  );
export const GetMultiplayerLeaderBoard = async ({
  gameInstanceId,
  page,
  limit,
}) =>
  await api.post(
    `/game-instance/leaderBoard/get/${gameInstanceId}?page=${page}&limit=${limit}`,
    {},
    getAuthHeaders()
  );

// export const getSinglePlayerLeaderBoard = async ({ gameInstanceId, userId }) =>
//   await api.post(
//     `/game-instance/leaderBoard/get/${gameInstanceId}?currentUserId=${userId}`,
//     {},
//     getAuthHeaders()
//   );

export const getSinglePlayerLeaderBoard = async ({
  gameInstanceId,
  userId,
  participantId,
}) =>
  await api.post(
    `/game-instance/user/getLiveData/${gameInstanceId}?currentUserId=${userId}&participantId=${participantId}`,
    {},
    getAuthHeaders()
  );

export const GetDetailedResult = async (body) =>
  await api.post("/participant/getParticipantCrypto", body, getAuthHeaders());

export const CancelGameInsance = async (userId, gameInstanceId, body) =>
  await api.post(
    `/game-instance/cancelGame/${userId}?gameInstanceId=${gameInstanceId}`,
    body,
    getAuthHeaders()
  );

export const checkTimeReached = async (body) =>
  await api.post(`/game-instance/timeReached`, body, getAuthHeaders());

//wallet
export const updateWalletAddress = async (data) =>
  await api.put("/users/updateWalletAddress", data);
export const getWalletActivity = ({ page, limit }) =>
  api.get(
    `/wallet-transaction/get?page=${page}&limit=${limit}`,
    getAuthHeaders()
  );
export const getWalletOverview = () =>
  api.get("/overview-balance/get", getAuthHeaders());
export const createTransaction = async (body) =>
  await api.post("/transaction/create", body, getAuthHeaders());
export const getTransaction = () =>
  api.get("/blockchianConfig/getAll", getAuthHeaders());

export const getUserIdData = (id) =>
  api.get(`/merkle-tree/userId/${id}`, getAuthHeaders());

//location
export const accessLocationAPI = async () => await api.get("/access-location");

//landing page data
export const getLandingPageData = async ({ page, limit }) =>
  await api.get(`/home-page/get?faqPage=${page}&faqLimit=${limit}`);

//user data
export const getTokenBalance = (id) =>
  api.get(`/users/getUserToken/${id}`, getAuthHeaders());
export const getUserById = (id) =>
  api.get(`/users/getById/${id}`, getAuthHeaders());

export const userUpdateApi = (id, data) =>
  api.put(`/users/update/${id}`, data, {
    headers: {
      Authorization: `${token}`,
      "content-type": "multipart/form-data",
    },
  });

export const getUserByUsername = (username) =>
  api.get(`/users/username/${username}`, getAuthHeaders());

export const updateUserBalance = (data) =>
  api.put(`/users/updateUserBalance`, data, getAuthHeaders());

// notification
export const getAllNotifications = (id, page, limit) =>
  api.get(`/notification/getAll/${id}?page=${page}&limit=10`, getAuthHeaders());
export const deleteNotifications = (id) =>
  api.delete(`/notification/delete/${id}`, getAuthHeaders());

export const markAsRead = (id) =>
  api.post(`/notification/markAsRead/${id}`, {}, getAuthHeaders());

export const getNotificationCount = (id) =>
  api.get(`/notification/count/${id}`, getAuthHeaders());

// config
export const getBlockChianConfig = async () =>
  await api.get("/blockChainConfig/getAll", getAuthHeaders());

export const createSubscription = async (body) =>
  await api.post("/home-page-email/create", body);

// search cryptos
export const searchCryptos = async ({ id, page, limit, term }) =>
  await api.get(
    `/crypto/getAll/${id}?page=${page}&limit=${limit}&title=${term}`,
    getAuthHeaders()
  );
export const addUserSelectedCryptos = async (body) =>
  await api.post(`/user-searched-cryptos/create`, body, getAuthHeaders());

export const removeUserSelectedCryptos = async (body) =>
  await api.post(`/user-searched-cryptos/remove`, body, getAuthHeaders());

export const getUserSelectedCryptos = async (id) =>
  await api.get(`/user-searched-cryptos/getAll/${id}`, getAuthHeaders());

export const getGameConfig = async () => await api.get("/game-config/get/user");

export const getCryptoUpdate = async () =>
  await api.get("/crypto/sortedCryptos", getAuthHeaders());

export const getUsersWithProfile = async () =>
  await api.get("/users/getUserWithProfile");

export const getTwoRecentWinners = async () =>
  await api.get("/game-instance/winnersUpdate", getAuthHeaders());

export const getWinAndLossChartData = async ({ userId, isWins, seriesData }) =>
  await api.get(
    `/wallet-transaction/winsAndLoss/${userId}?isWins=${isWins}&dateFilter=${seriesData}`,
    getAuthHeaders()
  );

export const getAllFaqs = async () => await api.get(`/faq/getAll`);

// Rules
export const getBiggestWinnerRules = async () =>
  await api.get(
    "/game-rules/getGameRules?winnerType=Biggest Winner&gameCategory=Multiplayer",
    getAuthHeaders()
  );
export const getBiggestLoserRules = async () =>
  await api.get(
    "/game-rules/getGameRules?winnerType=Biggest Loser&gameCategory=Multiplayer",
    getAuthHeaders()
  );

// Login metamask
export const createCustomToken = async (id) =>
  await api.post(`/users/createCustomToken/${id}`, {});

export const checkWalletAddress = async (id) =>
  await api.get(`/users/checkIsWalletExist/${id}`);

// today winners
export const getTodayWinners = async (gameType) =>
  await api.get(
    `/game-instance/getTodaysWinners?winnerType=${gameType}`,
    getAuthHeaders()
  );

// home page leader board
export const getHomePageLeaderBordData = async ({
  dateFilter,
  winnerType,
  page,
  limit,
}) =>
  await api.get(
    `/game-instance/getDashboardLeaderBoard?dateFilter=${dateFilter}&winnerType=${winnerType}&page=${page}&limit=${limit}`,
    getAuthHeaders()
  );

export const getHomeLeaderBoardUserGameById = async ({
  id,
  gameType,
  dateFilter,
  page,
  limit,
}) =>
  await api.get(
    `/game-instance/getGameByUserId/${id}?gameType=${gameType}&dateFilter=${dateFilter}&page=${page}&limit=${limit}`,
    getAuthHeaders()
  );

// top cryptos
export const topCryptos = async () =>
  await api.get("/crypto/sortedCryptos?topCryptos=true");

export const topStocks = async () =>
  await api.get("/stock/sortedStocks?topStock=true");

// export const coinbaseApi = async () =>
//   await axios.get("https://api.coinbase.com/api/v3/brokerage/products", {
//     headers: {
//       "Content-Type": "application/json",
//     },
//     redirect: "follow",
//   });

// referral config
export const referralConfig = async () =>
  await api.get("/configData/get", getAuthHeaders());

//Ambassador Referral Program
export const ambassadorReferral = async (id) =>
  await api.get(`/ambassador/get/${id}`, getAuthHeaders());

// game invite

export const inviteUserToGame = async (body) =>
  await api.post("/game-invite/create", body, getAuthHeaders());

export const getInvitaionConfirm = async (id, gameInstanceId) =>
  await api.get(`/game-invite/${id}/${gameInstanceId}`, getAuthHeaders());

export const getAllInvitaions = async (id) =>
  await api.get(`/game-invite/invitations/${id}`, getAuthHeaders());

export const deleteInvitation = async (id) =>
  await api.delete(`/game-invite/invitation/${id}`, getAuthHeaders());

export const getAllCampaingData = async (sortBy, page, limit) =>
  await api.get(
    `/home-page/get/campaign?sortBy=${sortBy}&pageNumber=${page}&pageSize=${limit}`
  );

//Not Using
export const updateReferralData = async (body) =>
  await api.post("/referral-program/refferels-count", body, getAuthHeaders());

//MAGIC TRANSACTION API

export const magicGetRequestState = async (requestId) =>
  await api.get(
    `https://gas-api.magic.link/v1/relayer/get-request-state?request_id=${requestId}`
  );

//BlockChainTransaction
export const transactionGasFee = async (body) =>
  await api.post(
    "/blockchainTransaction/transferGasFee",
    body,
    getAuthHeaders()
  );

export const requestToken = (data) =>
  api.post(`/blockchainTransaction/requestToken`, data, getAuthHeaders());

//Paymaster ZyFi =>
export const zyFiPaymaster = async (body) =>
  await api.post("https://api.zyfi.org/api/erc20_paymaster/v1", body, {
    "Content-Type": "application/json",
  });

//Stickers
export const getAllStickersWithPaginated = async (page, limit) =>
  await api.get(
    `/stickers/getAll/paginated?page=${page}&limit=${limit}`,
    getAuthHeaders()
  );
