import { Alert, IconButton, Snackbar, Stack } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const Toast = ({
    toast,
    handleClose: handleToastClose,
    toastMsg: toastMessage,
    toastStatus
  }) => {
    const isFailed = toastStatus==="failed"?true:false
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleToastClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    return (
      <Stack>
        <Snackbar
          open={toast}
          autoHideDuration={6000}
          onClose={handleToastClose}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={toastStatus==="failed"?"error":"success"} sx={{ background:isFailed?"red":"green", color: "white" }}>
            {toastMessage}
          </Alert>
        </Snackbar>
      </Stack>
    );
}

export default Toast