import { Box, Button } from "@mui/material";
import React from "react";

// const LandingButton = ({ text, onClick }) => {
//   return (
//     <>
//       <Button
//         sx={{
//           paddingY: {
//             xs: "6px",
//             sm: "10px",
//             md: "10px",
//           },
//           paddingX: {
//             xs: "12px",
//             sm: "15px",
//             md: "25px",
//           },
//           color: "white",
//           fontWeight: 500,
//           fontSize: {
//             xs: 6,
//             sm: 10,
//             md: 14,
//           },
//           textTransform: "none",
//           fontFamily: "Space Grotesk",
//           alignSelf: "start",
//           borderRadius: "64px",
//           background:
//             "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
//           // marginTop: "30px",
//           "&:hover": {
//             backgroundColor: " #10ADEF95",
//           },
//         }}
//         variant="contained"
//         onClick={onClick}
//       >
//         {text}
//       </Button>
//     </>
//   );
// };

const LandingButton = ({ text, onClick }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        paddingY: "clamp(2px, 1.5vw, 13px)",
        paddingX: "clamp(8px, 1.6vw, 22px)",
        fontSize: "clamp(3px, 1.2vw, 14px)",
        color: "white",
        fontFamily: "Space Grotesk",
        textTransform: "none",
        background:
          "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
        "&:hover": { backgroundColor: "#10ADEF95" },
        borderRadius: "clamp(32px, 6vw, 64px)",
      }}
      variant="contained"
      onClick={onClick}
    >
      {text}
    </Box>
  );
};

export default LandingButton;
