import { useTheme } from "@emotion/react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  styled,
  Badge,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { GetMultiplayerLeaderBoard } from "../../Services/Service";
import { useQuery } from "react-query";
import PageLoader from "../../Components/Loaders/PageLoader";
import LiveScoreCounter from "../../Components/GameResult/LiveScoreCounter";
import { commonData } from "../../constants/common";
import StockScoreModal from "../../Components/Game/StockScoreModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#03364C",
  fontSize: "16px",
  fontWeight: 500,
  borderBottom: "none",
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "#03364C",
}));
const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: 700, lg: 700 },
  bgcolor: "#03364C",
  borderRadius: "35px",
  //   border: "2px solid #000",
  padding: 2,
  // paddingTop: 4,
  //   boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: "100%", sm: "80%", md: 600, lg: 600 },
  // height: { sm: 350, md: 600, lg: 600 },
  // overflowY: "scroll",
};

const LeaderBoardStockModal = ({
  gameInstanceId,
  totalParticipants,
  slicedParticipants,
  gameName,
  gamePaymentType,
  status,
  endTime,
  participantId,
}) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [gameId, setGameId] = useState("");

  const userId = sessionStorage.getItem("userId");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpen = async (id) => {
    setOpen(true);
    setGameId(id);
  };
  const handleClose = () => setOpen(false);

  const { data, isLoading } = useQuery(
    ["multiplayerleaderboard", page, limit, gameId],
    async () => {
      try {
        if (gameId) {
          const leaderboard = await GetMultiplayerLeaderBoard({
            gameInstanceId: gameId,
            page: page + 1,
            limit,
          });
          console.log("LB CALL");
          setTotal(leaderboard?.data?.data?.total);
          return leaderboard?.data?.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    {
      staleTime: 5000,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  console.log(data, "LBD");

  return (
    <div>
      {/* <Tooltip 
        title={"Click to see live scores"}
        placement="top"
        arrow
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: "5px",
                },
            },
          },
        }}
      >*/}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          cursor: "pointer",
        }}
        onClick={() => handleOpen(gameInstanceId)}
      >
        <AvatarGroup
          total={totalParticipants}
          sx={{
            "& .MuiAvatar-root": {
              width: 28,
              height: 28,
              fontSize: 10,
              color: "white",
              background:
                "linear-gradient(to right, #11B1F4 10%, #085A7D  100%, #085A7D 100%)",
            },
          }}
        >
          {slicedParticipants?.map((user) => (
            <Avatar
              key={user._id}
              sx={{ width: 28, height: 28 }}
              alt="Remy Sharp"
              src={user?.userId?.profilePic}
            />
          ))}
        </AvatarGroup>
      </Box>
      {/* </Tooltip> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: !isMobile && "space-between",
              gap: 2,
              marginX: 2,
              paddingTop: 2,
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Typography variant="body2" fontWeight={500} fontSize={24}>
                Leaderboard
              </Typography>
              <Box
                sx={{
                  height: 25,
                  width: 140,
                  paddingX: 1,
                  borderRadius: "20px",
                  background:
                    "linear-gradient(to right, #11B1F4 10%, #085A7D  100%, #085A7D 100%)",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {gameName}
              </Box>
            </div>

            <LiveScoreCounter endTime={new Date(endTime)} status={status} />

            <IconButton
              sx={{
                position: "absolute",
                top: -10,
                right: -25,
                "&:hover": {
                  color: "#11B1F4",
                  backgroundColor: "transparent",
                },
                display: isMobile ? "block" : "none",
              }}
              aria-label="delete"
              size="medium"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {isLoading ? (
            <>
              <PageLoader />
            </>
          ) : (
            <>
              <TableContainer>
                <Table
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 20px",
                    width: "100%",
                  }}
                >
                  <StyledTableHead>
                    <StyledTableCell>Rank</StyledTableCell>
                    <StyledTableCell>Player</StyledTableCell>
                    <StyledTableCell>Team</StyledTableCell>
                    <StyledTableCell>Live Score</StyledTableCell>
                    <StyledTableCell>Winning</StyledTableCell>
                  </StyledTableHead>

                  {data?.participants
                    ?.sort((a, b) => a.position - b.position)
                    .map((participant) => (
                      <TableBody key={participant._id}>
                        <TableRow
                          sx={{
                            background:
                              participant?._id === participantId
                                ? "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)"
                                : "#042836",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              borderBottomLeftRadius: "48px",
                              borderTopLeftRadius: "48px",
                              fontSize: 26,
                              fontWeight: 500,
                              paddingLeft: 4,
                            }}
                          >
                            {participant?.position}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            <Box display={"flex"}>
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <></>
                                  // <Avatar
                                  //   sx={{
                                  //     height: 18,
                                  //     width: 18,
                                  //   }}
                                  //   alt="Remy Sharp"
                                  //   src="/Images/badge2.png"
                                  // />
                                }
                              >
                                <Avatar
                                  sx={{ width: 45, height: 45 }}
                                  alt="Travis Howard"
                                  src={participant?.userId?.profilePic}
                                />
                              </Badge>
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                // position={"relative"}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight={500}
                                  fontSize={18}
                                  marginX={1}
                                >
                                  {participant?.userId?.username}
                                </Typography>

                                {/* <Typography
                                variant="caption"
                                fontWeight={500}
                                fontSize={12}
                                color={"#FFD54B"}
                                marginX={1}
                              >
                                Level 2
                              </Typography> */}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            <StockScoreModal
                              row={participant}
                              userId={participant?.userId?._id}
                              status={status}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: "none", color: "white" }}
                          >
                            <span
                              style={{
                                color: "white",
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              {" "}
                              {participant?.averagePercentage === null ||
                              participant?.averagePercentage === undefined ? (
                                <span
                                  style={{ fontWeight: 500, marginLeft: 10 }}
                                >
                                  -------
                                </span>
                              ) : (
                                `${participant?.averagePercentage?.toFixed(5)}%`
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              borderBottomRightRadius: "48px",
                              borderTopRightRadius: "48px",
                              color: "#00FF66",
                              fontWeight: 500,
                              fontSize: 16,
                            }}
                          >
                            {gamePaymentType === "Free" ? (
                              <span style={{ color: "white" }}>-------</span>
                            ) : (
                              `${parseFloat(
                                participant?.prizeAmount?.toFixed(5)
                              )} USDC`
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
              <Box
                sx={{
                  height: {
                    xs: 100,
                    md: 80,
                  },
                  overflow: "hidden", // Prevent scrolling
                  whiteSpace: "nowrap", // Prevent text wrapping that might cause overflow
                  textOverflow: "ellipsis", // Optional: Add ellipsis if content overflows
                  display: "flex", // Optional: For better content alignment
                  alignItems: "center", // Optional: Center content vertically
                  justifyContent: "end", 
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={total}
                  rowsPerPage={limit}
                  // sx={{
                  //   height: {
                  //     xs: 100,
                  //     md: 80,
                  //   },
                  // }}
                  // sx={{
                  //   "& .MuiSelect-select": {
                  //     display: "none !important",
                  //   },
                  //   "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                  //     display: "none !important",
                  //   },
                  // }}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  labelRowsPerPage={isMobile ? "" : `Rows per page:`}
                />
              </Box>
            </>
          )}

          {/* </div> */}
        </Box>
      </Modal>
    </div>
  );
};

export default LeaderBoardStockModal;
