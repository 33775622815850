import { useTheme } from "@emotion/react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
// Import Swiper React components
import { motion } from "framer-motion";
// Import Swiper styles
import "swiper/css";

import { DrawerContext } from "../../context/DrawerContext";
import { useNavigate } from "react-router-dom";

import "./landing.css";
import { landingGameList } from "../../Config";

export const GameSliderHomePage = () => {
  const theme = useTheme();
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const imgRef = useRef(null);

  const navigate = useNavigate();

  const { landingIsCollapsed, setLandingIsCollapsed } =
    useContext(DrawerContext);

  const gameSliderImage = [
    "/Images/landingPage/slider1.png",
    "/Images/landingPage/slider2.png",
    "/Images/landingPage/slider3.png",
  ];

  const [bannerImage, serBannerImage] = React.useState(
    // gameSliderImage[sliderIndex]
    landingGameList[sliderIndex]
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  //===========

  const scrollRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // const handleMouseDown = (e) => {
  //   e.preventDefault();
  //   setIsMouseDown(true);
  //   setStartX(e.pageX - scrollRef.current.offsetLeft);
  //   setScrollLeft(scrollRef.current.scrollLeft);
  //   scrollRef.current.style.cursor = "grabbing";
  // };

  // const handleMouseLeave = () => {
  //   setIsMouseDown(false);
  //   scrollRef.current.style.cursor = "grab";
  // };

  // const handleMouseUp = () => {
  //   setIsMouseDown(false);
  //   scrollRef.current.style.cursor = "grab";
  // };

  // const handleMouseMove = (e) => {
  //   if (!isMouseDown) return;
  //   e.preventDefault();
  //   const x = e.pageX - scrollRef.current.offsetLeft;
  //   const walk = (x - startX) * 2;
  //   scrollRef.current.scrollLeft = scrollLeft - walk;
  // };

  //=============

  // Preload all images to improve switch performance
  useEffect(() => {
    landingGameList.forEach((e) => {
      const img = new Image();
      img.src = e.banner;
    });
  }, [landingGameList]);

  const handleImageLoad = () => {
    setIsLoaded(true); // Set the state when the image is fully loaded
  };

  useEffect(() => {}, [bannerImage]);

  const switchImage = (index, e) => {
    setIsImageLoading(true);
    // setIsLoaded(false); // Reset loading state
    setSliderIndex(index);
    serBannerImage(e);
    // console.log(sliderIndex, index, e);

    // setIsImageLoading(false);
    // Delay the image switch
    setTimeout(() => {
      setIsImageLoading(false); // End loading state after the delay
    }, 500); // Adjust delay time in milliseconds (500ms = 0.5 seconds)
  };

  // =========
  const settings = {
    // className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "70px",
    // speed: 500,
  };

  const blurEffect = (isBlur) => (isBlur ? "blur(1.5px)" : `blur(0)`);

  return (
    <>
      {!isSmall ? (
        <Box
          sx={{
            my: 2,
            mx: 1,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gap: "2rem",
              gridTemplateColumns: {
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              },
            }}
          >
            {landingGameList.map((e, index) =>
              index === 5 ? (
                <Box
                  sx={{
                    height: {
                      sm: "6.9rem",
                      md: "6.8rem",
                      lg: "7rem",
                    },
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 10,
                      // objectFit: "cover",
                    }}
                    src={e?.location}
                    alt="game"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    boxShadow: "0 1px 5px gray",
                    borderRadius: 6,
                    background:
                      sliderIndex === index
                        ? "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                        : null,
                  }}
                >
                  <Button
                    key={index}
                    sx={{
                      filter: blurEffect(index == 2),
                      flexDirection: "row",

                      padding: isSmall ? 1.5 : isTablet ? 2 : 2,
                      // boxShadow: "0 1px 5px gray",
                      textTransform: "none",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                    onClick={() => switchImage(index, e)}
                  >
                    <img
                      style={{
                        width: isSmall ? "70px" : isTablet ? "80px" : "100px",
                        height: isSmall ? "70px" : "80px",
                        borderRadius: 10,
                        // objectFit: "cover",
                      }}
                      src={e?.location}
                      alt="game"
                      // loading="lazy"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: isSmall ? 1.5 : isTablet ? 2.5 : 2,
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        // fontSize={isSmall ? 12 : { xs: 12, sm: 16 }}
                        // fontSize={isSmall ? "0.8rem" : "1rem"}
                        fontSize={{
                          xs: 11,
                          sm: 14,
                        }}
                        sx={{
                          pb: 0.5,
                        }}
                        // lineHeight={2}
                        color={"white"}
                        textAlign="left" // Align text to the left
                      >
                        {e.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        // fontSize={isSmall ? 9 : { xs: 10, sm: 14 }}
                        // fontSize={isSmall ? "0.6rem" : "0.8rem"}
                        fontSize={{
                          xs: 9,
                          sm: 12,
                        }}
                        color={"white"}
                        textAlign="left"
                      >
                        {e.label}
                      </Typography>
                    </Box>
                  </Button>

                  {index === 2 && (
                    <Typography
                      position={"absolute"}
                      color="white"
                      variant="h5"
                      sx={{
                        textAlign: "center",
                        textWrap: "nowrap",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontWeight: "bold",
                      }}
                    >
                      Coming Soon
                    </Typography>
                  )}
                </Box>
              )
            )}
          </Box>

          {BigGameBanner(
            bannerImage,
            isImageLoading,
            isLoaded,
            handleImageLoad,
            navigate
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            // display: "flex",
            // flexDirection: "column",
            // marginLeft: 1,
            // marginRight: 1,
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
            marginBottom: 6,
          }}
          // position="relative"
        >
          {/* <ImageSwitcher images={gameSliderImage} /> */}
          {/* <div
            style={{
              position: "relative",
            }}
          > */}
          <Box
            // position="absolute"
            className="invisible-scrollbar"
            sx={{
              display: "flex",
              // pl: 4,
              // pr: 2,
              overflowX: "auto",
              scrollBehavior: "smooth",
              gap: "2rem",
              mb: "1.5rem",
              width: "100%",
              zIndex: 10,
            }}
          >
            {landingGameList.map((e, index) =>
              index === 5 ? (
                <Box
                  sx={{
                    width: {
                      xs: "15rem",
                      sm: "20rem",
                      md: "22rem",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "15rem",
                      height: "6rem",
                      borderRadius: 10,
                      // objectFit: "cover",
                    }}
                    src={e?.location}
                    alt="game"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    boxShadow: "0 1px 5px gray",
                    borderRadius: 3,
                    background:
                      sliderIndex === index
                        ? "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                        : null,
                    width: {
                      xs: "15rem",
                      sm: "20rem",
                      md: "22rem",
                    },
                    height: {
                      xs: "6rem",
                    },
                    marginY: 2,
                  }}
                >
                  <Button
                    key={index}
                    sx={{
                      width: {
                        xs: "15rem",
                        sm: "20rem",
                        md: "22rem",
                      },
                      filter: blurEffect(index == 2),
                      // maxHeight: 150,
                      flexShrink: 0,
                      // height: isSmall
                      //   ? "100px !important"
                      //   : isTablet
                      //   ? "140px !important"
                      //   : "100px !important",
                      // display: "grid",
                      flexDirection: "row",
                      padding: isSmall ? 1.5 : isTablet ? 2 : 2,
                      textTransform: "none",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                    onClick={() => switchImage(index, e)}
                  >
                    <img
                      style={{
                        width: isSmall ? "70px" : isTablet ? "80px" : "100px",
                        height: isSmall ? "70px" : "80px",
                        borderRadius: 10,
                        // objectFit: "cover",
                      }}
                      src={e?.location}
                      alt="game"
                      // loading="lazy"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: isSmall ? 1.5 : isTablet ? 2.5 : 2,
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        // fontSize={isSmall ? 12 : { xs: 12, sm: 16 }}
                        // fontSize={isSmall ? "0.8rem" : "1rem"}
                        fontSize={{
                          xs: 11,
                          sm: 14,
                        }}
                        sx={{
                          pb: 0.5,
                        }}
                        // lineHeight={2}
                        color={"white"}
                        textAlign="left" // Align text to the left
                      >
                        {e.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        // fontSize={isSmall ? 9 : { xs: 10, sm: 14 }}
                        // fontSize={isSmall ? "0.6rem" : "0.8rem"}
                        fontSize={{
                          xs: 9,
                          sm: 12,
                        }}
                        color={"white"}
                        textAlign="left"
                      >
                        {e.label}
                      </Typography>
                    </Box>
                  </Button>

                  {index === 2 && (
                    <Typography
                      position={"absolute"}
                      color="white"
                      variant="h5"
                      sx={{
                        textAlign: "center",
                        textWrap: "nowrap",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontWeight: "bold",
                      }}
                    >
                      Coming Soon
                    </Typography>
                  )}
                </Box>
              )
            )}
          </Box>
          {/* </div> */}

          {BigGameBanner(
            bannerImage,
            isImageLoading,
            isLoaded,
            handleImageLoad,
            navigate
          )}
        </Box>
      )}
    </>
  );
};

const BigGameBanner = (
  bannerImage,
  isImageLoading,
  isLoaded,
  handleImageLoad,
  navigate
) => {
  return (
    <Box
      sx={{
        mt: {
          xs: 3,
          md: 10,
        },
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: bannerImage?.link ? "pointer" : "inherit",
      }}
      onClick={() => {
        if (bannerImage?.link) navigate(bannerImage?.link);
      }}
    >
      <motion.img
        initial={{ opacity: 0, scale: 0.9 }} // Initial state
        animate={{
          opacity: isImageLoading ? 0 : 1,
          scale: isImageLoading ? 0.97 : 1,
        }} // Animation states
        transition={{
          opacity: { duration: 0.5, ease: "easeInOut" },
          scale: { duration: 0.5, ease: "easeInOut" },
        }} // Animation duration and easing,
        style={{
          width: "80%", // Increase size to make it larger
          height: "55%", // Adjust the height as well
          position: "relative", // Position relative to its normal position
          zIndex: 1, // Bring it above other content
          //   top: "-30px", // Move the image upwards
          //   left: "30px", // Adjust as needed to align properly
          //   marginRight: 2,
          //   marginRight: 2,
          // transform: "rotateY(1deg) rotateZ(-5deg) skewX(-5deg)",
          transformOrigin: "center",
          objectFit: "cover",
          // transition: "opacity 0.5s ease-in-out",
          opacity: isLoaded ? 1 : 0,
        }}
        src={bannerImage?.banner}
        alt="game"
        loading="eager"
        onLoad={handleImageLoad}
      />
    </Box>
  );
};

export default GameSliderHomePage;

// import { useTheme } from "@emotion/react";
// import { Box, Button, Typography, useMediaQuery } from "@mui/material";
// import React, { useContext, useEffect, useRef, useState } from "react";
// import cryptoParlayBigSvg from "../../asset/crypto_parlay.svg";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";

// // import required modules
// import { Pagination } from "swiper/modules";
// // import ImageSwitcher from "./ImageSwiter";
// import { DrawerContext } from "../../context/DrawerContext";
// import { useNavigate } from "react-router-dom";

// // import Slider from "react-slick";
// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";

// import "./landing.css";

// const landingGameList = [
//   {
//     location: "/Images/landingPage/slider-sb1.png",
//     title: "Biggest winner",
//     label:
//       "Pick several crypto that you believe will go up during the contest duration to win BIG",
//     banner: "/Images/landingPage/slider1.png",
//     link: "/landing/biggestwinner",
//   },
//   {
//     location: "/Images/landingPage/slider-sb2.png",
//     title: "Biggest Loser",
//     label:
//       "Pick several crypto that you believe will go down during the contest duration and win BIG",
//     banner: "/Images/landingPage/slider2.png",
//     link: "/landing/biggestloser",
//   },
//   {
//     location: "/Images/landingPage/slider-sb3.png",
//     title: "Crypto Parlay",
//     label:
//       "Pick multiple crypto tokens to either go UP or DOWN. Get each section correct and win BIG!!",
//     // banner: "/Images/landingPage/slider3.png",
//     banner: cryptoParlayBigSvg,
//   },
//   {
//     location: "/Images/landingPage/BiggestWinnerStock-sb.png",
//     title: "Biggest winner stocks",
//     label:
//       "Pick the stocks you believe will that will go UP the highest percentage within a 5 minute contest",
//     banner: "/Images/landingPage/stock.png",
//   },
//   {
//     location: "/Images/landingPage/BiggestLoserStock-sb.png",
//     title: "Biggest loser stocks",
//     label:
//       "Pick the stocks you believe will that will go DOWN the most percentage within a 5 minute contest",
//     banner: "/Images/landingPage/stock.png",
//   },

//   // {
//   //   location: "/Images/landingPage/slider-sb4.png",
//   //   title: "Sports Betting",
//   //   label: "",
//   //   banner: "/Images/landingPage/slider4.png",
//   // },
//   // {
//   //   location: "/Images/landingPage/slider-sb5.png",
//   //   title: "Casino",
//   //   label: "",
//   //   banner: "/Images/landingPage/slider5.png",
//   // },
// ];

// export const GameSliderHomePage = () => {
//   const theme = useTheme();
//   const [sliderIndex, setSliderIndex] = React.useState(0);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const imgRef = useRef(null);

//   const navigate = useNavigate();

//   const { landingIsCollapsed, setLandingIsCollapsed } =
//     useContext(DrawerContext);

//   const gameSliderImage = [
//     "/Images/landingPage/slider1.png",
//     "/Images/landingPage/slider2.png",
//     "/Images/landingPage/slider3.png",
//   ];

//   const [bannerImage, serBannerImage] = React.useState(
//     // gameSliderImage[sliderIndex]
//     landingGameList[sliderIndex]
//   );

//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
//   const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
//   const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

//   //===========

//   const scrollRef = useRef(null);
//   const [isMouseDown, setIsMouseDown] = useState(false);
//   const [startX, setStartX] = useState(0);
//   const [scrollLeft, setScrollLeft] = useState(0);

//   const handleMouseDown = (e) => {
//     e.preventDefault();
//     setIsMouseDown(true);
//     setStartX(e.pageX - scrollRef.current.offsetLeft);
//     setScrollLeft(scrollRef.current.scrollLeft);
//     scrollRef.current.style.cursor = "grabbing";
//   };

//   const handleMouseLeave = () => {
//     setIsMouseDown(false);
//     scrollRef.current.style.cursor = "grab";
//   };

//   const handleMouseUp = () => {
//     setIsMouseDown(false);
//     scrollRef.current.style.cursor = "grab";
//   };

//   const handleMouseMove = (e) => {
//     if (!isMouseDown) return;
//     e.preventDefault();
//     const x = e.pageX - scrollRef.current.offsetLeft;
//     const walk = (x - startX) * 2;
//     scrollRef.current.scrollLeft = scrollLeft - walk;
//   };

//   //=============

//   // Preload all images to improve switch performance
//   useEffect(() => {
//     landingGameList.forEach((e) => {
//       const img = new Image();
//       img.src = e.banner;
//     });
//   }, [landingGameList]);

//   const handleImageLoad = () => {
//     setIsLoaded(true); // Set the state when the image is fully loaded
//   };

//   useEffect(() => {}, [bannerImage]);

//   const switchImage = (index, e) => {
//     // setIsLoaded(false); // Reset loading state
//     setSliderIndex(index);
//     serBannerImage(e);
//     console.log(sliderIndex, index, e);
//   };

//   // =========
//   const settings = {
//     // className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "70px",
//     // speed: 500,
//   };

//   return (
//     <>
//       {!isTablet ? (
//         <Box
//           sx={{
//             width: "100%",
//             // overflow: "hidden",
//             // display: "flex",
//             // flexDirection: "column",
//             marginLeft: 1,
//             marginRight: 1,
//             // alignItems: "center",
//             // justifyContent: "center",
//             marginBottom: 6,
//           }}
//         >
//           {/* <Paper style={{ overflowX: "auto", background: "transparent" , display: "flex"}}> */}
//           {/* <Box
//             // className="invisible-scrollbar"
//             // sx={{
//             //   display: "flex",
//             //   // pl: 4,
//             //   // pr: 4,
//             //   overflowX: "auto",
//             //   scrollBehavior: "smooth",
//             //   gap: "2rem",
//             //   justifyContent: "flex-start",
//             //   alignItems: "center",
//             //   cursor: "grab",
//             // }}

//             ref={scrollRef}
//             className="invisible-scrollbar"
//             sx={{
//               display: "flex",
//               overflowX: "auto",
//               scrollBehavior: "smooth",
//               gap: "2rem",
//               justifyContent: "flex-start",
//               alignItems: "center",
//               // cursor: "grab",
//               width: "100vw",
//               // padding: 2,
//               WebkitOverflowScrolling: "touch",
//               "&::-webkit-scrollbar": {
//                 display: "none",
//               },
//               msOverflowStyle: "none",
//               scrollbarWidth: "none",
//               userSelect: "none",
//               mb: 2,
//               // mr: 200,
//             }}
//             onMouseDown={handleMouseDown}
//             onMouseLeave={handleMouseLeave}
//             onMouseUp={handleMouseUp}
//             onMouseMove={handleMouseMove}
//           > */}
//           <Swiper
//             // slidesPerView={4}
//             // spaceBetween={30}
//             // autoplay={{
//             //   delay: 10000,
//             // }}
//             slidesPerView={3}
//             // spaceBetween={10}
//             // pagination={{
//             //   clickable: true,
//             // }}
//             modules={[Pagination]}
//             // className="mySwiper"
//             key={landingIsCollapsed ? "collapsed" : "expanded"}
//             style={{
//               marginBottom: 20,
//               // overflow: "scroll",
//               overflow: "hidden",
//               position: "relative",
//               zIndex: 10,
//               width: "100%",
//             }}
//             className="invisible-scrollbar"
//             breakpoints={{
//               200: {
//                 slidesPerView: 1,
//                 spaceBetween: 10,
//               },
//               576: {
//                 slidesPerView: 2,
//                 spaceBetween: 10,
//               },
//               768: {
//                 slidesPerView: landingIsCollapsed ? 3 : 2,
//                 spaceBetween: 10,
//               },
//               992: {
//                 slidesPerView: landingIsCollapsed ? 3 : 2,
//                 spaceBetween: landingIsCollapsed ? 10 : 10,
//               },
//               1100: {
//                 slidesPerView: landingIsCollapsed ? 4 : 3,
//                 spaceBetween: landingIsCollapsed ? 30 : 20,
//               },
//             }}
//           >
//             {/* <div
//             style={{
//               // minWidth : 0,
//               // maxHeight: 0,
//               // padding: "20px"
//               width: "100%",
//             }}
//           >
//             <Slider
//               {...settings}
//               className="swiper"
//               slidesToShow={landingIsCollapsed ? 4 : 3}
//               autoplay={false}
//               // style={{
//               // ".slick-prev": {
//               //   left: 0,
//               // },
//               // }}
//             > */}
//             {landingGameList.map((e, index) => (
//               <SwiperSlide key={index}>
//                 <Button
//                   key={index}
//                   sx={{
//                     // display: "inline-block",
//                     //package
//                     // width: "20rem",
//                     // height: "8rem",
//                     display: "flex",
//                     // flexDirection: "column",
//                     // flexWrap: "nowrap",
//                     maxWidth: {
//                       lg: landingIsCollapsed ? "20.5rem" : "none",
//                       md: "20.5rem",
//                       // sm: "16rem",
//                     },
//                     height: "7.5rem",
//                     // height: "10rem",
//                     // flexDirection: "row",
//                     // flexShrink: 0,
//                     background:
//                       sliderIndex === index
//                         ? "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
//                         : null,

//                     // padding: 2,
//                     py: landingIsCollapsed ? 2 : 2,
//                     px: landingIsCollapsed ? 2 : 2,
//                     marginY: 2,
//                     borderRadius: 6,
//                     boxShadow: "0 1px 5px gray",
//                     textTransform: "none",
//                     // alignItems: "start",
//                     // justifyContent: "start",
//                     // alignContent: "start",
//                   }}
//                   onClick={() => switchImage(index, e)}
//                 >
//                   <img
//                     style={{
//                       width: "85px",
//                       height: "85px",
//                       borderRadius: 16,
//                       objectFit: "contain",
//                       alignContent: "center",
//                     }}
//                     src={e?.location}
//                     alt="game"
//                     // loading="lazy"
//                   />

//                   <Box
//                     sx={{
//                       // display: "flex",
//                       // flexDirection: "column",
//                       pl: landingIsCollapsed ? 2 : 2.5,
//                       // alignItems: "flex-start",

//                       // flexWrap: "nowrap",
//                       // backgroundColor: "black",
//                     }}
//                   >
//                     <Typography
//                       variant="h6"
//                       fontWeight="bold"
//                       fontSize={isTablet ? 14 : landingIsCollapsed ? 14 : 16}
//                       // lineHeight={2}
//                       color={"white"}
//                       textAlign="left" // Align text to the left
//                       sx={{
//                         pb: 0.3,
//                       }}
//                     >
//                       {e.title}
//                     </Typography>
//                     <Typography
//                       variant="body1"
//                       fontSize={isTablet ? 12 : landingIsCollapsed ? 11 : 12}
//                       color={"white"}
//                       textAlign="left"
//                     >
//                       {e.label}
//                     </Typography>
//                   </Box>
//                 </Button>
//               </SwiperSlide>
//             ))}
//             {/* </Slider> */}
//             {/* </div> */}
//           </Swiper>
//           {/* </Box> */}
//           {/* </Paper> */}
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               cursor: bannerImage?.link ? "pointer" : "inherit",
//             }}
//             onClick={() => {
//               if (bannerImage?.link) navigate(bannerImage?.link);
//             }}
//           >
//             <img
//               style={{
//                 width: "55%", // Increase size to make it larger
//                 height: "55%", // Adjust the height as well
//                 // position: "relative", // Position relative to its normal position
//                 // zIndex: 1, // Bring it above other content
//                 // top: "-30px", // Move the image upwards
//                 // left: "30px", // Adjust as needed to align properly
//                 // transform: "rotateY(1deg) rotateZ(-5deg) skewX(-5deg)",
//                 transformOrigin: "center",
//                 objectFit: "cover",
//                 // transition: "opacity 0.5s ease-in-out",
//                 opacity: isLoaded ? 1 : 0,
//               }}
//               // src={bannerImage}
//               // src={gameSliderImage[sliderIndex]}
//               src={bannerImage?.banner}
//               alt="game"
//               loading="lazy"
//               onLoad={handleImageLoad}
//             />
//           </Box>
//         </Box>
//       ) : (
//         <Box
//           sx={{
//             width: "100%",
//             overflow: "hidden",
//             // display: "flex",
//             // flexDirection: "column",
//             marginLeft: 1,
//             marginRight: 1,
//             alignItems: "center",
//             justifyContent: "center",
//             marginBottom: 6,
//           }}
//           // position="relative"
//         >
//           {/* <ImageSwitcher images={gameSliderImage} /> */}
//           <div
//             style={{
//               position: "relative",
//             }}
//           >
//             <Box
//               position="absolute"
//               className="invisible-scrollbar"
//               sx={{
//                 display: "flex",
//                 pl: 4,
//                 pr: 4,
//                 overflowX: "auto",
//                 scrollBehavior: "smooth",
//                 gap: "2rem",
//                 mb: "1.5rem",
//                 width: "92%",
//                 zIndex: 10,
//               }}
//             >
//               {landingGameList.map((e, index) => (
//                 <Button
//                   key={index}
//                   sx={{
//                     width: {
//                       xs: "15rem",
//                       sm: "20rem",
//                       md: "22rem",
//                     },
//                     // maxHeight: 150,
//                     flexShrink: 0,
//                     // height: isSmall
//                     //   ? "100px !important"
//                     //   : isTablet
//                     //   ? "140px !important"
//                     //   : "100px !important",
//                     // display: "grid",
//                     flexDirection: "row",
//                     background:
//                       sliderIndex === index
//                         ? "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
//                         : null,

//                     padding: isSmall ? 1.5 : isTablet ? 2 : 2,
//                     marginY: 2,
//                     borderRadius: 6,
//                     boxShadow: "0 1px 5px gray",
//                     textTransform: "none",
//                     alignItems: "start",
//                     justifyContent: "start",
//                   }}
//                   onClick={() => switchImage(index, e)}
//                 >
//                   <img
//                     style={{
//                       width: isSmall ? "70px" : isTablet ? "80px" : "100px",
//                       height: isSmall ? "70px" : "80px",
//                       borderRadius: 10,
//                       // objectFit: "cover",
//                     }}
//                     src={e?.location}
//                     alt="game"
//                     // loading="lazy"
//                   />
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                       pl: isSmall ? 1.5 : isTablet ? 2.5 : 2,
//                       alignItems: "flex-start",
//                     }}
//                   >
//                     <Typography
//                       variant="h6"
//                       fontWeight="bold"
//                       // fontSize={isSmall ? 12 : { xs: 12, sm: 16 }}
//                       // fontSize={isSmall ? "0.8rem" : "1rem"}
//                       fontSize={{
//                         xs: 11,
//                         sm: 14,
//                       }}
//                       sx={{
//                         pb: 0.5,
//                       }}
//                       // lineHeight={2}
//                       color={"white"}
//                       textAlign="left" // Align text to the left
//                     >
//                       {e.title}
//                     </Typography>
//                     <Typography
//                       variant="body1"
//                       // fontSize={isSmall ? 9 : { xs: 10, sm: 14 }}
//                       // fontSize={isSmall ? "0.6rem" : "0.8rem"}
//                       fontSize={{
//                         xs: 9,
//                         sm: 12,
//                       }}
//                       color={"white"}
//                       textAlign="left"
//                     >
//                       {e.label}
//                     </Typography>
//                   </Box>
//                 </Button>
//               ))}
//             </Box>
//           </div>

//           <Box
//             sx={{
//               mt: 20,
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               cursor: bannerImage?.link ? "pointer" : "inherit",
//             }}
//             onClick={() => {
//               if (bannerImage?.link) navigate(bannerImage?.link);
//             }}
//           >
//             <img
//               style={{
//                 width: "80%", // Increase size to make it larger
//                 height: "55%", // Adjust the height as well
//                 position: "relative", // Position relative to its normal position
//                 zIndex: 1, // Bring it above other content
//                 //   top: "-30px", // Move the image upwards
//                 //   left: "30px", // Adjust as needed to align properly
//                 //   marginRight: 2,
//                 //   marginRight: 2,
//                 // transform: "rotateY(1deg) rotateZ(-5deg) skewX(-5deg)",
//                 transformOrigin: "center",
//                 objectFit: "cover",
//                 // transition: "opacity 0.5s ease-in-out",
//                 opacity: isLoaded ? 1 : 0,
//               }}
//               src={bannerImage?.banner}
//               alt="game"
//               loading="lazy"
//               onLoad={handleImageLoad}
//             />
//           </Box>
//         </Box>
//       )}
//     </>
//   );
// };

// export default GameSliderHomePage;
