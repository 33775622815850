import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
// import Loader from "../../Components/Loader/Loader";
import { useQuery } from "react-query";
import * as apiService from "../../Services/Service";
import BiggestWinnerBanner from "./BiggestWinnerBanner";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import PageLoader from "../../Components/Loaders/PageLoader";
import { useGameConfig } from "../../constants/useGameConfig";
import MultiplayerPicks from "../../Components/Game/MultiplayerPicks";
import MultiplayerGameStatus from "../../Components/Game/MultiplayerGameStatus";
import GameDuration from "../../Components/Game/GameDuration";
import JoinedPlayersProgressBar from "../../Components/Game/JoinedPlayersProgressBar";
import JoinButton from "../../Components/Game/JoinButton";
import CountdownTimer from "../../Components/Timer/CountdownTimer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#03364C",
  fontSize: "16px",
  fontWeight: "500",
  borderBottom: "none",
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "#03364C",
}));

const StockBiggestWinner = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPicksTab, setCurrentPicksTab] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [gameType, setGameType] = useState(0);
  const location = useLocation();

  const userId = sessionStorage.getItem("userId");

  const navigate = useNavigate();

  const { data: gameConfig } = useGameConfig();

  console.log(gameConfig);

  //   const getGameAssetType = () => {
  //     const path = location.pathname;
  //     if (path.includes("biggestwinner/cryptos")) {
  //       return "CRYPTOS";
  //     } else if (path.includes("biggestwinner/stocks")) {
  //       return "STOCKS";
  //     }
  //   };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data, isLoading, refetch } = useQuery(
    ["multiplayerData", page, limit, navigate, gameType, userId],
    async () => {
      try {
        await delay(800);
        const gameAssetType = "STOCKS";
        const MultiPlayerData = await apiService.getBiggestWinnerMultiplayer({
          page: page + 1,
          limit,
          gameType,
          userId,
          gameAssetType,
        });
        setCount(MultiPlayerData?.data?.data?.total);
        console.log("CALL");
        return MultiPlayerData.data.data.gameInstance.sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );
      } catch (error) {
        console.log(error);
      }
    },
    {
      staleTime: 5000,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: true,
    }
  );
  useEffect(() => {
    // This will trigger the API call when the component is first loaded
    refetch();
  }, [refetch]);

  console.log(data, "dddd");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePicksTabClick = (e) => {
    setCurrentPicksTab(e.target.id);
    setGameType(gameConfig[e.target.id]);
    setPage(0);
  };

  const handleAllGamesClick = () => {
    setCurrentPicksTab("");
    setGameType(0);
    setPage(0);
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      {/* {isGameConfigLoading ? (
        <>
          <Loader />
        </>
      ) : ( */}
      <>
        <video
          ref={videoRef}
          style={{
            display: isSmallScreen ? "block" : "none",
            width: "100%",
            height: "300px",
            objectFit: "cover",
            borderRadius: "18px",
            marginBottom: "20px",
          }}
          loop
          muted
        >
          <source src="/video/Bigwinner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <BiggestWinnerBanner landing={false} />

        {/* -------------------------------------NEW UI---------------------------------------------------------*/}
        <>
          <Card
            sx={{
              background: "#03364C",
              padding: 2,
              borderRadius: "18px",
              zIndex: 52,
              marginTop: isSmallScreen ? 0 : 4,
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <CardHeader title={"Multiplayer"} />
              <MultiplayerPicks
                currentPicksTab={currentPicksTab}
                handleAllGamesClick={handleAllGamesClick}
                // isGameConfigLoading={isGameConfigLoading}
                // gameConfig={gameConfig}
                handlePicksTabClick={handlePicksTabClick}
              />
            </Box>

            <>
              {isLoading ? (
                // && !isGameConfigLoading
                <>
                  <PageLoader />
                </>
              ) : (
                <>
                  {!data || data.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingY: 10,
                        }}
                      >
                        <Typography>
                          There are no records to display.
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <TableContainer sx={{ minWidth: "900px" }}>
                        <Table
                          sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 20px",
                            width: "100%",
                          }}
                        >
                          <StyledTableHead>
                            {/* <TableRow> */}
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Starts In</StyledTableCell>
                            <StyledTableCell>Duration</StyledTableCell>
                            <StyledTableCell>Prize</StyledTableCell>
                            <StyledTableCell>Entry Fee</StyledTableCell>
                            <StyledTableCell>Total Players</StyledTableCell>
                            <StyledTableCell>Game ID</StyledTableCell>
                            <StyledTableCell></StyledTableCell>

                            {/* </TableRow> */}
                          </StyledTableHead>
                          <TableBody>
                            {data?.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  background: "#042836",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    borderBottomLeftRadius: "48px",
                                    borderTopLeftRadius: "48px",
                                  }}
                                >
                                  <MultiplayerGameStatus status={row.status} />
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                  <CountdownTimer
                                    startTime={new Date(row?.startTime)}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                  <GameDuration
                                    endTime={row.endTime}
                                    startTime={row.startTime}
                                    row={row}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                  {row.game?.entryFees * row.game?.minPlayer -
                                    row.game?.entryFees *
                                      row.game?.minPlayer *
                                      0.2}{" "}
                                  USDC
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  {row?.game?.gamePaymentType === "Free" ? (
                                    <span
                                      style={{
                                        color: "white",
                                        background: "#11B1F4",
                                        padding: "2px 10px",
                                        borderRadius: "10px",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      Free
                                    </span>
                                  ) : (
                                    `${row.game?.entryFees}  USDC`
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <JoinedPlayersProgressBar
                                    participantsLength={row?.participantsLength}
                                    maxPlayer={row.game?.maxPlayer}
                                    minPlayer={row.game?.minPlayer}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{ borderBottom: "none", fontWeight: 800 }}
                                >
                                  {row._id
                                    .substring(row._id.length - 7)
                                    .toUpperCase()}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    borderBottomRightRadius: "48px",
                                    borderTopRightRadius: "48px",
                                  }}
                                >
                                  <JoinButton row={row} />

                                  {/* {selectedRowData && (
                                      <Modals
                                        open={openModal}
                                        handleClose={handleCloseModal}
                                      >
                                        <SelectCryptos data={selectedRowData} />
                                      </Modals>
                                    )} */}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={count}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          </Card>
        </>
      </>
      {/* )} */}
    </>
  );
};

export default StockBiggestWinner;
