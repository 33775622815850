import React from "react";
import { images } from "../../constants";
import { Box, Container, Typography } from "@mui/material";
import { Diversity1TwoTone } from "@mui/icons-material";
const ChampsTokenRoadMap = () => {
  return (
    <Box
      sx={{
        // background: `linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)`,
        // background: "#FFFFFF",
        background: "#00000045",
        display: "flex",
        flexDirection: "column",
        px: {
          xs: 5,
          sm: 10,
        },
        pb: {
          xs: 5,
          sm: 10,
        },
        pt: {
          xs: 3,
          sm: 10,
        },
      }}
    >
      <div>
        <Typography
          variant="caption"
          fontSize={{ md: "16px", sm: "30px", xs: "24px" }}
          fontFamily={"Space Grotesk"}
          fontWeight={500}
          sx={{ color: "#FFFFFF" }}
        >
          Token
        </Typography>
        <Box>
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            CHAMPS Token
          </Typography>
        </Box>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          //   borderImageSource:
          //     "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)",
          border: "2px solid  #11B1F4",
          borderRadius: {
            xs: 3,
            sm: 4,
          },
          py: 5,
          mt: {
            xs: 2,
            sm: 0,
          },
        }}
      >
        <img
          width={"70%"}
          src={images.landingChampsBannerRoadMap}
          style={{
            paddingInline: 3,
          }}
          alt=""
        />
      </Box>
    </Box>
  );
};

export default ChampsTokenRoadMap;
