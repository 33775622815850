import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

const Timer = ({ target }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // const target = new Date("2023-11-29 23:11");

    const interval = setInterval(() => {
      // const dd = new Date();
      // const localTime = dd.getTime();
      // const offset = -5; // UTC of USA Eastern Time Zone is -05.00
      // const usa = localTime + 3600000 * offset;
      // const usaTimeNow = new Date(usa);

      const easternTime = new Date().toLocaleString("en-US", {
        timeZone: process.env.REACT_APP_DEFAULT_TIME_ZONE,
      });

      const easternTimeInMS = Date.parse(easternTime);
      // const now = new Date().getTime();
      const difference = target.getTime() - easternTimeInMS;
      console.log(difference, "difference");

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDays(d);

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(h);

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(m);

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(s);
    }, 1000);

    return () => clearInterval(interval);
  }, [target]);

  return (
    <>
      <Box
        sx={{
          height: 35,
          // width: 110,
          paddingX: 1,
          borderRadius: "20px",
          background: seconds < 0 ? "#00FF66" : "#FFD54B",
          textTransform: "none",
          display: "flex",
          fontWeight: 500,
          alignItems: "center",
          justifyContent: "center",
          color: "#042836",
        }}
        whiteSpace={"nowrap"}
      >
        {seconds < 0
          ? "In progress"
          : days === 0 && hours === 0
          ? `${minutes}m ${seconds}s left`
          : days === 0
          ? `${hours}h ${minutes}m ${seconds}s left`
          : `${days}d ${hours}h ${minutes}m ${seconds}s left`}
      </Box>
    </>
  );
};

export default Timer;
