import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Container,
  CssBaseline,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import Loader from "../../Components/Loader/Loader";
import { useQuery } from "react-query";
import {
  // getBiggestLooserMultiplayer,
  getBiggestLooserMultiplayerLandingPageData,
  topStocks,
} from "../../Services/Service";
import { useTheme } from "@emotion/react";
import PageLoader from "../../Components/Loaders/PageLoader";
import { useGameConfig } from "../../constants/useGameConfig";
import BiggestLooserBaner from "../BiggestLooser/BiggestLooserBaner";
import MultiplayerPicks from "../../Components/Game/MultiplayerPicks";
import MultiplayerGameStatus from "../../Components/Game/MultiplayerGameStatus";
import GameDuration from "../../Components/Game/GameDuration";
import JoinedPlayersProgressBar from "../../Components/Game/JoinedPlayersProgressBar";
import JoinButton from "../../Components/Game/JoinButton";
import GameListHeader from "../../Components/Landing/GameListHeader";
import BackArrowButton from "../../Components/Landing/components/BackArrowButton";
import MobileScreenBanner from "../../Components/Landing/components/MobileScreenBanner";
import CountdownTimer from "../../Components/Timer/CountdownTimer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#03364C",
  fontSize: "16px",
  fontWeight: "500",
  borderBottom: "none",
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "#03364C",
}));

const StockBiggestLooserLandingPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  // const [currentTab, setCurrentTab] = useState("1");
  const [currentPicksTab, setCurrentPicksTab] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);

  const [gameType, setGameType] = useState(0);

  const { data: gameConfig, isLoading: isGameConfigLoading } = useGameConfig();

  console.log(gameConfig);

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data, isLoading } = useQuery(
    ["multiplayerLooserData", page, limit, gameType],
    async () => {
      try {
        // await delay(400);
        const MultiPlayerLooserData =
          await getBiggestLooserMultiplayerLandingPageData({
            page: page + 1,
            limit,
            gameType,
            gameAssetType: "STOCKS",
          });
        setCount(MultiPlayerLooserData?.data?.data?.total);
        // setLoading(false);
        return MultiPlayerLooserData.data?.data?.gameInstance.sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );
      } catch (error) {
        // setLoading(false);
      }
    },
    {
      staleTime: 5000,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePicksTabClick = (e) => {
    setCurrentPicksTab(e.target.id);
    setGameType(gameConfig[e.target.id]);
    setPage(0);
  };

  const handleAllGamesClick = () => {
    setCurrentPicksTab("");
    setGameType(0);
    setPage(0);
  };

  const {
    data: cryptos,
    isLoading: isCryptosLoading,
    refetch,
  } = useQuery(["topStocks"], async () => {
    try {
      // await delay(800);
      const response = await topStocks();
      return response.data?.data;
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Grid className="mainPage">
      <Grid container sx={{ width: "100%" }}>
        <Box
          sx={{
            // paddin g: 2,
            paddingY: 4,
            width: "100%",
            position: "relative",
            background: "#011F2D",
            overflow: "scroll",
            height: "100vh",
            paddingTop: 16,
          }}
        >
          {isGameConfigLoading || isCryptosLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <CssBaseline />
              <GameListHeader cryptos={cryptos} />

              <Container maxWidth="xl">
                <BackArrowButton />

                <MobileScreenBanner src={"/video/Bigloser.mp4"} />
                <BiggestLooserBaner landing={true} />

                <>
                  <Card
                    sx={{
                      background: "#03364C",
                      padding: 2,
                      borderRadius: "18px",

                      zIndex: 52,
                      marginTop: isSmallScreen ? 0 : 4,
                      overflow: "auto",
                      // display: tab.id === "2" ? "none" : "block",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardHeader title={"Multiplayer"} />
                      <MultiplayerPicks
                        currentPicksTab={currentPicksTab}
                        handleAllGamesClick={handleAllGamesClick}
                        isGameConfigLoading={isGameConfigLoading}
                        gameConfig={gameConfig}
                        handlePicksTabClick={handlePicksTabClick}
                      />
                    </Box>

                    <>
                      {isLoading && !isGameConfigLoading ? (
                        <>
                          <PageLoader />
                        </>
                      ) : (
                        <>
                          {!data || data.length === 0 ? (
                            <>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  paddingY: 10,
                                }}
                              >
                                <Typography>
                                  There are no records to display.
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              <TableContainer sx={{ minWidth: "900px" }}>
                                <Table
                                  sx={{
                                    borderCollapse: "separate",
                                    borderSpacing: "0px 20px",
                                    width: "100%",
                                  }}
                                >
                                  <StyledTableHead>
                                    {/* <TableRow> */}
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Starts In</StyledTableCell>
                                    <StyledTableCell>Duration</StyledTableCell>
                                    <StyledTableCell>Prize</StyledTableCell>
                                    <StyledTableCell>Entry Fee</StyledTableCell>
                                    <StyledTableCell>
                                      Total Players
                                    </StyledTableCell>
                                    <StyledTableCell>Game ID</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>

                                    {/* </TableRow> */}
                                  </StyledTableHead>
                                  <TableBody>
                                    {data?.map((row, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          background: "#042836",
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            borderBottomLeftRadius: "48px",
                                            borderTopLeftRadius: "48px",
                                          }}
                                        >
                                          <MultiplayerGameStatus
                                            status={row.status}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{ borderBottom: "none" }}
                                        >
                                          <CountdownTimer
                                            startTime={new Date(row?.startTime)}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{ borderBottom: "none" }}
                                        >
                                          <GameDuration
                                            endTime={row.endTime}
                                            startTime={row.startTime}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{ borderBottom: "none" }}
                                        >
                                          {row.game?.entryFees *
                                            row.game?.minPlayer -
                                            row.game?.entryFees *
                                              row.game?.minPlayer *
                                              0.2}{" "}
                                          USDC
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          {row?.game?.gamePaymentType ===
                                          "Free" ? (
                                            <span
                                              style={{
                                                color: "white",
                                                background: "#11B1F4",
                                                padding: "2px 10px",
                                                borderRadius: "10px",
                                                marginLeft: "2px",
                                              }}
                                            >
                                              Free
                                            </span>
                                          ) : (
                                            `${row.game?.entryFees}  USDC`
                                          )}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <JoinedPlayersProgressBar
                                            participantsLength={
                                              row?.participantsLength
                                            }
                                            maxPlayer={row.game?.maxPlayer}
                                            minPlayer={row.game?.minPlayer}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            fontWeight: 800,
                                          }}
                                        >
                                          {row._id
                                            .substring(row._id.length - 7)
                                            .toUpperCase()}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            borderBottomRightRadius: "48px",
                                            borderTopRightRadius: "48px",
                                          }}
                                        >
                                          <JoinButton row={row} />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={count}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleLimitChange}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  </Card>
                </>
              </Container>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default StockBiggestLooserLandingPage;
