import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import LandingButton from "./LandingButton";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import React, { useEffect } from "react";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import { images } from "../../constants";
import { FooterSection } from "../Auth/FooterSection";
import GameSliderHomePage from "./HomePageSlider";
import { BannerFooterSection } from "./LandingProfileGroup";

export const LandingTopBanner = () => {
  const navigate = useNavigate();
  const videoRef = React.useRef(null);

  const [isPlaying, setIsPlaying] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // console.log(isTablet, isMobile, "isTablet, isMobile");
  const togglePlayPause = async () => {
    console.log(isPlaying);
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        await video.pause();
      } else {
        await video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        // width: "100%",
        height: "auto",
        overflow: "hidden",
        borderRadius: "20px 0 0 0",
        pl: 2,
        pr: 2,
        pt: 2,
        background: "rgba(0, 0, 0, 0.27)",
      }}
      onClick={togglePlayPause}
    >
      <Box
        position={"relative"}
        zIndex={1}
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <img
          src="/Images/landingPage/top_banner.png"
          alt="Banner Image"
          width="100%"
          height={"100%"}
          style={{
            // marginInline: 2,
            // borderRadius: 26,
            borderRadius: "clamp(0.5rem, 5vw, 2rem)",
            objectFit: "cover",
            display: "inline-block",
            filter:
              "linear-gradient(264.79deg, rgba(17, 177, 244, 0.56) 4.91%, #092B3C 99.65%)",
          }}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // borderRadius: 26,
            borderRadius: "clamp(0.5rem, 5vw, 2rem)",
            background:
              "linear-gradient(264.79deg, rgba(17, 177, 244, 0.56) 4.91%, #092B3C 99.65%)",
            pointerEvents: "none",
          }}
        ></div>

        {/* Right-Side Overlay Image */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pointerEvents: "none",
          }}
        >
          <img
            src="/Images/landingPage/top_banner_overlay.png"
            alt="Banner Overlay"
            style={{
              height: "100%",
              width: "auto",
              objectFit: "contain",
              maxWidth: "100%",
            }}
          />
        </Box>

        {/* <Box
          display={"flex"}
          position={"absolute"}
          zIndex={9}
          top={0}
          bottom={0}
          right={0}
          // right={{
          //   xs: 10,
          //   sm: 30,
          // }}
        >
          <img
            src="/Images/landingPage/top_banner_overlay.png"
            alt="Banner Image"
            width="auto"
            height={"auto"}
            style={{
              // marginInline: 2,
              objectFit: "contain",
              display: "inline-block",
            }}
          />
        </Box> */}

        <Box
          position={"absolute"}
          zIndex={10}
          // top={{
          //   xs: "10%",
          //   sm: "20px",
          //   md: "10%",
          //   lg: "6%",
          //   xl: "10%",
          // }}
          top={"clamp(10%, 18px, 10%)"}
          // left={{
          //   xs: "4%",
          //   sm: "3%",
          // }}
          left={"clamp(4%, 3%, 4%)"}
        >
          <Box
            width="100%"
            display={"flex"}
            flexDirection={"column"}
            // gap={{
            //   xs: 0.3,
            //   sm: 0.8,
            //   md: 0.2,
            // }}
            // gap="clamp(0.2rem, 0.5rem, 0.2rem)"
            gap="clamp(0.1rem, 0.5vw, 0.8rem)"
          >
            <Typography
              fontSize={{
                // lg: "3rem",
                xl: "2.5rem",
                md: "2rem",
                sm: "1.5rem",
                xs: "0.6rem",
              }}
              fontFamily={'"Inter", system-ui'}
              fontWeight={800}
              sx={{
                color: "#FFFFFF",
                // gap: {
                //   xs: 0.5,
                //   sm: 0.8,
                //   md: 0,
                // },
              }}
            >
              Home of the
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={{
                  xs: 0.4,
                  sm: 0.8,
                  md: 1.5,
                }}
              >
                <Box
                  sx={{
                    // mx: 1,
                    display: "flex",
                    gap: {
                      xs: 0.5,
                      sm: 0.8,
                      md: 1,
                    },
                    justifyContent: "center",
                    alignItems: "baseline",
                    color: "#FF3030",
                  }}
                >
                  Bears
                  <Box
                    sx={{
                      width: {
                        xs: "0.8rem",
                        sm: "2rem",
                        md: "3rem",
                      },
                    }}
                  >
                    <img
                      src={images.landingArrowDown}
                      alt=""
                      style={{ width: "100%", height: "auto" }} // Ensures the image scales properly
                    />
                  </Box>
                </Box>
                <div style={{ color: "#FFFFFF" }}>&</div>
                <Box
                  sx={{
                    // mx: 1,
                    display: "flex",
                    flexDirection: "row",
                    gap: {
                      xs: 0.5,
                      sm: 0.8,
                      md: 1,
                    },
                    justifyContent: "center",
                    alignItems: "center",
                    // alignItems: "baseline",
                    color: "#30FF45",
                  }}
                >
                  Bulls
                  {/* <img
                    width={isMobile ? "13px" : isTablet ? "30px" : "45px"}
                    height="auto"
                    src={images.landingArrowUp}
                    alt=""
                  /> */}
                  <Box
                    sx={{
                      width: {
                        xs: "0.8rem",
                        sm: "2rem",
                        md: "3rem",
                      },
                    }}
                  >
                    <img
                      src={images.landingArrowUp}
                      alt=""
                      style={{ width: "100%", height: "auto" }} // Ensures the image scales properly
                    />
                  </Box>
                </Box>
              </Box>
            </Typography>

            <Typography
              variant="body1"
              fontSize={{
                xs: "0.4rem",
                sm: "0.6rem",
                md: "0.8rem",
                lg: "0.8rem",
                xl: "1rem",
              }}
              sx={{
                color: "rgba(226, 228, 233, 1)",
              }}
            >
              Create account and earn ETH rewards daily.
            </Typography>

            <Box
              sx={{
                display: "flex",
                my: "clamp(0.4rem, 1vw, 1rem)",
              }}
            >
              <LandingButton
                text={"Signup and Play"}
                onClick={() => navigate("/signup")}
              />
            </Box>

            <BannerFooterSection
              text={"Don’t have an account? "}
              link={"Create Account"}
              onClick={"/login"}
              size={4}
            />
          </Box>
        </Box>
      </Box>

      {/* <Box> */}
      <GameSliderHomePage />
      {/* </Box> */}

      {/* 
      <img
        style={{ width: "100%", height: "auto" }}
        src={
          // "https://the420link1.s3.amazonaws.com/LandingPage/dce9e81c-4b2c-4661-ab8c-399e4464741c_landing-header.gif"
          // "https://the420link1.s3.amazonaws.com/LandingPage/5ae9b59e-4b5d-4065-9c19-de407c2793c9_landing_header.gif"
          // "https://the420link1.s3.amazonaws.com/LandingPage/b6af1486-16ea-4edc-a1b6-e6c79ddc948c_landing-header.gif"
          "https://the420link1.s3.amazonaws.com/LandingPage/1d893b6f-9ebf-4509-90e3-2801f641af1d_landing-header.gif"
        }
        alt="Champs"
      />


     <Box
        sx={{
          position: "absolute",
          top: {
            xs: "75%",
            sm: "82%",
            md: "85%",
          },
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 2,
          width: "100%",
          background: `linear-gradient(0.71deg, rgba(0, 0, 0, 0) 6.05%, rgba(0, 0, 0, 0.69) 40.78%)`,
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            // background: `linear-gradient(0.71deg, rgba(0, 0, 0, 0) 6.05%, rgba(0, 0, 0, 0.69) 40.78%)`,
            justifyContent: "space-between",
            px: {
              xs: 1,
              sm: 2,
              md: 3,
            },
            pr: {
              xs: 2,
              sm: 3,
              md: 5,
            },
            color: "#FFFFFF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Responsive flex direction
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              display="flex"
              // variant="h3"
              fontSize={{ md: "2.2rem", sm: "1.5rem", xs: "0.6rem" }}
              // fontFamily={'"Bebas Neue", system-ui'}
              fontFamily={'"Inter", system-ui'}
              fontWeight={800}
              sx={{
                textAlign: "center",
                gap: {
                  xs: 0.5,
                  sm: 0.8,
                  md: 1,
                },
              }}
            >
              Home of the
              <Box
                sx={{
                  // mx: 1,
                  display: "flex",
                  gap: {
                    xs: 0.5,
                    sm: 0.8,
                    md: 1,
                  },
                  justifyContent: "center",
                  alignItems: "baseline",
                  color: "#FF3030",
                }}
              >
                Bears
                <img
                  width={isMobile ? "13px" : isTablet ? "30px" : "45px"}
                  height="auto"
                  src={images.landingArrowDown}
                  alt=""
                />
              </Box>
              <div style={{ color: "#0EA1EE" }}>&</div>
              <Box
                sx={{
                  // mx: 1,
                  display: "flex",
                  gap: {
                    xs: 0.5,
                    sm: 0.8,
                    md: 1,
                  },
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#30FF45",
                }}
              >
                Bulls
                <img
                  width={isMobile ? "13px" : isTablet ? "30px" : "45px"}
                  height="auto"
                  src={images.landingArrowUp}
                  alt=""
                />
              </Box>
            </Typography>
          </Box>

          <div
            style={
              {
                // display: "flex",
              }
            }
          >
            <LandingButton
              text={"Get Started"}
              onClick={() => navigate("/signup")}
            />
          </div>
        </Box>
      </Box> */}
    </Box>
  );
};
