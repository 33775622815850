import cryptoParlayBigSvg from "../asset/crypto_parlay.svg";

export const landingGameList = [
  {
    location: "/Images/landingPage/slider-sb1.png",
    title: "Biggest winner",
    // label:
    //   "Pick several crypto that you believe will go up during the contest duration to win BIG",
    label: "Build your team with the BEST performing CRYTPO",
    banner: "/Images/landingPage/slider1.png",
    link: "/landing/biggestwinner",
  },
  {
    location: "/Images/landingPage/slider-sb2.png",
    title: "Biggest Loser",
    // label:
    //   "Pick several crypto that you believe will go down during the contest duration and win BIG",
    label: "Build your team with the WORST performing CRYPTO",
    banner: "/Images/landingPage/slider2.png",
    link: "/landing/biggestloser",
  },
  {
    location: "/Images/landingPage/slider-sb3.png",
    title: "Crypto Parlay",
    label:
      "Pick multiple crypto tokens to either go UP or DOWN. Get each section correct and win BIG!!",
    // banner: "/Images/landingPage/slider3.png",
    banner: cryptoParlayBigSvg,
  },
  {
    location: "/Images/landingPage/BiggestWinnerStock-sb.png",
    title: "Biggest winner stocks",
    // label:
    //   "Pick the stocks you believe will that will go UP the highest percentage within a 5 minute contest",
    label: "Build your team with the BEST performing STOCKS",
    banner: "/Images/landingPage/stock.png",
    link: "/landing/stockbiggestwinner",
  },
  {
    location: "/Images/landingPage/BiggestLoserStock-sb.png",
    title: "Biggest loser stocks",
    // label:
    //   "Pick the stocks you believe will that will go DOWN the most percentage within a 5 minute contest",
    label: "Build your team with the WORST performing STOCKS",
    banner: "/Images/landingPage/stock.png",
    link: "/landing/stockbiggestloser",
  },
  {
    location: "/Images/landingPage/coming_soon.png",
    title: "",
    label: "Coming Soon",
    banner: "",
  },

  // {
  //   location: "/Images/landingPage/slider-sb4.png",
  //   title: "Sports Betting",
  //   label: "",
  //   banner: "/Images/landingPage/slider4.png",
  // },
  // {
  //   location: "/Images/landingPage/slider-sb5.png",
  //   title: "Casino",
  //   label: "",
  //   banner: "/Images/landingPage/slider5.png",
  // },
];

export const landingMenus = [
  {
    link: "/how-to-play",
    label: "How to Play",
  },
  {
    link: "/about-us",
    label: "About Us",
  },
  {
    link: "/",
    label: "Crypto Games",
    toggle: true,
    children: [
      {
        link: "/landing/biggestwinner",
        label: "Biggest Winner",
      },
      {
        link: "/landing/biggestloser",
        label: "Biggest Loser",
      },
    ],
  },
  {
    link: "/",
    label: "Stocks Games",
    toggle: true,
    children: [
      {
        link: "/landing/stockbiggestwinner",
        label: "Biggest Winner",
      },
      {
        link: "/landing/stockbiggestloser",
        label: "Biggest Loser",
      },
    ],
  },
  {
    link: "/roadmap",
    label: "Roadmap",
  },
  {
    link: "https://app.cryptochamps.io/",
    label: "CHAMPS TOKEN",
    newTab: true,
  },
  {
    link: "/signup",
    label: "Become a CHAMP",
  },
];
