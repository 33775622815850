import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  // Button,
  // ClickAwayListener,
  // Grow,
  // MenuItem,
  // MenuList,
  // Paper,
  // Popper,
  Stack,
  TextField,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import BaseOptionChart from "../../Components/Chart/BaseOptionChart";
import ReactApexChart from "react-apexcharts";
import { FiCalendar } from "react-icons/fi";
import { useQuery } from "react-query";
import { getWinAndLossChartData } from "../../Services/Service";

const MyGamesBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userId = sessionStorage.getItem("userId");
  const [seriesData, setSeriesData] = useState("day");
  const [totalAdded, setTotalAdded] = useState([]);
  const [categories, setcategories] = useState();
  const totalAddedData = [];

  const handleChangeSeriesData = (event) => {
    setSeriesData(event.target.value);
  };

  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();

  const chartOptions = merge(BaseOptionChart(), {
    colors: ["#11B1F4"],
    backgroundColor: "#11B1F4",
    stroke: {
      width: 4,
    },
    fill: {
      colors: ["#11B1F4"],
    },
    legend: { position: "top", horizontalAlign: "right" },
    chart: {
      foreColor: "#ffffff",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories:
        seriesData === "month"
          ? [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ]
          : seriesData === "week"
          ? categories
          : seriesData === "day"
          ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
          : [2022, 2023, 2024, 2025],
    },

    grid: {
      borderColor: "#ffffff12",
      borderWidth: 1,
    },

    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="custom-tooltip">${w.globals.series[seriesIndex][dataPointIndex]} <span>USDC</span></div>`;
      },
      enabled: true,
      style: {
        fontSize: "20px",
        fontFamily: "Roboto",
      },
      theme: "#085A7D",
      borderColor: "#ffffff12",
      borderWidth: 2,
    },
  });

  const tabs = {
    id: "1",
    tabTitle: "Wins",
    chartData: [
      {
        // year: "Week",
        data: [
          {
            name: "Total",
            data: totalAdded,
          },
        ],
      },
    ],
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getCurrentWeek = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 0);
    const difference = now - startOfYear;
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const currentWeek = Math.floor(difference / oneWeek);

    return currentWeek;
  };

  const processData = (data) => {
    // Create an array to represent all months (from 1 to 12)
    if (seriesData === "month") {
      const allMonths = Array.from({ length: 12 }, (_, index) => index + 1);
      // Create a map to store totalSpend for each month
      const monthMap = new Map(
        data
          .filter((entry) => entry._id.year === currentYear)
          .map((item) => [item._id?.month, item?.totalAdded?.toFixed(2)])
      );
      console.log(monthMap, "monthMap");

      // Fill in the gaps with zero for months without data
      const processedData = allMonths.map((month) => monthMap.get(month) || 0);
      console.log(processedData, "MA");
      return processedData;
    } else if (seriesData === "week") {
      const categories = [];
      data.forEach((item) => {
        const { _id, totalAdded } = item;
        const date = new Date(_id.year, _id.month - 1, _id.dayOfMonth);
        const formattedDate = `${date.getDate()} ${getMonthName(
          date.getMonth()
        )}`;
        categories.push(formattedDate);
        totalAddedData.push(totalAdded);
      });
      setcategories(categories);
      console.log(totalAddedData, categories, "WEEEEK");
      return totalAddedData;
    } else if (seriesData === "day") {
      const allMonths = Array.from({ length: 7 }, (_, index) => index + 1);
      // Create a map to store totalSpend for each month
      const monthMap = new Map(
        data
          .filter((entry) => entry._id.week === getCurrentWeek())
          .map((item) => [item._id?.dayOfWeek, item?.totalAdded?.toFixed(2)])
      );

      // Fill in the gaps with zero for months without data
      const processedData = allMonths.map((month) => monthMap.get(month) || 0);
      console.log(processedData, "PD");
      return processedData;
    } else if (seriesData === "year") {
      const allMonths = Array.from(
        { length: 5 },
        (_, index) => index + (currentYear - 2)
      );
      // Create a map to store totalSpend for each month
      const monthMap = new Map(
        data.map((item) => [item._id?.year, item?.totalAdded?.toFixed(2)])
      );

      console.log(monthMap, "yearMap");

      // Fill in the gaps with zero for months without data
      const processedData = allMonths.map((month) => monthMap.get(month) || 0);
      console.log(processedData, "MA");
      return processedData;
    }
  };
  const getMonthName = (monthIndex) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthIndex];
  };

  const { data, isLoading, refetch } = useQuery(
    ["Winchart", userId, seriesData],
    async () => {
      try {
        await delay(400);
        const response = await getWinAndLossChartData({
          userId,
          isWins: true,
          seriesData: seriesData,
        });
        setTotalAdded(processData(response.data?.data));
        return response.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const options = [
    { option: "Daily", value: "day" },
    { option: "Weekly", value: "week" },
    { option: "Monthly", value: "month" },
    { option: "Yearly", value: "year" },
  ];

  console.log(data, "WAL");

  return (
    <>
      <Box
        sx={{
          borderRadius: "22px",
          width: "100%",
          height: "317.67px",
          position: "relative",
        }}
      >
        <img
          //   style={{ display: isMobile ? "none" : "block" }}
          width={"100%"}
          height={"100%"}
          style={{
            borderRadius: "22px",
          }}
          src="/Images/banner-mg.png"
          alt=""
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            // width: "100%",
            height: "95%",
            borderRadius: "22px",
            backgroundColor: alpha("#000000", 0.5),
            // backgroundColor: "#000000",
            backdropFilter: `blur(5px)`,
            WebkitBackdropFilter: `blur(5px)`,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "center" : "unset",
            margin: 1,
            gap: isMobile ? 3 : 0,
          }}
        >
          {/* Left */}
          <Box sx={{ width: "100%", maxWidth: "600px" }}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ marginTop: 2, marginLeft: isMobile ? 1 : 2 }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Total wins
              </Typography>

              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <FiCalendar size={28} />
                <div>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleToggle}
                    sx={{
                      textTransform: "none",
                      color: "white",
                      fontSize: 14,
                    }}
                  >
                    Weekly
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleClose}>Profile</MenuItem>
                              <MenuItem onClick={handleClose}>
                                My account
                              </MenuItem>
                              <MenuItem onClick={handleClose}>Logout</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Box> */}

              <Box display={"flex"} alignItems={"center"}>
                <FiCalendar size={28} />
                <TextField
                  select
                  fullWidth
                  value={seriesData}
                  SelectProps={{ native: true }}
                  onChange={handleChangeSeriesData}
                  sx={{
                    width: "82px",
                    "& fieldset": { border: "0 !important" },
                    "& select": {
                      pl: 1,
                      py: 0.5,

                      pr: "24px !important",
                      typography: "subtitle2",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 0.75,
                      bgcolor: "background.neutral",
                    },
                    "& .MuiNativeSelect-icon": {
                      top: 4,
                      right: 0,
                      width: 20,
                      height: 20,
                    },
                  }}
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.option}
                    </option>
                  ))}
                </TextField>
              </Box>
            </Stack>
            {isLoading ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80%",
                    marginBottom: 2,
                  }}
                >
                  <CircularProgress size={20} sx={{ color: "#11B1F4" }} />
                </Box>
              </>
            ) : (
              <Box sx={{ mt: 1, mx: isMobile ? 0 : 1 }} dir="ltr">
                {tabs.chartData.map((item) => (
                  <ReactApexChart
                    type="area"
                    series={item.data}
                    options={chartOptions}
                    height={isMobile ? 180 : 230}
                  />
                ))}
              </Box>
            )}
          </Box>

          {/*right  */}

          <Box
            sx={{
              width: "40%",
              display: isMobile ? "none" : "block",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
              }}
              src="/Images/mygames-r.png"
              alt=""
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyGamesBanner;
