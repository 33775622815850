import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { getUserByUsername, inviteUserToGame } from "../../Services/Service";
import { AuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvitationPopup = ({ open, setOpen, inviteRowData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [username, setUsername] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [userDetails, setUserDetails] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { user } = React.useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
    setUserDetails(null);
    setUsername("");
    setErrorMessage("");
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    setLoading(true);
    const fetchUserDetails = async (username) => {
      try {
        const response = await getUserByUsername(username);
        if (response.data) {
          setUserDetails(response.data);
          setErrorMessage("");
          setLoading(false);
        }
      } catch (error) {
        console.log(
          "Error fetching user details:",
          error.response.data.message
        );
        setUserDetails(null);
        setErrorMessage("User not found");
        setLoading(false);
      }
    };
    // Debounce the API call to avoid making too many requests while the user is typing
    setTimeout(() => {
      fetchUserDetails(newUsername);
    }, 500); // Adjust the delay as needed
  };

  console.log(inviteRowData, "inviteRowData");

  const handleConfirmInvitation = async () => {
    try {
      if (!userDetails?._id || !userDetails?.username)
        return toast.error("Please enter valid username");

      setIsSubmitting(true);
      const body = {
        game: inviteRowData?.game,
        sendUserId: user._id,
        sendUserName: user.username,
        sendUserProfilePic: user.profilePic,
        recieveUserId: userDetails?._id,
        recieveUserName: userDetails?.username,
        recieveProfilePic: userDetails?.profilePic,
        gameInstance: inviteRowData._id,
      };
      console.log(body);
      const response = await inviteUserToGame(body);
      if (response.data?.success === false) {
        toast.error(response.data?.message);
        setIsSubmitting(false);
      } else if (response.data?.success === true) {
        setIsSubmitting(false);
        toast.success(response.data?.message);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          "& .MuiDialog-paper": {
            borderRadius: "25px",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            background:
              "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)",
            padding: 2,
            width: isMobile ? "100%" : "400px",
            height: isMobile ? "290px" : "400px",
            position: "relative",
            borderRadius: "25px",
            gap: isMobile ? 1 : 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", sm: "32px", md: "32px" },
              fontWeight: "600",
              fontFamily: "Poppins",
              color: "white",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            Invite
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              paddingY: 1,
            }}
          >
            {loading ? (
              <Skeleton
                variant="circular"
                sx={{ width: isMobile ? 50 : 65, height: isMobile ? 50 : 65 }}
              />
            ) : (
              <Avatar
                src={userDetails && userDetails?.profilePic}
                sx={{ width: isMobile ? 50 : 65, height: isMobile ? 50 : 65 }}
                variant="circular"
              />
            )}

            {loading ? (
              <Skeleton width="70%" />
            ) : (
              <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                {userDetails && userDetails?.name}
              </Typography>
            )}
          </Box>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              display={"flex"}
              gap={1}
              width={isMobile ? "100%" : "80%"}
              justifyContent={"start"}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Username / ID
              </Typography>
              {!errorMessage ? (
                <Avatar
                  sx={{ width: 16, height: 16 }}
                  src="/Images/verified-badge.png"
                />
              ) : (
                <Avatar
                  sx={{ width: 16, height: 16 }}
                  src="/Images/unverified-badge.png"
                />
              )}
            </Box>
            <TextField
              value={username}
              onChange={handleUsernameChange}
              sx={{
                "& fieldset": { border: "none" },
                backgroundColor: "#011F2D",
                borderRadius: "8px",
                width: isMobile ? "100%" : "80%",
                height: isMobile ? "35px" : "50px",
              }}
            />
            <span
              style={{ textAlign: "start", color: "red", fontSize: "12px" }}
            >
              {!userDetails && errorMessage}
            </span>
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: isMobile ? "100%" : "80%",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Button
              sx={{
                backgroundColor: "#F41111",
                color: "white",
                width: "100%",
                textTransform: "none",
                paddingX: { xs: 2, sm: 4, md: 4 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 14 },
                "&:hover": {
                  backgroundColor: alpha("#F41111", 0.5),
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isSubmitting}
              sx={{
                backgroundColor: "#00FF66",
                color: "#0A739F",
                width: "100%",
                textTransform: "none",
                paddingX: { xs: 2, sm: 4, md: 4 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 14 },
                "&:hover": {
                  backgroundColor: alpha("#00FF66", 0.5),
                },
              }}
              loadingIndicator={<CircularProgress color="info" size={16} />}
              onClick={handleConfirmInvitation}
            >
              Confirm
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default InvitationPopup;
