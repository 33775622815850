import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import Lottie from "lottie-react";
import * as apiService from "../../Services/Service";
import ReactTelegramStickerViewer from "./ReactTelegramStickerViewer";

const Sticker = ({ setOpenSticker, openSticker, handleSendMessage }) => {
  const [stickers, setStickers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchSticker = async () => {
      if (loadMoreLoading) return;
      setLoadMoreLoading(true);

      try {
        const response = await apiService.getAllStickersWithPaginated(
          page + 1,
          limit
        );
        console.log(response?.data?.data);

        const data = response?.data?.data?.data ?? [];

        setTotalCount(response?.data?.data?.totalCount);
        setStickers((prev = []) => {
          const newStickers = (data || []).filter(
            (newItem) =>
              !prev.some((existingItem) => existingItem?._id === newItem?._id)
          );

          return [...prev, ...newStickers];
        });
      } catch (error) {
        console.error(error);
      }

      setLoadMoreLoading(false);
    };
    fetchSticker();
  }, [page]);

  const handleStickerSocket = async (stickerId) => {
    if (!stickerId) return;

    console.log("STICKER FILE ID", stickerId);
    setLoading(true);
    try {
      await handleSendMessage(stickerId);
      setOpenSticker(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "70vh",
        background: "#051016",
        borderRadius: 3,
      }}
    >
      {/* Cancel button at the top */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 1, // Adjust padding as needed
          gap: 3,
        }}
      >
        {loading && <Typography variant="caption">Loading...</Typography>}
        <IconButton
          onClick={() => {
            setOpenSticker(false);
          }} // Replace with your close handler
          sx={{ color: "white" }} // Set button color
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {stickers?.length <= 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <Typography>No sticker found</Typography>
        </Box>
      )}

      <Typography
        sx={{
          display: stickers?.length > 0 ? "block" : "none",
          textAlign: "center",
          mb: 3,
        }}
      >
        Stickers
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          px: 2,
          justifyContent: {
            xs: "center",
            md: "flex-start",
            lg: "center",
          },
          alignItems: "start",
          overflow: "scroll",
        }}
      >
        {stickers?.map((e, i) => (
          <Box
            key={i}
            sx={{
              cursor: "pointer",
              background: "rgba(0,40,57,255)",
              py: 1,
              px: 2,
              borderRadius: 3,
            }}
            onClick={async () => {
              await handleStickerSocket(
                e?.file_path_result?.file_id
                // ??
                //   "CAACAgQAAxUAAWd8_I88UicMmvfculBikft7s7uDAAJKEAACa7hwUlwdtfYaplR4NgQ"
              );
            }}
          >
            {e?.stickerUrl?.endsWith(".tgs") ? (
              <ReactTelegramStickerViewer
                tgsUrl={e?.stickerUrl}
                style={{ height: "60px", width: "60px" }}
              />
            ) : (
              <img
                style={{
                  backgroundColor: "transparent",
                }}
                src={
                  // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_8.png"
                  // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_9.png"
                  // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_9.png"
                  // "https://the420link1.s3.amazonaws.com/Stickers/CRYPTOCHAMPSPACK/file_10.png"
                  e?.stickerUrl
                }
                alt="sticker"
                width={50}
                height={50}
              />
            )}
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "Center",
          alignItems: "start",
          ml: 2,
          mb: 2,
        }}
      >
        <Button
          sx={{
            display:
              !(stickers?.length > 0) || stickers?.length >= totalCount
                ? "none"
                : "block",
            background: "rgba(0,40,57,255)",
            color: "white",
            fontSize: 10,
            textTransform: "capitalize",
          }}
          onClick={handleLoadMore}
        >
          {loadMoreLoading ? "Loading..." : "Load more"}
        </Button>
      </Box>
    </Box>
  );
};

export default Sticker;
