const landingArrowUp = "./Images/landing_arrow_up.png";
const landingArrowDown = "./Images/landing_arrow_down.png";
const landingChampsToken = "./Images/landingPage/champsToken.png";
const landingChampsBannerRoadMap =
  "./Images/landingPage/champs_banner_roadMap.png";

export default {
  landingArrowUp,
  landingArrowDown,
  landingChampsToken,
  landingChampsBannerRoadMap,
};
