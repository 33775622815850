import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import FooterComponent from "../Landing/FooterComponent";
// import LandingHeader from "../Landing/LandingHeader";
import BetaTestingPopup from "../Popups/BetaTestingPopup";
import CryptoChampTopBar from "../../Pages/NewLandingPage/Topbar";
import LandingPageSidebar from "../../Pages/NewLandingPage/LandingPageSideBar";
import { DrawerContext } from "../../context/DrawerContext";
import { Sticky, StickyContainer } from "react-sticky";
import AppBar from "@mui/material/AppBar";

const LandingLayout = () => {
  const location = useLocation();

  console.log(location?.pathname, "currentlocation");
  // const [collapsed, setCollapsed] = useState(false);

  // const handleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));

  const { landingIsCollapsed, setLandingIsCollapsed } =
    useContext(DrawerContext);

  console.log("landingIsCollapsed", landingIsCollapsed);
  return (
    <div
      style={{
        background: "rgba(15, 16, 60, 1)",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // width: "100%",
        maxWidth: "1536px",
      }}
    >
      <Grid className="mainPage">
        {/* <Grid container sx={{ width: "100%" }}> */}

        {/* {location?.pathname !== "/" && (
          <nav style={{ width: "100%" }}>
            <LandingHeader fromOtherPage={true} />
          </nav>
        )} */}
        {/* <h1 style={{ color: "white" }}>
          {`${window.screen.width} + "x" + ${window.screen.height}
            "Windows screen size"`}
        </h1> */}
        <AppBar position="fixed" elevation={0}>
          <CryptoChampTopBar />
        </AppBar>

        <StickyContainer
          style={{
            display: "grid",
            gridTemplateColumns: isMatch
              ? "100%"
              : landingIsCollapsed
              ? "6% 94%"
              : "18.5% 81.5%",
            width: "100%",
            // marginTop: isMatch ? "4.5rem" : "6.1rem",
          }}
        >
          <Sticky key={landingIsCollapsed ? "collapsed" : "expanded"}>
            {(props) => (
              <Box
                // className="mainPage"
                sx={{
                  ...props.style,
                  marginTop: {
                    // md: landingIsCollapsed ? "7rem" : "6.4rem",
                    lg: "7rem",
                  },
                  maxHeight: 500,
                  // width: landingIsCollapsed ? "6%" : "18.5%",
                  // width: "100%",
                  minWidth: landingIsCollapsed ? "6%" : "18.5%",
                  // width: "100%",
                  overflowY: "auto",
                  transition: "all 0.5s tween",
                }}
              >
                <LandingPageSidebar />
              </Box>
            )}
          </Sticky>
          <Box
            sx={{
              position: "relative",
              // marginTop: isMatch ? "5.5rem" : "6.1rem",
              mt: {
                xs: "4.5rem",
                sm: "4rem",
                md: "6rem",
              },
              transition: "all 0.5s tween",
              width: "100%",
            }}
          >
            <main style={{ flexGrow: 1 }}>
              <Outlet />
            </main>
            <footer style={{ flexShrink: 0 }}>
              <FooterComponent />
            </footer>
          </Box>
        </StickyContainer>
      </Grid>
      <BetaTestingPopup />
    </div>
  );
};

export default LandingLayout;
